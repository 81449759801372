import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import useDebounce from 'hooks/useDebounce';

const DebouncedSearchField = ({ onSearch, placeholder = '', id = 'search-input' }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    onSearch(searchTerm);
  }, [debouncedSearchTerm]);

  return (
    <Box>
      <TextField
        id={id}
        variant="outlined"
        fullWidth={true}
        placeholder={placeholder}
        size="small"
        sx={{
            backgroundColor: '#fff'
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </Box>
  );
};

export default DebouncedSearchField;
