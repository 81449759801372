import React, {useEffect, useRef, useState} from 'react';
import WebViewer from '@pdftron/pdfjs-express-viewer';

import usePaperDownload from 'hooks/papers/usePaperDownload';

const PdfViewer = ({paperId, paperName = '', height = 'calc(100vh - 80px)'}) => {
    const [instance, setInstance] = useState(null)
    const viewer = useRef(null);
    const {data, status} = usePaperDownload({paperId});

    useEffect(() => {
        if (data && !instance) {
            WebViewer(
                {
                    path: '/pdfjs-express',
                    initialDoc: '',
                    licenseKey: 'mSrKc5CHYIPhqt9iLC5I'
                },
                viewer.current,
            ).then((instance) => {
                instance.setFitMode(instance.FitMode.FitWidth);
                setInstance(instance)
                instance.UI.loadDocument(data.data, {filename: `${paperName}.pdf`});
            });
        }
        if (data && instance) {
            instance.UI.loadDocument(data.data, {filename: `${paperName}.pdf`});
            instance.Core.documentViewer.addEventListener('documentLoaded', function() {
                instance.setFitMode(instance.FitMode.FitWidth);
            });
        }
    }, [data])

    useEffect(() => {
        if (instance) {
            if (status === 'pending') {
                instance.openElements(['loadingModal']);
            } else {
                instance.closeElements(['loadingModal']);
            }
        }
    }, [status])

    return (
        <div className="MyComponent">
            <div className="webviewer" style={{height}} ref={viewer}></div>
        </div>
    );

}

export default PdfViewer;