import React from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';

import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';

const Filters = ({
                     filters,
                     handleChangeFilters = () => {
                     }
                 }) => {
    const {t} = useTranslation();

    const sortOptions = [
        {id: 'RECENT', name: 'Recent verticals', value: 'RECENT'},
        {id: 'TOP', name: 'Top verticals', value: 'TOP'},
    ];

    return (
        <Grid container justifyContent="flex-end" item xs={12}>
            <Grid item xs={12}>
                <LabeledCustomSelect
                    sx={{
                        backgroundColor: '#fff', '.MuiOutlinedInput-notchedOutline': {
                            top: 0,
                            'legend': {display: 'none'},
                        },
                        '.MuiInputBase-input': {
                            fontSize: '0.875rem !important'
                        }
                    }}
                    id="select-sort-by-options"
                    name="sortBy"
                    selectItems={sortOptions}
                    value={filters.sortBy}
                    onChange={(e) => {
                        handleChangeFilters(prev => {
                            return {...prev, sortBy: e.target.value}
                        })
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default Filters;
