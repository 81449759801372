import React, {useEffect, useRef, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useResizeDetector} from 'react-resize-detector';
import Box from '@mui/material/Box';
import alpha from 'color-alpha'

import useDebounce from 'hooks/useDebounce';

const BarChart = ({title, handleClick, data = [], maxHeight = '80vh'}) => {
    const {width, ref} = useResizeDetector();
    const chartWidth = useDebounce(width, 200);

    const charOptions = () => {
        return {
            chart: {
                type: 'bar',
                spacingLeft: 200,
            },
            title: {
                text: title,
                align: 'left'
            },
            xAxis: {
                title: {
                    text: null
                },
                gridLineWidth: 1,
                lineWidth: 0,
                labels: {
                    useHTML: true,
                    allowOverlap: false,
                    style: {
                        wordBreak: 'break-all',
                        textOverflow: 'ellipsis',
                        width: 196,
                        fontSize: '0.875rem',
                        fontFamily: 'inherit',
                        textAlign: 'left',
                        overflow: 'hidden',
                    },
                    align: 'left',
                    x: -200,
                    events: {
                        click: function () {
                            // console.log(this.value)
                            // onClickLabel(this.value)
                        }
                    }
                }
            },
            yAxis: {
                min: 0,
                labels: {
                    overflow: 'justify',
                },
                title: {
                    text: null
                },
                gridLineWidth: 0,
                events: {
                    click: function () {
                        console.log(this)
                    }
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: '50%',
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:,.2f}'
                    },
                    groupPadding: 0.05
                },
                series: {
                    animation: false,
                    pointWidth: 15
                }
            },
            tooltip: {
                style: {
                    fontSize: "0.875rem",
                },
                formatter: function () {
                    return `${this.key} : ${this.point.y.toFixed(2)}`;
                },
            },
            credits: {
                enabled: false
            },
        };
    }

    const [options, setOptions] = useState(charOptions());
    const chartRef = useRef(null);

    useEffect(() => {

            const categories = []
            const roc = []
            const f1 = []
            const acc = []
            for (const value of data) {
                categories.push(value.model_name)
                roc.push({y: value.roc_auc, id: value.model_id})
                f1.push({y: value.f1_score, id: value.model_id})
                acc.push({y: value.accuracy, id: value.model_id})
            }

            const series = [
                {
                    name: 'ROC AUC',
                    data: roc,
                    point: {
                        events: {
                            click: handleClick,
                        },
                    },
                    color: alpha('#3401af', .5)
                },
                {
                    name: 'F1 Score',
                    data: f1,
                    point: {
                        events: {
                            click: handleClick,
                        },
                    },
                    color: alpha('#df812e', .5)
                }, {
                    name: 'Accuracy',
                    data: acc,
                    point: {
                        events: {
                            click: handleClick,
                        },
                    },
                    color: alpha('#49ac43', .5)
                }]
            setOptions({
                ...charOptions(),
                series: series,
                xAxis: {
                    categories
                }
            });
        }, [data]
    )

    useEffect(() => {
        if (chartRef) {
            chartRef.current.chart.reflow();
        }

    }, [chartWidth])

    return (<Box sx={{height: '100%', minHeight: maxHeight, maxHeight}} ref={ref}>
            <HighchartsReact containerProps={{style: {height: "100%"}}} ref={chartRef} highcharts={Highcharts}
                             options={options}/>
        </Box>
    );
};

export default BarChart;
