import {useInfiniteQuery} from '@tanstack/react-query';

import api from 'services/api';

const fetchEvidences = async ({pageParam = 1, filters, sort, vertical}) => {
    const query = new URLSearchParams({
        page: pageParam
    });

    if (filters.search) {
        query.append('paper_name', filters.search);
    }

    if ([0, 1].includes(filters.is_extracted_evidences)) {
        query.append('is_extracted_evidences', filters.is_extracted_evidences);
    }

    if (['NOT_EXTRACTED', 'SUCCESS', 'FAILED', 'PARTIAL'].includes(filters.paper_evidence_status)) {
        query.append('paper_evidence_status', filters.paper_evidence_status);
    }

    if (sort.created_at && sort.current === 'created_at') {
        query.append('sort_by', 'created_at');
        query.append('order', sort.created_at);
    }

    if (vertical) {
        query.append('vertical', vertical);
    }

    try {
        const response = await api.get(`api/v1/papers/evidences?${query.toString()}`);
        const {
            data: {payload, meta},
        } = await response.json();

        return {data: payload, meta};
    } catch (e) {
        return Promise.reject(e);
    }
};

const useEvidences = ({filters, sort, vertical, enabled = true,}) => {
    return useInfiniteQuery({
        queryKey: ['evidences', filters, vertical, sort],
        queryFn: ({pageParam = 1}) => fetchEvidences({pageParam, filters, sort, vertical}),
        enabled,
        getNextPageParam: ({meta: {next_page}}, allPages) => {
            if (next_page === 0) return undefined;
            return next_page;
        },
    });
};

export default useEvidences;
