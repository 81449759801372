import React, {useEffect, useRef, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useResizeDetector} from 'react-resize-detector';
import Box from '@mui/material/Box';
import highchartsMore from 'highcharts/modules/heatmap';

import useDebounce from 'hooks/useDebounce';

highchartsMore(Highcharts);

const ConfusionMatrixChart = ({data, maxHeight = '80vh'}) => {
    const {width, ref} = useResizeDetector();
    const chartWidth = useDebounce(width, 200);

    const charOptions = () => {
        return {
            chart: {
                type: 'heatmap',
                marginTop: 40,
                marginBottom: 60
            },
            title: {
                text: null
            },
            xAxis: {
                categories: ['Negative', 'Positive'],
                title: {
                    text: 'Predicted'
                }
            },
            yAxis: {
                categories: ['Negative', 'Positive'],
                title: {
                    text: 'Actual'
                },
                reversed: true
            },
            colorAxis: {
                min: 0,
                stops: [
                    [0, '#FFFFFF'],
                    [0.5, '#997fd7'],
                    [1, '#434348']
                ]
            },
            legend: {
                align: 'right',
                layout: 'vertical',
                margin: 0,
                verticalAlign: 'top',
                y: 25,
                symbolHeight: 280
            },
            tooltip: {
                formatter: function () {
                    return `<b>${this.series.xAxis.categories[this.point.x]}</b> predicted as <br><b>${this.series.yAxis.categories[this.point.y]}</b>: <br><b>${this.point.value}</b> instances`;
                }
            },
            credits: {
                enabled: false
            },
        }
    }

    const [options, setOptions] = useState(charOptions());
    const chartRef = useRef(null);

    useEffect(() => {
            let matrix = []
            if (data) {
                matrix = [
                    // [x, y, value]
                    [0, 0, data['true_negatives']],  // Actual Negative - Predicted Negative (True Negative)
                    [0, 1, data['false_positives']],  // Actual Negative - Predicted Positive (False Positive)
                    [1, 0, data['false_negatives']],   // Actual Positive - Predicted Negative (False Negative)
                    [1, 1, data['true_positives']]   // Actual Positive - Predicted Positive (True Positive)
                ]
            }

            setOptions({
                ...charOptions(),
                series: [{
                    name: 'Confusion Matrix',
                    borderWidth: 1,
                    data: matrix,
                    dataLabels: {
                        enabled: true,
                        color: '#000000'
                    }
                }],
            });
        }, [data]
    )

    useEffect(() => {
        if (chartRef) {
            chartRef.current.chart.reflow();
        }

    }, [chartWidth])

    return (<Box sx={{height: '100%', minHeight: maxHeight, maxHeight}} ref={ref}>
            <HighchartsReact containerProps={{style: {height: "100%"}}} ref={chartRef} highcharts={Highcharts}
                             options={options}/>
        </Box>
    );
};

export default ConfusionMatrixChart;
