import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import {
    TextField
} from '@mui/material';
import {Formik} from 'formik';

const PaperForm = ({
                       formRef,
                       onSubmit,
                       edit = false,
                       paper,
                   }) => {

    const {t} = useTranslation();

    const validationSchema = Yup.object().shape({
        paper_name: Yup.string().required(t('Name is required')),
    });

    return (
        <Grid conainer item xs={12}>
            <Formik
                initialValues={{
                    paper_index: edit ? paper['paper_index'] : '',
                    paper_name: edit ? paper['paper_name'] : '',
                    source_url: edit ? paper['source_url'] : '',
                    provider_url: edit ? paper['provider_url'] : '',
                    citation_count: edit ? paper['citation_count'] : null,
                    published_year: edit ? paper['published_year'] : null,
                    abstract: edit ? paper['abstract'] : '',
                    apa_citation: edit ? paper['apa_citation'] : '',
                }}
                innerRef={formRef}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <TextField id="name"
                                       label={t("inbox.create.name")}
                                       required
                                       name="paper_name"
                                       value={values.paper_name}
                                       onChange={handleChange}
                                       error={Boolean(touched.paper_name && errors.paper_name)}
                                       helperText={touched.paper_name && errors.paper_name}
                                       fullWidth
                                       variant="outlined" size="small"/>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField id="link"
                                       label={t("inbox.create.sourceUrl")}
                                       required
                                       name="source_url"
                                       value={values.source_url}
                                       onChange={handleChange}
                                       error={Boolean(touched.source_url && errors.source_url)}
                                       helperText={touched.source_url && errors.source_url}
                                       fullWidth
                                       type={'url'}
                                       variant="outlined" size="small"/>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField id="link"
                                       label={t("Provider Url")}
                                       required
                                       name="provider_url"
                                       value={values.provider_url}
                                       onChange={handleChange}
                                       error={Boolean(touched.provider_url && errors.provider_url)}
                                       helperText={touched.provider_url && errors.provider_url}
                                       fullWidth
                                       type={'url'}
                                       variant="outlined" size="small"/>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField id="published_year"
                                       label={t("inbox.create.publishedYear")}
                                       name="published_year"
                                       value={values.published_year}
                                       onChange={handleChange}
                                       error={Boolean(touched.published_year && errors.published_year)}
                                       helperText={touched.published_year && errors.published_year}
                                       fullWidth
                                       type={"number"}
                                       variant="outlined" size="small"/>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField id="citation_count"
                                       label={t("inbox.create.citations")}
                                       name="citation_count"
                                       value={values.citation_count}
                                       onChange={handleChange}
                                       error={Boolean(touched.citation_count && errors.citation_count)}
                                       helperText={touched.citation_count && errors.citation_count}
                                       fullWidth
                                       type={"number"}
                                       variant="outlined" size="small"/>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField id="abstract"
                                       label={t("inbox.create.abstract")}
                                       name="abstract"
                                       value={values.abstract}
                                       onChange={handleChange}
                                       error={Boolean(touched.abstract && errors.abstract)}
                                       helperText={touched.abstract && errors.abstract}
                                       fullWidth
                                       minRows={4}
                                       maxRows={4}
                                       multiline
                                       variant="outlined" size="small"/>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField id="apa_citation"
                                       label={t("inbox.create.apaCitation")}
                                       name="apa_citation"
                                       value={values.apa_citation}
                                       onChange={handleChange}
                                       error={Boolean(touched.apa_citation && errors.apa_citation)}
                                       helperText={touched.apa_citation && errors.apa_citation}
                                       fullWidth
                                       minRows={4}
                                       maxRows={4}
                                       multiline
                                       variant="outlined" size="small"/>
                        </Grid>

                    </Grid>
                )}
            </Formik>
        </Grid>
    );
};

export default PaperForm;