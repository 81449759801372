import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
    profile: null,
    role: "",
    authenticated: false,
};

const appSlice = createSlice({
    name: 'auth',
    initialState: INITIAL_STATE,
    reducers: {
        notifyAuthSuccess(state, action) {
            state.profile = action.payload.profile;
            state.role = action.payload.role;
            state.authenticated = action.payload.authenticated;
        },
    },
});

export const { name, actions, reducer } = appSlice;

export const { notifyAuthSuccess } = actions;

export default reducer;
