import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import { useViewport } from 'hooks/useViewport';
import { notifyChangePageTitle } from 'state/app';

const PageContainer = ({ pageTitle, pageSubTitle, pageTooltip, children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { height } = useViewport();

    useLayoutEffect(() => {
        dispatch(notifyChangePageTitle({ pageTitle, pageSubTitle, pageTooltip }));
    }, [dispatch, location, pageTitle, pageSubTitle, pageTooltip]);

    const containerHeight = height - (theme.mixins.toolbar.minHeight + 1) - parseInt(theme.layouts.main.padding, 10);

    if (!children || !containerHeight) {
        return <></>;
    }

    return <>{React.cloneElement(children, { containerHeight })}</>;
};

export default PageContainer;
