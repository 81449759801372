import React, {useState} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';

import useHover from 'hooks/useHover';
import CardHeaderColumn from 'components/elements/CardHeader';
import {ROUTE_INBOX_MODEL_PERFORMANCE_OVERVIEW} from 'pages/constants';
import CustomTooltip from 'components/elements/CustomTooltip';

const CardRoot = styled(Box)(() => ({
    padding: '2px 0px 6px 0px',
}));

const Accordion = styled(MuiAccordion)(({theme, active = false}) => ({
    border: '0.1em solid #E9E9E9',
    boxShadow: 'none',
    ...(active && {
        background: 'aliceblue',
    }),
}));

const ModelCard = ({style, data}) => {
        const {t} = useTranslation();
        const [hoverRef] = useHover();
        let [searchParams] = useSearchParams();
        const navigate = useNavigate();

        const [expand, setExpand] = useState(false);

        const onToggleExpandRow = () => {
            setExpand((prev) => !prev);
        };

        const isActive = () => {
            return data['run_id'] === searchParams.get('pipeline_id')
        }

        const handleViewModelOverview = () => {
            navigate({
                pathname: ROUTE_INBOX_MODEL_PERFORMANCE_OVERVIEW.replace(':modelId', data['model_id']),
            });
        }

        return (
            <CardRoot style={style} ref={hoverRef}>
                <Accordion expanded={expand} TransitionProps={{unmountOnExit: true}} active={isActive()}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            '&.MuiAccordionSummary-root': {boxShadow: '1px 2px 13px -10px #888'},
                            '&.MuiAccordionSummary-root.Mui-expanded': {minHeight: 'unset'},
                            div: {
                                '&.MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                            },
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}

                    >
                        <Grid container alignItems="center" justifyContent="space-between" flexWrap='nowrap' spacing={1}
                              onClick={onToggleExpandRow}>
                            <CardHeaderColumn
                                spacing={3}
                                headerName={t('Vertical')}
                                headerTitle={<Grid
                                    item
                                    container
                                    alignItems="center"
                                >
                                    <Grid item>{data['vertical_name']}</Grid>
                                </Grid>}
                            />
                            <CardHeaderColumn
                                spacing={2.25}
                                headerName={t('No of papers')}
                                headerTitle={data['no_of_papers']}
                            />
                            <CardHeaderColumn
                                spacing={2.25}
                                headerName={t('No of papers manually flagged')}
                                headerTitle={data['no_of_manually_flagged_papers']}
                            />
                            <CardHeaderColumn
                                spacing={2}
                                headerName={t('Top model accuracy')}
                                headerTitle={data['accuracy'] ? data['accuracy'].toFixed(2) : ''}
                            />
                            <CardHeaderColumn
                                spacing={2}
                                headerName={t('Top model f1 score')}
                                headerTitle={data['f1_score'] ? data['f1_score'].toFixed(2) : ''}
                            />
                            <Grid item xs={0.5} container>
                                {data['model_id'] && <Grid item>
                                    <CustomTooltip title={'View top model'}>
                                        <IconButton onClick={handleViewModelOverview}>
                                            <LaunchIcon/>
                                        </IconButton>
                                    </CustomTooltip>
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                </Accordion>
            </CardRoot>
        );
    }
;

export default ModelCard;