import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import RefreshIcon from '@mui/icons-material/Refresh';

import PageContainer from 'components/elements/PageContainer';
import CustomButton from 'components/elements/CustomButton';

import Filters from './filters';
import ScoreSessionList from './score-session-list';

const Classifications = () => {
    const {t} = useTranslation();

    return (
        <PageContainer pageTitle={t('pipelines.classifications.pageTitle')} pageSubTitle={t('pipelines.classifications.pageSubTitle')}>
            <ClassificationsPage/>
        </PageContainer>
    );
};

const ClassificationsPage = ({ containerHeight }) => {
    const { t } = useTranslation();
    const [filters, setFilters] = React.useState({
        search: '',
        vertical: ''
    });
    const [sort, setSort] = useState({
        name: '',
        order: ''
    });

    const [paperAdding, setPaperAdding] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const handleSearch = (search) => {
        setFilters({
            ...filters,
            search
        })
    }

    const handleAdd = () => {
        setPaperAdding(true)
    }

    const handleAddPaperClose = () => {
        setPaperAdding(false)
    }

    const handleRefresh = () => {
        setRefresh(Math.random())
    }

    return (
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={1} sx={{padding: '0 1em !important'}}>

            <Grid item container xs={12} alignItems={'center'}>
                <Filters handleSearch={handleSearch} filters={filters} handleFilters={setFilters}/>
            </Grid>

            <Grid item container xs={12} justifyContent={'flex-end'} spacing={3}>
                <Grid item>
                    <CustomButton sx={{color: '#656565'}} startIcon={<RefreshIcon sx={{color:'#0794d3'}}/>}
                               onClick={handleRefresh}>{t('Refresh')}</CustomButton>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <ScoreSessionList height={containerHeight - 144}
                              refresh={refresh}
                              sort={sort} filters={filters}/>
            </Grid>

        </Grid>
    );
};


export default Classifications;
