import React, {useRef} from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

import CustomDrawer from 'components/elements/CustomDrawer';
import PdfViewer from 'components/elements/PdfViewer';


const ViewPdfDrawer = ({open, onclose,width='50vw'}) => {
    const viewer = useRef(null);
    const instance = useRef(null);

    return <CustomDrawer open={open} onclose={onclose}>
        <Box
            sx={{width, padding: 2}}
        >
            <Grid container spacing={1}>
                <Grid item xs={12} container justifyContent='flex-end' alignItems={'center'}>
                    <Grid item>
                        <IconButton
                            aria-label='close'
                            onClick={onclose}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {open && <PdfViewer paperId={open['paper_id']} paperName={open['paper_name']} viewer={viewer} instance={instance}/>}
                </Grid>
            </Grid>
        </Box>
    </CustomDrawer>
}

export default ViewPdfDrawer;