import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import {Card} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import PageContainer from 'components/elements/PageContainer';
import useClassifierModels from 'hooks/classifiers/useClassifierModels';
import GraphCard from 'components/elements/GraphCard';
import {ROUTE_INBOX_MODEL_PERFORMANCE_OVERVIEW} from 'pages/constants';

import ModelList from './model-list';
import {useSelector} from 'react-redux';
import BarChart from './bar-chart';
import PieChart from './pie-chart';
import VerticalWiseModelBreakdown from "./vertical-wise-model-breakdown";

const ModelPerformance = () => {
    const {t} = useTranslation();

    return (
        <PageContainer pageTitle={t('inbox.modelPerformance.pageTitle')}
                       pageSubTitle={t('inbox.modelPerformance.pageSubTitle')}>
            <ModelPerformancePage/>
        </PageContainer>
    );
};

const ModelPerformancePage = ({containerHeight}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [filters, setFilters] = useState({
        status: 'SUCCESS',
        vertical: ''
    });
    const [paperDistribution, setPaperDistribution] = useState([])
    const [models, setModels] = useState([])
    const [selectedModel, setSelectedModel] = useState('')
    const selectedVertical = useSelector((state) => state.app.vertical);
    const [verticals, setVerticals] = useState([])

    const {
        data
    } = useClassifierModels({
        filters: {},
    });

    useEffect(() => {
        if (data) {
            const list = (data.pages.reduce((accum, curr) => {
                return [...accum, ...curr.data];
            }, []))
            let temp = [];
            for (const item of list) {
                for (const ver of item.verticals) {
                    const index = temp.findIndex(s => s.id === ver);
                    if (index >= 0) {
                        temp[index]['y'] += 1
                    } else {
                        temp.push({
                            id: ver,
                            name: ver,
                            y: 1,
                        },)
                    }

                }
            }
            setVerticals(temp)
            setModels(list)
        }
    }, [data])

    const handleModelChange = (model) => {
        setSelectedModel(model)
    }

    const handleViewModelOverview = (value) => {
        navigate({
            pathname: ROUTE_INBOX_MODEL_PERFORMANCE_OVERVIEW.replace(':modelId', value.point.id),
        });
    }

    return (
        <Grid container spacing={2} sx={{padding: '0.5em !important', overflow: 'auto', height: containerHeight - 4}}>
            {/*<Grid item xs={12} container justifyContent={'space-between'}>*/}
            {/*    <Filters filters={filters} handleFilters={setFilters} models={models.map((model) => {*/}
            {/*        return {*/}
            {/*            id: model.model_id,*/}
            {/*            name: model.model_id,*/}
            {/*            value: model.classifier_train_run_id,*/}
            {/*        }*/}
            {/*    })} handleModelChange={handleModelChange} model={selectedModel}/>*/}
            {/*    <Grid item xs={12} container justifyContent={'flex-end'} alignItems={'flex-end'} spacing={2}>*/}
            {/*        <Grid item>*/}
            {/*            <CustomButton sx={{color: '#656565'}} startIcon={<AddTaskIcon/>} fullWidth>Mark model*/}
            {/*                active</CustomButton>*/}
            {/*        </Grid>*/}
            {/*        <Grid item>*/}
            {/*            <CustomButton sx={{color: '#656565'}} startIcon={<LaunchIcon/>} fullWidth>Deep dive model*/}
            {/*                performance</CustomButton>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}

            <Grid item xs={7}>
                <GraphCard title={'Performance Scores'}>
                    <BarChart maxHeight={'600px'} data={models} handleClick={handleViewModelOverview}/>
                </GraphCard>
            </Grid>

            <Grid item xs={5}>
                <VerticalWiseModelBreakdown/>
            </Grid>

            <Grid item xs={12}>
                <Card sx={{padding: 2, height: '100%'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ModelList height={containerHeight - 64} handleViewModelOverview={handleViewModelOverview}/>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>

        </Grid>
    );
};

export default ModelPerformance;
