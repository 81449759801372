import {useInfiniteQuery} from '@tanstack/react-query';

import api from 'services/api';

const fetchEvidenceExtractions = async ({pageParam = 1, filters, vertical}) => {
    const query = new URLSearchParams({
        page: pageParam
    });

    if (vertical) {
        query.append('vertical', vertical);
    }

    try {
        const response = await api.get(`api/v1/evidences/extractions?${query.toString()}`);
        const {
            data: {payload, meta},
        } = await response.json();

        return {data: payload, meta};
    } catch (e) {
        return Promise.reject(e);
    }
};

const useEvidenceExtractions = ({filters, vertical, enabled = true,}) => {
    return useInfiniteQuery({
        queryKey: ['evidence-extractions', filters, vertical],
        queryFn: ({pageParam = 1}) => fetchEvidenceExtractions({pageParam, filters, vertical}),
        enabled,
        retryOnMount:'always',
        getNextPageParam: ({meta: {next_page}}, allPages) => {
            if (next_page === 0) return undefined;
            return next_page;
        },
    });
};

export default useEvidenceExtractions;
