import {useMutation} from '@tanstack/react-query';
import api from 'services/api';

const usePaperUpdateValidated = () => {
    const updatePaper = async (req, paperId) => {
        try {
            const trials = await api.patch(
                `api/v1/papers/${paperId}`,
                JSON.stringify(req),
            );

            const data = await trials.json();

            return data.data;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation({
        mutationFn: ({req, paperId}) => updatePaper(req, paperId),
    });
};

export default usePaperUpdateValidated;