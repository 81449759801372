import React, {useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {useTranslation} from 'react-i18next';
import {Button, Checkbox, FormControlLabel} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';

import usePaperCreateWithPDF from 'hooks/papers/usePaperCreateWithPDF';
import AlertCard from 'components/elements/AlertCard';
import useVerticalCreate from 'hooks/vertical/useVerticalCreate';
import {notifyChangeVertical, notifyUpdateVerticals} from 'state/app';

import PaperImportPDFForm from './paper-import-pdf-form';
import SelectVerticalForm from '../createSearch/select-vertical-form';

const ImportPaperDialog = ({open, handleClose}) => {
    const {t} = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const [verticals, setVerticals] = useState([]);
    const [navigate, setNavigate] = useState(true);
    const [step, setStep] = useState(0);
    const [request, setRequest] = useState(null);

    const initData = useSelector((state) => state.app.initData);
    const selectedVertical = useSelector((state) => state.app.vertical);

    const {mutateAsync, status} = usePaperCreateWithPDF();
    const {mutateAsync: createVertical, status: verticalStatus} = useVerticalCreate();

    useEffect(() => {
        if (initData) {
            setVerticals(initData.verticals.map((vertical) => {
                return {
                    id: vertical.vertical,
                    name: vertical.vertical_name,
                    value: vertical.vertical,
                };
            }))
        }
    }, [initData])

    const handleSave = () => {
        formRef.current.handleSubmit();
    }

    const handleBack = ()=>{
        setRequest((req)=>{
            return {...req,...formRef.current.values}
        })
        setStep(0)
    }

    const handleSubmit = (values) => {
        if (step === 0) {
            setRequest(values);
            setStep(1)
        } else if (step === 1) {
            if (request['create_new']) {
                const vertical = {
                    vertical: request['vertical'],
                    vertical_name: request['vertical_name'],
                }
                createVertical({req: vertical}).then(res => {
                    handleImport({
                        ...request,
                        ...values
                    })
                    dispatch(notifyChangeVertical(res.data.vertical));
                    dispatch(notifyUpdateVerticals({
                        vertical: res.data.vertical,
                        vertical_name: res.data.vertical_name
                    }))
                })
            } else {
                handleImport({
                    ...request,
                    ...values
                })
            }

        }

    }

    const handleImport = (values) => {
        const req = {
            vertical: values.vertical,
            source_urls: values.source_urls.split(/\r?\n/),
            ...(values.download_session_name === '' && {
                download_session_name: `${values.vertical}-Search-${Date.now()}`
            })
        }
        mutateAsync({req}).then(() => {
            handleClose({navigate})
        });
    }

    const getVerticalName = () => {
        if (request) {
            if (request['create_new']) {
                return `to ${request['vertical_name']}`
            } else {
                const index = verticals.findIndex(v => v.value === request['vertical'])
                if (index >= 0) {
                    return `to ${verticals[index].name}`
                } else {
                    return ''
                }
            }
        } else {
            return ''
        }

    }

    const handleChangeNavigate = () => {
        setNavigate(prevState => !prevState)
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={'md'}
            >
                <DialogContent sx={{padding: '2rem', minHeight: '30vh'}}>
                    <Stack direction='row' spacing={1} justifyContent='flex-end'>
                        <IconButton
                            aria-label='close'
                            onClick={handleClose}
                            sx={{
                                backgroundColor: '#333333',
                                padding: '4px',
                                '&:hover': {
                                    backgroundColor: '#333333',
                                },
                            }}
                        >
                            <CloseIcon sx={{color: '#ffffff', fontSize: '1.2rem'}}/>
                        </IconButton>
                    </Stack>
                    <Grid container spacing={4}>
                        <Grid item xs={11} container spacing={2}>
                            <Grid item xs={12}
                                  sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Typography sx={{
                                    fontWeight: '700',
                                    fontSize: '1.2rem'
                                }}>{t(`Import papers with PDF URLs ${getVerticalName()}`)}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{paddingTop: '4px !important'}}>
                                <Divider sx={{borderBottom: '2px solid black;', width: '100%'}}/>
                            </Grid>
                        </Grid>
                        {status === 'error' && <Grid item xs={11} container spacing={3}>
                            <AlertCard severity={'error'} message={'Failed to import papers'} height={'auto'}/>
                        </Grid>}
                        {step === 0 && <Grid item xs={11} container spacing={3}>
                            <SelectVerticalForm formRef={formRef} onSubmit={handleSubmit} verticals={verticals} request={request}
                                                selectedVertical={selectedVertical}/>
                        </Grid>}
                        {step === 1 && <Grid item xs={11} container spacing={3}>
                            <PaperImportPDFForm formRef={formRef} onSubmit={handleSubmit} selectedVertical={selectedVertical}/>
                        </Grid>
                        }
                        <Grid item xs={11} container justifyContent={'flex-end'} spacing={2} alignItems={'center'}>
                            {step === 1 && <Grid item>
                                <FormControlLabel
                                    sx={{marginLeft:0}}
                                    control={
                                        <Checkbox checked={navigate}
                                                  onChange={(e) => handleChangeNavigate()}
                                                  name="gilad"/>
                                    }
                                    labelPlacement="start"
                                    label="Navigate to download sessions on submit"
                                />
                            </Grid>}

                            {step === 1 && <Grid item>
                                <Button variant="outlined" disabled={status === 'pending'}
                                        onClick={handleBack}>Back</Button>
                            </Grid>}

                            <Grid item>
                                <Button variant="contained" disabled={status === 'pending'}
                                        onClick={handleSave}>{step === 0 ? 'Proceed' : 'Import'}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default ImportPaperDialog;