import React, {useEffect, useRef, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useResizeDetector} from 'react-resize-detector';
import Box from '@mui/material/Box';

import useDebounce from 'hooks/useDebounce';
import alpha from "color-alpha";

const BarChart = ({title, data = [], maxHeight = '300px'}) => {
    const {width, ref} = useResizeDetector();
    const chartWidth = useDebounce(width, 200);

    const charOptions = () => {
        return {
            chart: {
                type: 'bar',
                spacingLeft: 150,
            },
            title: {
                text: title,
                align: 'left'
            },
            xAxis: {
                title: {
                    text: null
                },
                gridLineWidth: 1,
                lineWidth: 0,
                labels: {
                    useHTML: true,
                    allowOverlap: false,
                    style: {
                        wordBreak: 'break-all',
                        textOverflow: 'ellipsis',
                        width: 146,
                        fontSize: '0.875rem',
                        fontFamily: 'inherit',
                        textAlign: 'left',
                        overflow: 'hidden',
                    },
                    align: 'left',
                    x: -150,
                }
            },
            yAxis: {
                min: 0,
                labels: {
                    overflow: 'justify'
                },
                title: {
                    text: null
                },
                gridLineWidth: 0
            },
            plotOptions: {
                bar: {
                    borderRadius: '50%',
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:,.2f}'
                    },
                    groupPadding: 0.1
                },
                series: {
                    animation: false
                }
            },
            tooltip: {
                style: {
                    fontSize: "0.875rem",
                },
                useHTML: true,
                formatter: function () {
                    return `<span>${this.key}<br/> ${this.point.series.name}: ${this.point.y.toFixed(2)}</span>`;
                },
            },
            credits: {
                enabled: false
            },
        };
    }

    const [options, setOptions] = useState(charOptions());
    const chartRef = useRef(null);

    useEffect(() => {

            const categories = []
            const roc = []
            const f1 = []
            const acc = []
            for (const value of data) {
                categories.push(value.model_name)
                roc.push(value.accuracy)
                f1.push(value.f1_score)
                acc.push(value.roc_auc)
            }

            let series = [
                {
                    name: 'ROC AUC',
                    data: roc,
                    color: alpha('#3401af', .5)
                },
                {
                    name: 'F1 Score',
                    data: f1,
                    color: alpha('#df812e', .5)
                }, {
                    name: 'Accuracy',
                    data: acc,
                    color: alpha('#49ac43', .5)
                }]
            setOptions({
                ...charOptions(),
                series: series,
                xAxis: {
                    categories
                }
            });
        }, [data]
    )

    useEffect(() => {
        if (chartRef) {
            chartRef.current.chart.reflow();
        }

    }, [chartWidth])

    return (<Box sx={{height: '100%', minHeight: maxHeight, maxHeight}} ref={ref}>
            <HighchartsReact containerProps={{style: {height: "100%"}}} ref={chartRef} highcharts={Highcharts}
                             options={options}/>
        </Box>
    );
};

export default BarChart;
