import {useMutation} from '@tanstack/react-query';
import api from 'services/api';

const usePaperDelete = () => {
    const deletePaper = async (paperId) => {
        try {
            const trials = await api.delete(
                `api/v1/papers/${paperId}`
            );

            const data = await trials.json();

            return data.data;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation({
        mutationFn: ({paperId}) => deletePaper(paperId),
    });
};

export default usePaperDelete;