import React, {useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {useTranslation} from 'react-i18next';
import {Button, Checkbox, FormControlLabel} from '@mui/material';
import {useSelector} from 'react-redux';

import CustomButton from 'components/elements/CustomButton';
import useTrainClassifierTrigger from 'hooks/classifiers/useTrainClassifierTrigger';
import AlertCard from 'components/elements/AlertCard';
import useClassifierConfigs from 'hooks/classifiers/useClassifierConfigs';

import TrainForm from './train-form';
import AdvancedConfigs from './advanced-configs';

const TrainClassifierDialog = ({open, handleClose}) => {
    const {t} = useTranslation();
    const formRef = useRef();
    const [verticals, setVerticals] = useState([]);
    const initData = useSelector((state) => state.app.initData);
    const selectedVertical = useSelector((state) => state.app.vertical);
    const [navigate, setNavigate] = useState(true);
    const [request, setRequest] = useState(null);

    useEffect(() => {
        if (initData) {
            setVerticals(initData.verticals.map((vertical) => {
                return {
                    id: vertical.vertical,
                    name: `${vertical.vertical_name}`,
                    value: vertical.vertical,
                };
            }))
        }
    }, [initData])

    const {mutateAsync, status} = useTrainClassifierTrigger();
    const {mutateAsync: fetchConfigs} = useClassifierConfigs()

    const handleTrigger = () => {
        formRef.current.setFieldValue('trigger', true)
        formRef.current.handleSubmit();
    }

    const handleSubmit = (values) => {
        if (values.trigger) {
            const req = {
                ...values,
                ...request
            }
            if (req.step === 'train') {
                delete req['step']
                delete req['trigger']
                fetchConfigs({verticals: req.verticals}).then((data) => {
                    const newReq = {
                        ...req,
                        ...data.data
                    }
                    triggerClassifier(newReq)
                })
            } else {
                delete req['step']
                delete req['trigger']
                triggerClassifier(req)
            }
        }

        if (values.step === 'advanced') {
            setRequest({...request, ...values})
        }
        if (values.step === 'train') {
            setRequest({...request, ...values})
        }
    }

    const triggerClassifier = (req) => {
        mutateAsync({req}).then(() => {
            handleClose({navigate})
        });
    }

    const getVerticalName = () => {
        const index = verticals.findIndex(v => v.value === selectedVertical)
        if (index >= 0) {
            return verticals[index].name
        } else {
            return ''
        }
    }

    const handleAdvanced = () => {
        formRef.current.setFieldValue('step', 'advanced')
        formRef.current.handleSubmit();
    }

    const handleBack = () => {
        formRef.current.setFieldValue('step', 'train')
        formRef.current.handleSubmit();
    }

    const handleChangeNavigate = () => {
        setNavigate(prevState => !prevState)
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={'lg'}
            >
                <DialogContent sx={{padding: '2rem', height: '80vh'}}>
                    <Stack direction='row' spacing={1} justifyContent='flex-end'>
                        <IconButton
                            aria-label='close'
                            onClick={handleClose}
                            sx={{
                                backgroundColor: '#333333',
                                padding: '4px',
                                '&:hover': {
                                    backgroundColor: '#333333',
                                },
                            }}
                        >
                            <CloseIcon sx={{color: '#ffffff', fontSize: '1.2rem'}}/>
                        </IconButton>
                    </Stack>
                    <Grid container>
                        <Grid item xs={11} container spacing={2}>
                            <Grid item xs={12}
                                  sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Typography sx={{
                                    fontWeight: '700',
                                    fontSize: '1.2rem'
                                }}>{t('Train Classifier')}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{paddingTop: '4px !important', paddingBottom: '2em !important'}}>
                                <Divider sx={{borderBottom: '2px solid black;', width: '100%'}}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={11} container direction={'column'} justifyContent={'space-between'}
                              flexWrap={'nowrap'}
                              sx={{height: '100%'}}>
                            <Grid item xs={11} container spacing={2} sx={{
                                overflowY: 'auto',
                                paddingTop: '0 !important',
                                overflowX: 'hidden', minHeight: 'calc(80vh - 232px)',maxHeight: 'calc(80vh - 232px)'
                            }}>

                                {status === 'error' && <Grid item xs={11} container spacing={3}>
                                    <AlertCard severity={'error'} message={'Failed to trigger train classifier'}
                                               height={'auto'}/>
                                </Grid>}
                                {request && request.step === 'advanced' ?
                                    <AdvancedConfigs formRef={formRef} request={request} onSubmit={handleSubmit}/> :
                                    <TrainForm formRef={formRef} onSubmit={handleSubmit}
                                               vertical={getVerticalName()}
                                               request={request}
                                               verticals={verticals}/>
                                }

                            </Grid>
                            <Grid item xs={11} container spacing={2}>
                                <Grid item xs={12} container spacing={2} justifyContent={'flex-end'}>
                                    {request && request.step === 'advanced' ? <Grid item>
                                            <CustomButton disableRipple
                                                          onClick={handleBack}>{t('Go back')}</CustomButton>
                                        </Grid> :
                                        <Grid item>
                                            <CustomButton disableRipple
                                                          onClick={handleAdvanced}>{t('Tune training parameters')}</CustomButton>
                                        </Grid>
                                    }
                                </Grid>

                                <Grid item xs={12} container justifyContent={'flex-end'} spacing={2}>
                                    <Grid item>
                                        <FormControlLabel
                                            sx={{marginLeft: 0}}
                                            control={
                                                <Checkbox checked={navigate}
                                                          onChange={(e) => handleChangeNavigate()}
                                                          name="gilad"/>
                                            }
                                            labelPlacement="start"
                                            label="Navigate to model training sessions on submit"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" disabled={status === 'loading'}
                                                onClick={handleClose}>Cancel</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" disabled={status === 'loading'}
                                                onClick={handleTrigger}>Train</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default TrainClassifierDialog;