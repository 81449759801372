import React, {useEffect, useRef, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useResizeDetector} from 'react-resize-detector';
import Box from '@mui/material/Box';

import useDebounce from 'hooks/useDebounce';
import alpha from "color-alpha";

const ColumnChart = ({title, data = [],maxHeight='400px'}) => {
    const {width, ref} = useResizeDetector();
    const chartWidth = useDebounce(width, 200);

    const charOptions = () => {
        return {
            chart: {
                type: 'column'
            },
            title: {
                text: title,
                align: 'left'
            },
            xAxis: {
                categories: ['HE', 'HEJ', 'CS'],
                crosshair: true,
                accessibility: {
                    description: 'Countries'
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                valueSuffix: ' '
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                },
                series:{
                    animation: false
                }
            },
            credits: {
                enabled: false
            },
        };
    }

    const [options, setOptions] = useState(charOptions());
    const chartRef = useRef(null);

    useEffect(() => {

            const categories = []
            const extracted = []
            const notExtracted = []
            for (const value of data) {
                categories.push(value.vertical)
                extracted.push(value.extracted_count)
                notExtracted.push(value.un_extracted_count)
            }
            const series = [
                {
                    name: 'Evidence Extracted',
                    data: extracted,
                    color: alpha('#3401af', .5)
                },
                {
                    name: 'No Evidence Extracted',
                    data: notExtracted,
                    color: alpha('#df812e', .5)
                }
            ]
            setOptions({
                ...charOptions(),
                series: series,
                xAxis: {
                    categories
                }
            });
        },
        [data]
    )

    useEffect(() => {
        if (chartRef) {
            chartRef.current.chart.reflow();
        }

    }, [chartWidth])

    return (<Box sx={{height:'100%',minHeight: maxHeight, maxHeight}} ref={ref}>
            <HighchartsReact containerProps={{style: {height: "100%"}}} ref={chartRef} highcharts={Highcharts} options={options}/>
        </Box>
    );
};

export default ColumnChart;
