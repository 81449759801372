import {useMutation} from '@tanstack/react-query';
import api from 'services/api';

const useVerticalUpdate = () => {
    const updateVertical = async (req,vertical) => {
        try {
            const response = await api.put(
                `api/v1/verticals/${vertical}`,
                JSON.stringify(req),
            );

            const {
                data: {payload, meta},
            } = await response.json();

            return {data: payload, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation({
        mutationFn: ({req,vertical}) => updateVertical(req,vertical),
    });
};

export default useVerticalUpdate;