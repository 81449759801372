import React from 'react';
import Grid from '@mui/material/Grid';
import styled from '@mui/system/styled';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

const AvatarContainer = styled(Grid)(() => ({
    padding: '0 16px 0 16px',
    flexWrap: 'nowrap',
}));

const UserNameRoleContainer = styled(Grid)({
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '9px',
});

const UserName = styled(Typography)(({theme}) => ({
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
    color: theme.palette.grey[650],
}));

const AvatarUser = ({userName = ''}) => {
    return (
        <AvatarContainer container>
            <Grid item>
                <Avatar>{userName ? userName.match(/\b(\w)/g).join('') : ''}</Avatar>
            </Grid>
            <UserNameRoleContainer container item>
                <Grid item>
                    <UserName>{userName}</UserName>
                </Grid>
            </UserNameRoleContainer>
        </AvatarContainer>
    );
};

export default AvatarUser;
