import React, {useEffect, useRef, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useResizeDetector} from 'react-resize-detector';
import Box from '@mui/material/Box';

import useDebounce from 'hooks/useDebounce';

const TimeSeriesChart = ({title, series = [], maxHeight = '350px'}) => {
    const {width, ref} = useResizeDetector();
    const chartWidth = useDebounce(width, 200);

    const charOptions = () => {
        return {
            chart: {
                zoomType: 'x'
            },
            title: {
                text: title,
                align: 'left'
            },
            xAxis: {
                type: 'datetime',
                // labels: {
                //     formatter: function() {
                //         console.log('Value is', this);
                //         return this.value;
                //     }
                // }
            },
            yAxis: {
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat:'',
                pointFormat: '{point.x}: <b>{point.y}</b>'
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    events: {
                        show: function () {
                            var chart = this.chart,
                                series = chart.series,
                                i = series.length,
                                otherSeries;
                            while (i--) {
                                otherSeries = series[i];
                                if (otherSeries !== this && otherSeries.visible) {
                                    otherSeries.hide();
                                }
                            }
                        },
                        legendItemClick: function () {
                            if (this.visible) {
                                return false;
                            }
                        }
                    },
                    animation: false

                },
                area: {
                    marker: {
                        radius: 2
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: null
                }
            },
            credits: {
                enabled: false
            },
        };
    }

    const [options, setOptions] = useState(charOptions());
    const chartRef = useRef(null);

    const colors = ["#7cb5ec", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1", '#a6c96a'];

    useEffect(() => {
            setOptions({
                ...charOptions(),
                series: series,
            });
        },
        [series]
    )

    useEffect(() => {
        if (chartRef) {
            chartRef.current.chart.reflow();
        }

    }, [chartWidth])

    return (<Box sx={{height: '100%', minHeight: maxHeight, maxHeight}} ref={ref}>
            <HighchartsReact containerProps={{style: {height: "100%"}}} ref={chartRef} highcharts={Highcharts}
                             options={options}/>
        </Box>
    );
};

export default TimeSeriesChart;
