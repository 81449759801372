import {useMutation} from '@tanstack/react-query';

import api from 'services/api';

const fetchClassifierConfigs = async ({verticals}) => {
    const query = new URLSearchParams({});

    if (verticals) {
        query.append('verticals', verticals);
    }

    try {
        const response = await api.get(`api/v1/classifiers/configs?${query.toString()}`);
        const {
            data: {payload, meta},
        } = await response.json();

        return {data: payload, meta};
    } catch (e) {
        return Promise.reject(e);
    }
};

const useClassifierConfigs = () => {
    return useMutation({
        mutationFn: ({verticals}) => fetchClassifierConfigs({verticals}),
    });
};

export default useClassifierConfigs;
