import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';
import CustomTooltip from 'components/elements/CustomTooltip';
import {notifyChangeVertical} from 'state/app';
import CreateVerticalDialog from './create-vertical-dialog';

const VerticalSelector = ({disabled = false}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [verticals, setVerticals] = useState([]);
    const [vertical, setVertical] = useState('');
    const [verticalAdding, setVerticalAdding] = useState(false);

    const initData = useSelector((state) => state.app.initData);
    const selectedVertical = useSelector((state) => state.app.vertical);

    useEffect(() => {
        if (initData) {
            const verticalList = initData.verticals.map((vertical) => {
                return {
                    id: vertical.vertical,
                    name: vertical.vertical_name,
                    value: vertical.vertical,
                };
            })
            if (!selectedVertical) {
                const item = JSON.parse(localStorage.getItem('vertical'));
                if (!item) {
                    dispatch(notifyChangeVertical(verticalList[0]?.value));
                } else {
                    dispatch(notifyChangeVertical(item));
                }
            } else {
                setVertical(selectedVertical);
            }
            setVerticals(verticalList)
        }
    }, [initData])

    useEffect(() => {
        if (selectedVertical) {
            setVertical(selectedVertical);
            localStorage.setItem('vertical', JSON.stringify(selectedVertical));
        }
    }, [selectedVertical])

    const handleAddVertical = () => {
        setVerticalAdding(true)
    }

    const handleOnChange = (value) => {
        dispatch(notifyChangeVertical(value));
    }

    const handleAddVerticalClose = () => {
        setVerticalAdding(false)
    }

    return <Grid item container spacing={0.5} justifyContent={"flex-end"}>
        <Grid item xs={12}>
            <Typography
                sx={{
                    fontSize: '0.75rem',
                    color: '#989898'
                }}>{'Vertical'}</Typography>
        </Grid>

        <Grid item xs={12}>
            <Paper elevation={0} sx={{
                border: "#bec0c2 1px solid",
                borderColor: "#bec0c2",
                borderRadius: 1,
                backgroundColor: '#fff',
                ':hover': {borderColor: 'rgba(0, 0, 0, 0.87)'},
                ':focus': {borderColor: '#0069da'}
            }}>
                <Grid container justifyContent={'space-between'} alignItems={'center'} flexWrap={'nowrap'}>
                    <Grid item sx={{width: '100%'}}>
                        <LabeledCustomSelect
                            label={t('dataset.name')}
                            id="select-dataset"
                            name="dataset"
                            emptyPlaceholder={true}
                            InputLabelProps={{shrink: false}}
                            selectItems={verticals}
                            height={"calc(100vh/2)"}
                            disabled={disabled}
                            value={vertical}
                            sx={{
                                backgroundColor: '#fff', '.MuiOutlinedInput-notchedOutline': {
                                    top: 0,
                                    border: 'none',
                                    'legend': {display: 'none'},
                                },
                                '.MuiOutlinedInput-input': {
                                    padding: '6px 14px'
                                }
                            }}
                            onChange={(e) => {
                                handleOnChange(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item sx={{width: 'fit-content'}} alignItems={'center'} container flexWrap={'nowrap'}>
                        <Grid item>
                            <Divider orientation='vertical' sx={{height: '1.6rem'}}/>
                        </Grid>
                        <Grid item>
                            <CustomTooltip title={'New Vertical'}>
                                <IconButton disableRipple sx={{padding: '0 0.25em'}}
                                            onClick={handleAddVertical}>
                                    <AddBoxIcon sx={{width: '1.6rem', height: '1.6rem'}}/>
                                </IconButton>
                            </CustomTooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

        </Grid>
        {verticalAdding &&
            <CreateVerticalDialog open={verticalAdding} handleClose={handleAddVerticalClose}/>}
    </Grid>;
};

export default VerticalSelector;
