import React from 'react';
import Grid from '@mui/material/Grid';
import {Card} from '@mui/material';
import Typography from '@mui/material/Typography';

const SummaryCard = ({
                         title, count, onClick = () => {
    }
                     }) => {
    return <Grid item xs={4}>
        <Card sx={{padding: 2}}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography sx={{
                        fontSize: '1rem',
                        fontWeight: '700',
                        textAlign: 'center',
                        color: '#797979',
                        textTransform: 'capitalize'
                    }}>{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography onClick={onClick} sx={{
                        fontSize: '2.25rem',
                        fontWeight: '700',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }}>{count}</Typography>
                </Grid>
            </Grid>
        </Card>
    </Grid>
}

export default SummaryCard;
