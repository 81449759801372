import React from 'react';
import {useTranslation} from 'react-i18next';
import {TableVirtuoso} from 'react-virtuoso';
import styled from '@mui/system/styled';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MuiHelpOutlineIcon from '@mui/icons-material/HelpOutline';

import CustomTooltip from '../CustomTooltip';
import InvalidItemCard from '../InvalidItemCard';

const HelpOutlineIcon = styled(MuiHelpOutlineIcon)(({theme}) => ({
    fontSize: 'small',
}));

const ListItem = ({index, item, itemTemplate: Component}) => {
    if (!item) {
        return <InvalidItemCard/>;
    }

    return <Component data={item} index={index}/>;
};


const CustomInfiniteLoadTable = ({
                                     columns = [],
                                     handleSort,
                                     sort,
                                     height,
                                     data,
                                     isFetchingNextPage,
                                     itemTemplate,
                                     fetchNextPage,
                                     hasNextPage,
                                 }) => {
    const {t} = useTranslation();


    const loadMore = (isFetchingNextPage && !hasNextPage) ? () => {
    } : fetchNextPage;

    return (
        <TableVirtuoso
            style={{height: height}}
            totalCount={data.length}
            data={data}
            components={{
                Table: ({style, ...props}) => <Table {...props} style={{...style, minWidth: 1440}}/>,
                TableRow: ({style, ...props}) => <TableRow {...props} style={{...style, background: '#ffffff',}}/>,
                TableHead: ({style, ...props}) => <TableHead {...props} style={{...style, background: '#ffffff',}}/>,
            }}
            fixedHeaderContent={() => (
                <>{columns.map(column => {
                    return <TableCell align={column.align} style={{
                        width: column.width,
                        minWidth:column.minWidth,
                        background: '#ffffff',
                        ...(column.sticky && {position: 'sticky', zIndex: 1}),
                        ...(column.align === 'left' && {left: 0}),
                        ...(column.align === 'right' && {right: 0}),
                    }}>
                        <Grid container alignItems={'center'} flexWrap={'nowrap'}
                              sx={{
                                  ...(column.align === 'left' && {justifyContent: 'flex-start',}),
                                  ...(column.align === 'right' && {justifyContent: 'flex-end'}),
                                  ...(column.align === 'center' && {justifyContent: 'center'})
                              }}>
                            {column.sortable && column.align === 'right' && sort['current'] === column.accessor &&
                                <Grid item>
                                    {sort[column.accessor] === 'desc' && <IconButton onClick={() => handleSort({
                                        ...sort,
                                        current: column.accessor,
                                        [column.accessor]: 'asc'
                                    })}><ArrowDownwardIcon sx={{fontSize: '0.95rem',}}/></IconButton>}
                                    {sort[column.accessor] === 'asc' && <IconButton onClick={() => handleSort({
                                        ...sort,
                                        current: column.accessor,
                                        [column.accessor]: 'desc'
                                    })}><ArrowUpwardIcon
                                        sx={{fontSize: '0.95rem',}}/></IconButton>}
                                </Grid>}
                            <Grid item>
                                <Typography sx={{
                                    fontSize: '0.875rem',
                                    fontWeight: 700,
                                    ...(column.align === 'center' && {textAlign: 'center'}),
                                    ...(column.align === 'right' && {textAlign: 'end'}),
                                    ...(column.sortable && {cursor: 'pointer'})
                                }} onClick={() => {
                                    if (column.sortable) {
                                        handleSort({
                                            ...sort,
                                            current: column.accessor,
                                            [column.accessor]: sort[column.accessor] === 'asc' ? 'desc' : 'asc'
                                        })
                                    }
                                }}>{column.Header}
                                    {column.tooltip && <CustomTooltip title={column.tooltip}> <span
                                        style={{verticalAlign: '-0.1em'}}><HelpOutlineIcon fontSize="1.6rem"
                                                                                           sx={{'&.MuiSvgIcon-root': {color: '#666666'}}}/></span></CustomTooltip>}
                                </Typography>
                            </Grid>
                            {column.sortable && column.align === 'left' && sort['current'] === column.accessor &&
                                <Grid item>
                                    {sort[column.accessor] === 'desc' && <IconButton onClick={() => handleSort({
                                        ...sort,
                                        current: column.accessor,
                                        [column.accessor]: 'asc'
                                    })}><ArrowDownwardIcon sx={{fontSize: '0.95rem',}}/></IconButton>}
                                    {sort[column.accessor] === 'asc' && <IconButton onClick={() => handleSort({
                                        ...sort,
                                        current: column.accessor,
                                        [column.accessor]: 'desc'
                                    })}><ArrowUpwardIcon sx={{fontSize: '0.95rem',}}/></IconButton>}
                                </Grid>}
                        </Grid>
                    </TableCell>
                })}</>
            )}
            itemContent={(index, item) => ListItem({index, item, itemTemplate})}
            endReached={loadMore}
        />
    );
};

export default CustomInfiniteLoadTable;
