import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

import {
    ROUTE_PLAYS_EXPLORE,
    ROUTE_PLAYS,
    ROUTE_EVIDENCE,
    ROUTE_EVIDENCE_EXPLORE,
    ROUTE_INBOX,
    ROUTE_INBOX_EXPLORE,
    ROUTE_APP_DASHBOARD,
    ROUTE_SETTINGS,
    ROUTE_INBOX_MODEL_PERFORMANCE,
    ROUTE_ROOT,
    ROUTE_INBOX_SEARCH_PHRASES,
    ROUTE_PIPELINES,
    ROUTE_PIPELINES_EXTRACTIONS,
    ROUTE_PIPELINES_DOWNLOAD_SESSIONS,
    ROUTE_PIPELINES_CLASSIFICATION_SESSIONS,
    ROUTE_PIPELINES_MODEL_TRAINING_SESSIONS,
    ROUTE_INBOX_MODEL_PERFORMANCE_OVERVIEW,
} from 'pages/constants';

import MainLayout from 'layouts/main';
import AuthorizedRoute from 'components/base/AuthorizedRoute';
import Dashboard from 'pages/app/dashboard';
import Plays from 'pages/app/plays';
import Settings from 'pages/app/settings';
import EvidenceExplore from 'pages/app/evidence/explore';
import InboxExplore from 'pages/app/inbox/explore';
import DownloadSessions from 'pages/app/pipelines/downloadSessions';
import ModelPerformance from 'pages/app/inbox/modelPerformance';
import SearchPhrases from 'pages/app/inbox/searchPhrases';
import ExtractionSessions from './pipelines/extractionSessions';
import Classifications from './pipelines/scoreClassifierSessions';
import ModelTrainings from './pipelines/trainClassifierSessions';
import ModelOverview from './inbox/modelPerformance/modelOverview/model-overview';

const AppRouter = () => {
    return (
        <Routes>
            <Route path={ROUTE_APP_DASHBOARD} element={<MainLayout />}>
                <Route element={<AuthorizedRoute action="dashboard.view" />}>
                    <Route index element={<Dashboard />} />
                </Route>
            </Route>

            <Route path={ROUTE_PLAYS} element={<MainLayout />}>
                <Route element={<AuthorizedRoute action="play.view" />}>
                    <Route index path={ROUTE_PLAYS_EXPLORE} element={<Plays />} />
                </Route>
                {/* <Route element={<AuthorizedRoute action="play.create" />}>
                    <Route index path={ROUTE_PLAYS_CREATE} element={<Track />} />
                </Route> */}
            </Route>

            <Route path={ROUTE_EVIDENCE} element={<MainLayout />}>
                <Route element={<AuthorizedRoute action="evidence.view" />}>
                    <Route index path={ROUTE_EVIDENCE_EXPLORE} element={<EvidenceExplore />} />
                </Route>
            </Route>

            <Route path={ROUTE_INBOX} element={<MainLayout />}>
                <Route element={<AuthorizedRoute action="evidence.view" />}>
                    <Route index path={ROUTE_INBOX_EXPLORE} element={<InboxExplore />} />
                </Route>
                <Route element={<AuthorizedRoute action="evidence.view" />}>
                    <Route index path={ROUTE_INBOX_SEARCH_PHRASES} element={<SearchPhrases />} />
                </Route>
                <Route element={<AuthorizedRoute action="evidence.view" />}>
                    <Route index path={ROUTE_INBOX_MODEL_PERFORMANCE} element={<ModelPerformance />} />
                </Route>
                <Route element={<AuthorizedRoute action="evidence.view" />}>
                    <Route index path={ROUTE_INBOX_MODEL_PERFORMANCE_OVERVIEW} element={<ModelOverview />} />
                </Route>
            </Route>

            <Route path={ROUTE_PIPELINES} element={<MainLayout />}>
                <Route element={<AuthorizedRoute action="evidence.view" />}>
                    <Route index path={ROUTE_PIPELINES_DOWNLOAD_SESSIONS} element={<DownloadSessions />} />
                </Route>
                <Route element={<AuthorizedRoute action="evidence.view" />}>
                    <Route index path={ROUTE_PIPELINES_EXTRACTIONS} element={<ExtractionSessions />} />
                </Route>
                <Route element={<AuthorizedRoute action="evidence.view" />}>
                    <Route index path={ROUTE_PIPELINES_CLASSIFICATION_SESSIONS} element={<Classifications />} />
                </Route>
                <Route element={<AuthorizedRoute action="evidence.view" />}>
                    <Route index path={ROUTE_PIPELINES_MODEL_TRAINING_SESSIONS} element={<ModelTrainings />} />
                </Route>
            </Route>

            <Route path={ROUTE_SETTINGS} element={<MainLayout />}>
                <Route element={<AuthorizedRoute action="settings.manage" />}>
                    <Route index element={<Settings />} />
                </Route>
            </Route>

            <Route path={ROUTE_ROOT} element={<Navigate to={ROUTE_APP_DASHBOARD}/>}/>
        </Routes>
    );
};

export default AppRouter;
