import React, {useState} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import {format, parseISO} from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import {useDispatch} from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';

import useHover from 'hooks/useHover';
import CardHeaderColumn from 'components/elements/CardHeader';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import useVerticalDelete from 'hooks/vertical/useVerticalDelete';
import {notifyDeleteVertical} from 'state/app';
import CustomTooltip from 'components/elements/CustomTooltip';

import UpdateVerticalDialog from './updateVertical/update-vertical-dialog';

const CardRoot = styled(Box)(() => ({
    padding: '2px 0px 6px 0px',
}));

const Accordion = styled(MuiAccordion)(({theme, active = false}) => ({
    border: '0.1em solid #E9E9E9',
    boxShadow: 'none',
    ...(active && {
        background: 'aliceblue',
    }),
}));

const VerticalCard = ({style, data, index, handleRefetch}) => {
        const {t} = useTranslation();
        const [hoverRef] = useHover();
        const dispatch = useDispatch();

        const [expand, setExpand] = useState(false);

        const [verticalDeleting, setVerticalDeleting] = useState(null);
        const [verticalUpdating, setVerticalUpdating] = useState(null);

        const {mutateAsync, status} = useVerticalDelete();

        const handleDeleteOpen = (vertical) => {
            setVerticalDeleting(vertical)
        }

        const handleDeleteVerticalClose = () => {
            setVerticalDeleting(null)
        }

        const handleUpdateOpen = (vertical) => {
            setVerticalUpdating(vertical)
        }

        const handleUpdateVerticalClose = () => {
            setVerticalUpdating(null);
            handleRefetch();
        }

        const handleDelete = (vertical) => {
            mutateAsync({vertical}).then(() => {
                setVerticalDeleting(null);
                handleRefetch();
                dispatch(notifyDeleteVertical(vertical));
            })
        }

        return (
            <CardRoot style={style} ref={hoverRef}>
                <Accordion expanded={expand} TransitionProps={{unmountOnExit: true}}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            '&.MuiAccordionSummary-root': {boxShadow: '1px 2px 13px -10px #888'},
                            '&.MuiAccordionSummary-root.Mui-expanded': {minHeight: 'unset'},
                            div: {
                                '&.MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                            },
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}

                    >
                        <Grid container justifyContent="space-between" flexWrap='nowrap' spacing={1}>
                            {/*<CardHeaderColumn*/}
                            {/*    spacing={3}*/}
                            {/*    headerName={t('Vertical Id')}*/}
                            {/*    headerTitle={<Grid*/}
                            {/*        item*/}
                            {/*        container*/}
                            {/*        alignItems="center"*/}
                            {/*    >*/}
                            {/*        <Grid item>{data['vertical']}</Grid>*/}
                            {/*    </Grid>}*/}
                            {/*/>*/}
                            <CardHeaderColumn
                                spacing={6}
                                headerName={t('Vertical name')}
                                headerTitle={data['vertical_name']}
                            />
                            <CardHeaderColumn
                                spacing={3}
                                headerName={t('Created on')}
                                headerTitle={data['created_at'] ? format(parseISO(data['created_at']), 'Pp') : 'N/A'}
                            />
                            <Grid item container spacing={3} justifyContent={"flex-end"} alignItems="center"
                                  sx={{height: '100%'}} xs={1.6}>
                                <Grid item>
                                    <CustomTooltip title={'Edit vertical'}>
                                        <IconButton onClick={(e) => {
                                            e.stopPropagation();
                                            handleUpdateOpen(data);
                                        }}>
                                            <EditIcon fontSize={'small'}/>
                                        </IconButton>
                                    </CustomTooltip>
                                </Grid>
                                <Grid item>
                                    <CustomTooltip title={'Delete vertical'}>
                                        <IconButton onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteOpen(data);
                                        }}>
                                            <DeleteIcon fontSize={'small'}/>
                                        </IconButton>
                                    </CustomTooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                </Accordion>
                <>{verticalDeleting && <ConfirmationDialog open={verticalDeleting}
                                                           onConfirm={() => handleDelete(verticalDeleting.vertical)}
                                                           title={`Delete vertical ${verticalDeleting.vertical_name}`}
                                                           context={`Are you sure want to delete vertical ${verticalDeleting.vertical_name}?`}
                                                           confirmText={'Delete'}
                                                           onClose={handleDeleteVerticalClose}/>}</>
                <>{verticalUpdating && <UpdateVerticalDialog open={verticalUpdating} handleClose={handleUpdateVerticalClose}/>}</>
            </CardRoot>
        );
    }
;

export default VerticalCard;