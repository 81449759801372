import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';

import VerticalSelector from 'components/elements/VerticalSelector';
import FilterButton from 'components/elements/FilterButton';
import FilterDropdown from 'components/elements/FilterDropdown';
import {notifyChangeEvidencesFilters} from 'state/app';

import FilterForm from './filter-form';

const Filters = ({}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const filters = useSelector((state) => state.app.evidencesPage.filters);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleFilters = (value) => {
        dispatch(notifyChangeEvidencesFilters(value));
    }

    const handleFilterOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleFilterClose = (value) => {
        setAnchorEl(null);
    };

    const handleSubmit = (values) => {
        handleFilters({
            ...filters,
            is_extracted_evidences: values.is_extracted_evidences ? 1 : 0,
            paper_evidence_status: values.paper_evidence_status
        })
        handleFilterClose()
    }

    const getFilterCount = () => {
        let count = 0
        if (filters.is_extracted_evidences) {
            count++;
        }
        if (filters.paper_evidence_status !== '') {
            count++;
        }
        return count;
    };

    const handleSearch = (search) => {
        handleFilters({
            ...filters,
            search
        })
    }

    return (
        <Grid container flexWrap="nowrap" spacing={2} justifyContent={'space-between'}>
            <Grid item xs={4}>
                <VerticalSelector/>
            </Grid>
            <Grid item xs={4} container justifyContent={'flex-end'} spacing={0.5}>
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            fontSize: '0.75rem',
                            color: '#989898'
                        }}>{'Filter By'}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <FilterButton handleSearch={handleSearch} placeholder={t('Search by paper name')}
                                  filterCount={getFilterCount()}
                                  handleFilterOpen={handleFilterOpen}/>
                </Grid>
                <>{open && <FilterDropdown
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleFilterClose}
                >
                    <FilterForm onSubmit={handleSubmit} filters={filters}/>
                </FilterDropdown>}</>
            </Grid>
        </Grid>
    );
};

export default Filters;
