import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import { TextField} from '@mui/material';
import {FieldArray, Formik,getIn} from 'formik';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';


const EvidenceForm = ({
                          formRef,
                          onSubmit,
                          edit = false,
                          evidence
                      }) => {

    const {t} = useTranslation();

    const validationSchema = Yup.object().shape({
        // objective: Yup.string().required(t('Objective is required')),
        // treatment: Yup.string().required(t('Treatment is required')),
        // population_characteristics: Yup.string().required(t('Population haracteristics is required')),
        // population: Yup.string().required(t('Population is required')),
    });

    return (
        <Grid container item xs={12}>
            <Formik
                initialValues={{
                    research_objective: evidence ? evidence['research_objective'] : '',
                    population_characteristics: evidence ? evidence['population_characteristics'] : '',
                    population: evidence ? evidence['population'] : '',
                    treatment_used: evidence ? evidence['treatment_used'] : '',
                    study_design: evidence ? evidence['study_design'] : '',
                    outcome_metrics: evidence ? evidence['outcome_metrics'] : [{
                        outcome: '',
                        p_value: '',
                        effect_size: '',
                        confidence_interval:'',
                        metric_name:''
                    }]
                }}
                innerRef={formRef}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Grid container spacing={2} sx={{height:'fit-content'}}>
                        <Grid item xs={12}>
                            <TextField id="research_objective"
                                       label={t("Objective")}
                                       name="research_objective"
                                       value={values.research_objective}
                                       onChange={handleChange}
                                       error={Boolean(touched.research_objective && errors.research_objective)}
                                       helperText={touched.research_objective && errors.research_objective}
                                       fullWidth
                                       multiline
                                       minRows={2}
                                       variant="outlined" size="small"/>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField id="study_design"
                                       label={t("Study Design")}
                                       name="study_design"
                                       value={values.study_design}
                                       onChange={handleChange}
                                       error={Boolean(touched.study_design && errors.study_design)}
                                       helperText={touched.study_design && errors.study_design}
                                       fullWidth
                                       multiline
                                       minRows={2}
                                       variant="outlined" size="small"/>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField id="treatment_used"
                                       label={t("Treatment")}
                                       name="treatment_used"
                                       value={values.treatment_used}
                                       onChange={handleChange}
                                       error={Boolean(touched.treatment_used && errors.treatment_used)}
                                       helperText={touched.treatment_used && errors.treatment_used}
                                       fullWidth
                                       multiline
                                       minRows={2}
                                       variant="outlined" size="small"/>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField id="population_characteristics"
                                       label={t("Population Characteristics")}
                                       name="population_characteristics"
                                       value={values.population_characteristics}
                                       onChange={handleChange}
                                       error={Boolean(touched.population_characteristics && errors.population_characteristics)}
                                       helperText={touched.population_characteristics && errors.population_characteristics}
                                       fullWidth
                                       multiline
                                       minRows={2}
                                       variant="outlined" size="small"/>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField id="population"
                                       label={t("Population")}
                                       name="population"
                                       value={values.population}
                                       onChange={handleChange}
                                       error={Boolean(touched.population && errors.population)}
                                       helperText={touched.population && errors.population}
                                       fullWidth
                                       variant="outlined" size="small"/>
                        </Grid>

                        <Grid item xs={12} container>
                            {values.outcome_metrics &&  <FieldArray name="outcome_metrics">
                                {({insert, remove}) => (
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} container justifyContent={'space-between'}>
                                            <Grid item>
                                                <Typography>Outcomes</Typography>
                                            </Grid>
                                            <Grid item>
                                                <IconButton size={"small"}
                                                            onClick={() => {
                                                                insert(0, {
                                                                    outcome: '',
                                                                    p_value: '',
                                                                    effect_size: '',
                                                                    confidence_interval:'',
                                                                    metric_name:''
                                                                })
                                                            }}><AddIcon/></IconButton>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} container spacing={1}>
                                            {values['outcome_metrics'].map((field, index) => {
                                                return <Grid item container spacing={2} xs={12}>
                                                    {index>0 &&<Grid item xs={11} sx={{paddingTop: '1.5em !important'}}>
                                                        <Divider sx={{borderBottom: '2px solid rgb(233, 233, 233);', width: '100%'}}/>
                                                    </Grid>
                                                    }
                                                    <Grid item container spacing={2} xs={11} alignItems={'center'}>
                                                        <Grid item xs={6}>
                                                            <TextField id={`outcome_metrics.${index}.outcome`}
                                                                       name={`outcome_metrics.${index}.outcome`}
                                                                       error={Boolean(getIn(touched, `outcome_metrics.${index}.outcome`) && getIn(errors, `outcome_metrics.${index}.outcome`))}
                                                                       helperText={getIn(touched, `outcome_metrics.${index}.outcome`) && getIn(errors, `outcome_metrics.${index}.outcome`)}
                                                                       label={t("Outcome")}
                                                                       variant="outlined"
                                                                       fullWidth
                                                                       value={field.outcome}
                                                                       onChange={(e) => setFieldValue(`outcome_metrics.${index}.outcome`, e.target.value)}
                                                                       size="small"/>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <TextField id={`outcome_metrics.${index}.metric_name`}
                                                                       name={`outcome_metrics.${index}.metric_name`}
                                                                       error={Boolean(getIn(touched, `outcome_metrics.${index}.metric_name`) && getIn(errors, `outcome_metrics.${index}.metric_name`))}
                                                                       helperText={getIn(touched, `outcome_metrics.${index}.metric_name`) && getIn(errors, `outcome_metrics.${index}.metric_name`)}
                                                                       label={t("Metric name")}
                                                                       variant="outlined"
                                                                       fullWidth
                                                                       value={field.metric_name}
                                                                       onChange={(e) => setFieldValue(`outcome_metrics.${index}.metric_name`, e.target.value)}
                                                                       size="small"/>
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <TextField id={`outcome_metrics.${index}.p_value`}
                                                                       label={t("P Value")}
                                                                       name={`outcome_metrics.${index}.p_value`}
                                                                       error={Boolean(getIn(touched, `outcome_metrics.${index}.p_value`) && getIn(errors, `outcome_metrics.${index}.p_value`))}
                                                                       helperText={getIn(touched, `outcome_metrics.${index}.p_value`) && getIn(errors, `outcome_metrics.${index}.p_value`)}
                                                                       value={field.p_value}
                                                                       onChange={(e) => setFieldValue(`outcome_metrics.${index}.p_value`, e.target.value)}
                                                                       fullWidth
                                                                       variant="outlined" size="small"/>
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <TextField id={`outcome_metrics.${index}.effect_size`}
                                                                       label={t("Effect Size")}
                                                                       name={`outcome_metrics.${index}.effect_size`}
                                                                       error={Boolean(getIn(touched, `outcome_metrics.${index}.effect_size`) && getIn(errors, `outcome_metrics.${index}.effect_size`))}
                                                                       helperText={getIn(touched, `outcome_metrics.${index}.effect_size`) && getIn(errors, `outcome_metrics.${index}.effect_size`)}
                                                                       value={field.effect_size}
                                                                       onChange={(e) => setFieldValue(`outcome_metrics.${index}.effect_size`, e.target.value)}
                                                                       fullWidth
                                                                       variant="outlined" size="small"/>
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <TextField id={`outcome_metrics.${index}.confidence_interval`}
                                                                       label={t("Confidence Interval")}
                                                                       name={`outcome_metrics.${index}.confidence_interval`}
                                                                       error={Boolean(getIn(touched, `outcome_metrics.${index}.confidence_interval`) && getIn(errors, `outcome_metrics.${index}.confidence_interval`))}
                                                                       helperText={getIn(touched, `outcome_metrics.${index}.confidence_interval`) && getIn(errors, `outcome_metrics.${index}.confidence_interval`)}
                                                                       value={field.confidence_interval}
                                                                       onChange={(e) => setFieldValue(`outcome_metrics.${index}.confidence_interval`, e.target.value)}
                                                                       fullWidth
                                                                       variant="outlined" size="small"/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container alignItems={'center'} item xs={1}
                                                          justifyContent={"flex-end"}>
                                                        <Grid item>
                                                            <IconButton size={"small"}
                                                                        onClick={() => remove(index)}><CloseIcon/></IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            })}
                                        </Grid>
                                    </Grid>
                                )}
                            </FieldArray>
                            }
                        </Grid>

                    </Grid>
                )}
            </Formik>
        </Grid>
    );
};

export default EvidenceForm;