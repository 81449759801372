import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import {useSelector} from 'react-redux';

import useEvidences from 'hooks/evidences/useEvidences';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import InfiniteLoaderList from 'components/elements/InfiniteLoadList';
import AlertCard from 'components/elements/AlertCard';

import EvidenceCard from './evidence-card';

const EvidenceList = ({
                          height, refresh = 0, sort = {
        name: '',
        order: '',
    }, selectedEvidences, handleSelect, handleCount = () => {
    }, selectAll = false,
                      }) => {
    const {t} = useTranslation();
    const [flattenedResults, setFlattenedResults] = useState([]);
    const selectedVertical = useSelector((state) => state.app.vertical);
    const filters = useSelector((state) => state.app.evidencesPage.filters);

    const {data, isFetchingNextPage, hasNextPage, fetchNextPage, isError, status, refetch, isFetched} = useEvidences({
        filters,
        sort,
        vertical: selectedVertical,
        enabled: selectedVertical !== ''
    });

    useEffect(() => {
        if (data) {
            setFlattenedResults(data.pages.reduce((accum, curr) => {
                return [...accum, ...(curr.data ?? [])];
            }, []))
            if (data.pages.length > 0) {
                handleCount({
                    total_count:  data.pages[0].meta['total_count'],
                    not_extracted_count:  data.pages[0].meta['not_extracted_count'],
                    success_count:  data.pages[0].meta['success_count'],
                    partial_count:  data.pages[0].meta['partial_count'],
                    failed_count:  data.pages[0].meta['failed_count']
                })
            } else {
                handleCount({
                    total_count: 0,
                    not_extracted_count: 0,
                    success_count: 0,
                    partial_count: 0,
                    failed_count: 0
                })
            }
        }
    }, [data])

    useEffect(() => {
        refetch()
    }, [refresh])

    if (status === 'loading') {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height - 120}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    if (status === 'success' && flattenedResults && flattenedResults.length === 0) {
        return <Grid item xs={12} container spacing={1}>
            <AlertCard severity={'info'} message={'No data available'} height={height}/>
        </Grid>
    }

    return <>
        {flattenedResults && <InfiniteLoaderList
            height={height}
            data={flattenedResults}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isError={isError}
            itemTemplate={({data, index}) => <EvidenceCard data={data} index={index} handleRefetch={refetch}
                                                           selectAll={selectAll}
                                                           selected={selectedEvidences} handleSelect={handleSelect}/>}
        />}
    </>
};

export default EvidenceList;
