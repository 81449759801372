import React from 'react';

const ErrorFallback = ({ resetErrorBoundary }) => {
    return (
        <div role="alert">
            {/* <Alert severity="error" classes={{ message: classes.message }}>
        <div className={classes.content}>
          <AlertTitle>Error</AlertTitle>
          <span>Oops! something went wrong</span>
          <Button
            variant="contained"
            size="small"
            disableElevation
            classes={{ root: classes.buttonRoot }}
            onClick={resetErrorBoundary}
          >
            Try again
          </Button>
        </div>
      </Alert> */}
        </div>
    );
};

export default ErrorFallback;
