import {useMutation} from '@tanstack/react-query';

import api from 'services/api';

const usePaperUpload = () => {
    const uploadPaper = async (paperId, req) => {
        try {
            const trials = await api.upload(
                `api/v1/papers/${paperId}/upload`,
                req,
                {
                    timeout: 30000,
                },
            );
            const data = await trials.json();

            return data.data;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation({
        mutationFn: ({paperId,req}) => uploadPaper(paperId,req),
    });
};

export default usePaperUpload;
