import React from 'react';
import {useTranslation} from 'react-i18next';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CustomTooltip from 'components/elements/CustomTooltip';

import {getLabel, statusIcon, statusTooltip} from './utils';

const ExtractionItem = ({evidence, onClick, selected, width = 12}) => {
    const {t} = useTranslation();

    const getStatus = (status) => {

        if (status==='SUCCESS') {
            return <CustomTooltip title={statusTooltip('EXTRACTION_SUCCESS')}>
                {statusIcon('SUCCESS')}
            </CustomTooltip>
        } else if (status==='PARTIAL') {
            return <CustomTooltip title={statusTooltip('EXTRACTION_PARTIAL')}>
                {statusIcon('PARTIAL')}
            </CustomTooltip>
        } else if (status==='FAILED') {
            return <CustomTooltip title={statusTooltip('EXTRACTION_FAILED')}>
                {statusIcon('FAILED')}
            </CustomTooltip>
        } else {
            return <CustomTooltip title={statusTooltip('NOT_EXTRACTED')}>
                {statusIcon('NOT_EXTRACTED')}
            </CustomTooltip>
        }
    }

    return <ExtractionChip width={width} selected={selected} onClick={onClick}>
        <span style={{paddingRight: '0.5em'}}>{getStatus(evidence.status)}</span>
        <Typography textOverflow={'ellipsis'} sx={{minWidth:'78px',textWrap:'nowrap',overflow:'hidden'}}>
            <span>{getLabel(evidence.extraction_type)}</span>
        </Typography>
    </ExtractionChip>
}

const ExtractionChip = ({width = 12, selected, children, onClick}) => {
    return <Grid container item sx={{width:'fit-content',minWidth:'132px'}}>
        <Grid item xs={12} sx={{
            border: '1px solid #797979',
            borderRadius: '4px',
            height: 'fit-content',
            padding: '0.5em 0.5em 0.25em 0.5em',
            display: 'flex',
            cursor: 'pointer', ...(selected && {
                backgroundColor: '#f0f8ff'
            })
        }} onClick={onClick}>
            {children}
        </Grid>
    </Grid>
}

const ExtractionPdf = ({width = 12, selected, onClick}) => {
    return <ExtractionChip width={width} selected={selected} onClick={onClick}>
        <span style={{paddingRight: '0.5em'}}>
            <PictureAsPdfIcon sx={{color: '#0894d3'}}/>
        </span>
        <Typography>
            Pdf
        </Typography>
    </ExtractionChip>
}

export {ExtractionItem, ExtractionChip,ExtractionPdf};