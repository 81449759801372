import React from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';

import PageContainer from 'components/elements/PageContainer';

import CreationOverTime from './creation-over-time';
import Summary from './summary';
import PaperDistribution from './paper-distributuion';
import VerticalWiseProgress from "./vertical-wise-progress";

const Dashboard = () => {
    const {t} = useTranslation();

    return (
        <PageContainer pageTitle={t('app.dashboard.pageTitle')} pageSubTitle={t('app.dashboard.pageSubTitle')}>
            <DashboardPage/>
        </PageContainer>
    );
};

const DashboardPage = ({containerHeight}) => {
    const {t} = useTranslation();

    return (
        <Grid container spacing={2} sx={{padding: '1em !important', overflow: 'auto', height: containerHeight - 8}}>
            <Summary/>

            <Grid xs={12} item container spacing={2}>
                <Grid xs={4} item>
                    <PaperDistribution/>
                </Grid>
                <Grid xs={8} item>
                    <CreationOverTime/>
                </Grid>
            </Grid>
            <VerticalWiseProgress/>
        </Grid>
    );
};

export default Dashboard;
