import React from 'react';
import {Virtuoso} from 'react-virtuoso';

import InvalidItemCard from 'components/elements/InvalidItemCard';

const ListItem = ({index, item, itemTemplate: Component}) => {
    if (!item) {
        return <InvalidItemCard/>;
    }

    return <Component data={item} index={index}/>;
};

const InfiniteLoadList = ({
                              data,
                              isFetchingNextPage,
                              hasNextPage,
                              fetchNextPage,
                              isError,
                              itemTemplate,
                              height,
                              ref
                          }) => {

    const loadMore = (isFetchingNextPage && !hasNextPage) ? () => {
    } : fetchNextPage;

    return (
        <Virtuoso
            style={{
                height: `${height}px`,
                overflow:'auto',
            }}
            data={data}
            ref={ref}
            endReached={loadMore}
            overscan={50}
            itemContent={(index, item) => ListItem({index, item, itemTemplate})}
        />
    );
};

export default InfiniteLoadList;
