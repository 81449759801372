import React, {useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {useTranslation} from 'react-i18next';
import {Button, Checkbox, FormControlLabel} from '@mui/material';
import {useSelector} from 'react-redux';

import useEvidenceExtract from 'hooks/evidences/useEvidenceExtract';

import ExtractEvidenceForm from './extract-evidence-form';

const ExtractEvidenceDialog = ({open, handleClose, selected = [], selectAll = false, total = 0,filters}) => {
    const {t} = useTranslation();
    const formRef = useRef();
    const initData = useSelector((state) => state.app.initData);
    const selectedVertical = useSelector((state) => state.app.vertical);
    const [verticals, setVerticals] = useState([]);
    const [customGpts, setCustomGpts] = useState([]);
    const [navigate, setNavigate] = useState(true);

    useEffect(() => {
        if (initData) {
            setVerticals(initData.verticals.map((vertical) => {
                return {
                    id: vertical.vertical,
                    name: vertical.vertical_name,
                    value: vertical.vertical,
                };
            }))
            if (initData.extraction_options) {
                setCustomGpts(initData.extraction_options.map((gpt) => {
                    return {
                        name: gpt.custom_gpt,
                        value: gpt.custom_gpt,
                        active: gpt.custom_gpt !== 'GATES'
                    };
                }))
            }
        }
    }, [initData])

    const {mutateAsync, status} = useEvidenceExtract();

    const handleSave = () => {
        formRef.current.handleSubmit();
    }

    const handleSubmit = (values) => {
        const req = {
            ...values,
            vertical: selectedVertical,
            papers: selectAll ? [] : selected,
            select_all_papers: selectAll,
        }

        mutateAsync({req,filters}).then(() => {
            handleClose({navigate})
        });
    }

    const getVerticalName = () => {
        const index = verticals.findIndex(v => v.value === selectedVertical)
        if (index >= 0) {
            return verticals[index].name
        } else {
            return ''
        }
    }

    const handleChangeNavigate = () => {
        setNavigate(prevState => !prevState)
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={'md'}
            >
                <DialogContent sx={{padding: '2rem', minHeight: '25vh'}}>
                    <Stack direction='row' spacing={1} justifyContent='flex-end'>
                        <IconButton
                            aria-label='close'
                            onClick={handleClose}
                            sx={{
                                backgroundColor: '#333333',
                                padding: '4px',
                                '&:hover': {
                                    backgroundColor: '#333333',
                                },
                            }}
                        >
                            <CloseIcon sx={{color: '#ffffff', fontSize: '1.2rem'}}/>
                        </IconButton>
                    </Stack>
                    <Grid container spacing={4}>
                        <Grid item xs={11} container spacing={2}>
                            <Grid item xs={12}
                                  sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Typography sx={{
                                    fontWeight: '700',
                                    fontSize: '1.2rem'
                                }}>{t(`Extract Evidences using Customized GPT for ${getVerticalName()}`)}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{paddingTop: '4px !important'}}>
                                <Divider sx={{borderBottom: '2px solid black;', width: '100%'}}/>
                            </Grid>
                        </Grid>

                        <Grid item xs={11} container spacing={2}>
                            <Grid item xs={12}>
                                <Typography> Number of papers selected for evidence extraction: {selectAll ? total : selected.length}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ExtractEvidenceForm
                                    formRef={formRef}
                                    onSubmit={handleSubmit}
                                    customGpts={customGpts}
                                    selected={selected}
                                    selectedAll={selectAll}
                                    vertical={selectedVertical}
                                    count={selectAll ? total : selected.length}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={11} container justifyContent={'flex-end'} spacing={2} alignItems={'center'}>
                            <Grid item>
                                <FormControlLabel
                                    sx={{marginLeft: 0}}
                                    control={
                                        <Checkbox checked={navigate}
                                                  onChange={(e) => handleChangeNavigate()}
                                                  name="gilad"/>
                                    }
                                    labelPlacement="start"
                                    label="Navigate to extraction sessions on submit"
                                />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" disabled={status === 'loading'}
                                        onClick={handleSave}>Proceed</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default ExtractEvidenceDialog;