import React from 'react';
import { Oval } from 'react-loader-spinner';

const LoaderSpinner = ({ color,secondaryColor, height, width, text }) => {
  return (
    <>
      <Oval color={color} height={height} width={width} secondaryColor={secondaryColor} />
      {text && <h2>{text}</h2>}
    </>
  );
};

export default LoaderSpinner;
