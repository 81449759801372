import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import {Button, TextField} from '@mui/material';
import {Formik} from 'formik';

const FilterForm = ({
                        formRef,
                        onSubmit,
                        request,
                        edit = false,
                        paper
                    }) => {

    const {t} = useTranslation();

    const validationSchema = Yup.object().shape({});

    return (<Formik
            initialValues={{
                paper_index: edit ? paper['paper_index'] : '',
                paper_name: edit ? paper['paper_name'] : '',
                paper_link: edit ? paper['paper_link'] : '',
                paper_search_phrase: edit ? paper['paper_search_phrase'] : '',
                citation_count: edit ? paper['citation_count'] : '',
                published_year: edit ? paper['published_year'] : '',
                keyword_score: edit ? paper['keyword_score'] : '',
                table_score: edit ? paper['table_score'] : '',
                is_good_paper_manual_audit: edit ? paper['is_good_paper_manual_audit'] : false,
                is_good_paper_model_prediction: edit ? paper['is_good_paper_model_prediction'] : false,
                is_good_paper_model_prediction_score: edit ? paper['is_good_paper_model_prediction_score'] : '',
            }}
            innerRef={formRef}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleChange,
                  values,
                  touched,
                  errors,
                  setFieldValue
              }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField id="index"
                                   label={t("Source Provider")}
                                   name="paper_index"
                                   value={values.paper_index}
                                   onChange={handleChange}
                                   error={Boolean(touched.paper_index && errors.paper_index)}
                                   helperText={touched.paper_index && errors.paper_index}
                                   fullWidth
                                   variant="outlined" size="small"/>
                    </Grid>

                    <Grid item xs={12} container justifyContent={'flex-end'}>
                        <Grid item>
                            <Button variant="contained">Apply</Button>
                        </Grid>
                    </Grid>

                </Grid>
            )}
        </Formik>
    );
};

export default FilterForm;