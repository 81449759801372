import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import {Button, Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import {Formik} from 'formik';
import FormControl from '@mui/material/FormControl';
import LabeledCustomSelect from "../../../../components/elements/LabeledCustomSelect";

const FilterForm = ({
                        formRef,
                        onSubmit,
                        filters
                    }) => {

    const {t} = useTranslation();

    const validationSchema = Yup.object().shape({});

    const statues = [
        {
            id: 'NOT_EXTRACTED',
            name: 'Marked for extraction',
            value: 'NOT_EXTRACTED',
        },
        {
            id: 'SUCCESS',
            name: 'Success',
            value: 'SUCCESS',
        },
        {
            id: 'FAILED',
            name: 'Failed',
            value: 'FAILED',
        },
        {
            id: 'PARTIAL',
            name: 'Partially Extracted',
            value: 'PARTIAL',
        },
    ]

    return (<Formik
            initialValues={{
                is_extracted_evidences: !!filters['is_extracted_evidences'],
                paper_evidence_status: filters['paper_evidence_status']??''
            }}
            innerRef={formRef}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleChange,
                  values,
                  touched,
                  errors,
                  setFieldValue,
                  handleSubmit
              }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LabeledCustomSelect
                            placeholder={t('Paper evidence status')}
                            label={t('Paper evidence status')}
                            id="select-paper_evidence_status"
                            name="paper_evidence_status"
                            InputLabelProps={{shrink: false}}
                            selectItems={statues}
                            height={"calc(100vh/2)"}
                            onClear={() => setFieldValue('paper_evidence_status', '')}
                            value={values.paper_evidence_status}
                            error={Boolean(touched.paper_evidence_status && errors.paper_evidence_status)}
                            helperText={touched.paper_evidence_status && errors.paper_evidence_status}
                            onChange={(e) => setFieldValue('paper_evidence_status', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <FormGroup>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            sx={{marginLeft: 0}}
                                            control={
                                                <Checkbox checked={values.is_extracted_evidences}
                                                          onChange={(e) => setFieldValue('is_extracted_evidences', e.target.checked)}
                                                          name="gilad"/>
                                            }
                                            labelPlacement="start"
                                            label="Show evidence extracted papers only?"
                                        />
                                    </Grid>
                                </Grid>
                            </FormGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} container justifyContent={'flex-end'}>
                        <Grid item>
                            <Button variant="contained" onClick={handleSubmit}>Apply</Button>
                        </Grid>
                    </Grid>

                </Grid>
            )}
        </Formik>
    );
};

export default FilterForm;