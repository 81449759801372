import {useMutation} from '@tanstack/react-query';
import api from 'services/api';

const useEvidenceDelete = () => {
    const deleteEvidence = async (evidenceId) => {
        try {
            const evidences = await api.delete(
                `api/v1/evidences/${evidenceId}`
            );

            const data = await evidences.json();

            return data.data;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation({
        mutationFn: ({evidenceId}) => deleteEvidence(evidenceId),
    });
};

export default useEvidenceDelete;