import {useQuery} from '@tanstack/react-query';

import api from 'services/api';

const fetchCreationOverTime = async ({}) => {
    const query = new URLSearchParams({});
    try {

        const response = await api.get(`api/v1/classifiers/models/breakdown`);
        const {
            data: {payload, meta},
        } = await response.json();

        return {data: payload, meta};
    } catch (e) {
        return Promise.reject(e);
    }
};

const useModelBreakdown = ({enabled = true}) => {
    return useQuery({
        queryKey: ['dashboard-model-breakdown'],
        queryFn: ({}) => fetchCreationOverTime({}),
        enabled,
    });
};

export default useModelBreakdown;
