import React, {useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {useTranslation} from 'react-i18next';
import {Button} from '@mui/material';
import {useSelector} from 'react-redux';

import usePaperCreate from 'hooks/papers/usePaperCreate';
import AlertCard from 'components/elements/AlertCard';

import PaperForm from './paper-form';

const CreatePaperDialog = ({open, handleClose}) => {
    const {t} = useTranslation();
    const formRef = useRef();
    const [verticals, setVerticals] = useState([]);
    const initData = useSelector((state) => state.app.initData);
    const selectedVertical = useSelector((state) => state.app.vertical);

    useEffect(() => {
        if (initData) {
            setVerticals(initData.verticals.map((vertical) => {
                return {
                    id: vertical.vertical,
                    name: vertical.vertical_name,
                    value: vertical.vertical,
                };
            }))
        }
    }, [initData])

    const {mutateAsync, status} = usePaperCreate();

    const handleSave = () => {
        formRef.current.handleSubmit();
    }

    const handleSubmit = (values) => {
        const req = {
            vertical: selectedVertical,
            ...values,
            source_provider: 'MANUAL'
        }

        mutateAsync({req}).then(() => {
            handleClose()
        });
    }

    const getVerticalName = () => {
        const index = verticals.findIndex(v => v.value === selectedVertical)
        if (index >= 0) {
            return verticals[index].name
        } else {
            return ''
        }
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={'xl'}
            >
                <DialogContent sx={{padding: '2rem', minHeight: '50vh'}}>
                    <Stack direction='row' spacing={1} justifyContent='flex-end'>
                        <IconButton
                            aria-label='close'
                            onClick={handleClose}
                            sx={{
                                backgroundColor: '#333333',
                                padding: '4px',
                                '&:hover': {
                                    backgroundColor: '#333333',
                                },
                            }}
                        >
                            <CloseIcon sx={{color: '#ffffff', fontSize: '1.2rem'}}/>
                        </IconButton>
                    </Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={11} container spacing={2}>
                            <Grid item xs={12}
                                  sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Typography sx={{
                                    fontWeight: '700',
                                    fontSize: '1.2rem'
                                }}>{t('inbox.addNewPaper')} for {getVerticalName()}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{paddingTop: '4px !important'}}>
                                <Divider sx={{borderBottom: '2px solid black;', width: '100%'}}/>
                            </Grid>
                        </Grid>

                        <Grid item xs={11} container spacing={1}>
                            {status === 'error' && <Grid item xs={12} container spacing={1}>
                                <AlertCard severity={'error'} message={'Failed to add paper'} marginRight={0} height={'auto'}/>
                            </Grid>}
                            <PaperForm formRef={formRef} onSubmit={handleSubmit} verticals={verticals}/>
                        </Grid>
                        <Grid item xs={11} container justifyContent={'flex-end'}>
                            <Grid item>
                                <Button variant="contained" disabled={status === 'loading'}
                                        onClick={handleSave}>Save</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default CreatePaperDialog;