import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Rating from '@mui/material/Rating';
import styled from '@mui/system/styled';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

import PlayDifficultyLevel from 'components/elements/PlayDifficultyLevel';

const TableHeader = styled(TableCell)(({ theme }) => ({
    fontWeight: '700',
    paddingTop: '0',
    paddingLeft: '0',
    paddingRight: '0',
    borderBottom: '2px solid black;',
}));

const TableContentRow = styled(TableCell)(({ theme }) => ({
    paddingLeft: '0',
    paddingRight: '0',
}));

const PlaysTable = ({ plays = [] }) => {
    const { t } = useTranslation();

    return (
        <TableContainer component={Paper} elevation={0}>
            <Table sx={{ width: '100%' }}>
                <TableHead>
                    <TableRow>
                        <TableHeader>{t('explore.playbooks.popularity')}</TableHeader>
                        <TableHeader>{t('explore.playbooks.topPlays')}</TableHeader>
                        <TableHeader>{t('explore.playbooks.likelyROI')}</TableHeader>
                        <TableHeader>{t('explore.playbooks.successMetrics')}</TableHeader>
                        <TableHeader>{t('explore.playbooks.execution')}</TableHeader>
                        <TableHeader align="right">{t('explore.playbooks.putIntoAction')}</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {plays.map((row) => (
                        <TableRow
                            key={row.popularity}
                            sx={{ '&:last-child td, &:last-child th': { border: 0, paddingBottom: '0' } }}
                        >
                            <TableContentRow component="th" scope="row">
                                <Rating value={row.name} precision={0.5} readOnly />
                            </TableContentRow>
                            <TableContentRow>{row.calories}</TableContentRow>
                            <TableContentRow>{row.fat}</TableContentRow>
                            <TableContentRow>{row.carbs}</TableContentRow>
                            <TableContentRow><PlayDifficultyLevel difficulty={row.difficulty} /></TableContentRow>
                            <TableContentRow align="right">{row.protein}</TableContentRow>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PlaysTable;
