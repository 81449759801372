import React, {lazy, useState, Suspense} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Checkbox} from '@mui/material';

import useHover from 'hooks/useHover';
import CardHeaderColumn from 'components/elements/CardHeader';
import CustomTooltip from 'components/elements/CustomTooltip';
import usePaperUpdateValidated from 'hooks/papers/usePaperUpdateValidated';
import CustomButton from 'components/elements/CustomButton';

import CreateEvidenceDialog from './createEvidence/create-evidence-dialog';
import ReviewEvidenceDialog from './reviewEvidence/review-evidence-dialog';
import ActionMenu from './ActionMenu';
import ViewEvidenceDrawer from "./viewEvidence/view-evidence-drawer";
import {getEvidences, statusIcon, statusTooltip} from "./utils";

const ExperimentCardDetails = lazy(() => import('./evidence-card-details'));

const CardRoot = styled(Box)(() => ({
    padding: '2px 0px 6px 0px',
}));

const Accordion = styled(MuiAccordion)(({theme, active = false}) => ({
    border: '0.1em solid #E9E9E9',
    boxShadow: 'none',
    ...(active && {
        background: 'aliceblue',
    }),
}));

const EvidenceCard = ({style, data, index, handleRefetch, handleSelect, selected = [], selectAll = false}) => {
        const {t} = useTranslation();
        const [hoverRef] = useHover();
        let [searchParams] = useSearchParams();
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const [expand, setExpand] = useState(false);

        const [paperAdding, setPaperAdding] = useState(false);
        const [reviewing, setReviewing] = useState(false);
        const [viewing, setViewing] = useState(false);

        const {mutateAsync, status} = usePaperUpdateValidated();

        const handleSubmit = (value) => {
            const req = {
                "relevant_paper_manually_validated": value
            }
            mutateAsync({req, paperId: data.paper_id}).then(() => {
                handleMoreClose()
                handleRefetch()
            });
        }

        const handleAdd = (paper) => {
            setPaperAdding(paper)
        }

        const handleAddPaperClose = () => {
            setPaperAdding(null)
        }

        const handleReview = (event) => {
            event.stopPropagation();
            setReviewing(true)
        }

        const handleReviewClose = () => {
            setReviewing(false)
            handleRefetch()
        }

        const handleView = (event) => {
            event.stopPropagation();
            setViewing(true)
        }

        const handleViewClose = () => {
            setViewing(false)
        }


        const handleMore = (event) => {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
        };

        const handleMoreClose = () => {
            setAnchorEl(null);
        };

        return (
            <CardRoot style={style} ref={hoverRef}>
                <Accordion expanded={expand} TransitionProps={{unmountOnExit: true}}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            '&.MuiAccordionSummary-root': {boxShadow: '1px 2px 13px -10px #888'},
                            '&.MuiAccordionSummary-root.Mui-expanded': {minHeight: 'unset'},
                            div: {
                                '&.MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                            },
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}

                    >
                        <Grid container justifyContent="space-between" flexWrap='nowrap' spacing={1}
                              onClick={handleView}
                        >
                            <Grid item container xs={0.4} alignItems='center' justifyContent='center'>
                                <CustomTooltip title={statusTooltip(data['paper_evidence_status'])}>
                                    {statusIcon(data['paper_evidence_status'])}
                                </CustomTooltip>
                                <Grid item>
                                    <Checkbox size="small" disabled={selectAll}
                                              checked={selected.includes(data['paper_id']) || selectAll}
                                              onChange={() => handleSelect(data['paper_id'])}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={0.2} sx={{height: '100%'}}>
                                <Divider sx={{
                                    '&.MuiDivider-root': {
                                        borderWidth: '1px',
                                        borderColor: '#a7a7a7',
                                        width: 'fit-content'
                                    }
                                }}
                                         orientation='vertical'/>
                            </Grid>


                            <CardHeaderColumn
                                spacing={4.9}
                                headerName={t('inbox.paperName')}
                                headerTitle={<Grid
                                    item
                                    container
                                    alignItems="center"
                                >
                                    <Grid item>{data['paper_name']}</Grid>
                                </Grid>}
                            />
                            <CardHeaderColumn
                                spacing={1.6}
                                headerName={t('Citation Count')}
                                headerTitle={data['citation_count'] ? data['citation_count'] : 'N/A'}
                            />
                            <CardHeaderColumn
                                spacing={1.6}
                                headerName={t('Published Year')}
                                headerTitle={data['published_year'] ? data['published_year'] : 'N/A'}
                            />
                            <CardHeaderColumn
                                spacing={1.6}
                                headerName={t('Evidences')}
                                headerContent={<Grid container spacing={0.5}>
                                    {getEvidences(data['extracted_evidences'])}
                                </Grid>}
                            />
                            <Grid item container spacing={0.5} justifyContent={"flex-end"} alignItems="center"
                                  sx={{height: '100%'}} xs={1.6}>
                                <Grid item>
                                    {/*<IconButton onClick={handleReview}><RateReviewIcon*/}
                                    {/*fontSize={'small'}/></IconButton>*/}
                                    <CustomButton sx={{color: '#0794d3'}} startIcon={<AddBoxIcon fontSize={'small'}/>}
                                                  onClick={handleReview}>{t('Curate')}</CustomButton>
                                </Grid>
                                {/*<Grid item><IconButton onClick={() => handleAdd(data)}><PlayArrowIcon*/}
                                {/*    fontSize={'small'}/></IconButton></Grid>*/}
                                {/*<Grid item><IconButton onClick={() => handleAdd(data)}><AddIcon*/}
                                {/*    fontSize={'small'}/></IconButton></Grid>*/}
                                <Grid item>
                                    <IconButton onClick={handleMore}><MoreVertIcon/></IconButton>
                                </Grid>
                                {/*<Grid item>*/}
                                {/*    <IconButton*/}
                                {/*        onClick={(e) => {*/}
                                {/*            e.stopPropagation();*/}
                                {/*            onToggleExpandRow();*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        <ExpandMoreIcon sx={{transform: `rotate(${expand ? 180 : 0}deg)`}}/>*/}
                                {/*    </IconButton>*/}
                                {/*</Grid>*/}
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Suspense fallback={<Grid>Loading...</Grid>}>
                            <ExperimentCardDetails data={data}/>
                        </Suspense>
                    </AccordionDetails>
                </Accordion>
                {paperAdding &&
                    <CreateEvidenceDialog open={paperAdding} data={data} handleClose={handleAddPaperClose} height={300}/>}
                {reviewing &&
                    <ReviewEvidenceDialog open={reviewing} data={data} handleClose={handleReviewClose} height={300}/>}
                {
                    open &&
                    <ActionMenu open={open} anchorEl={anchorEl} handleClose={handleMoreClose} handleSubmit={handleSubmit}/>
                }
                {viewing && <ViewEvidenceDrawer open={viewing} data={data} handleClose={handleViewClose}/>}
            </CardRoot>
        );
    }
;

export default EvidenceCard;