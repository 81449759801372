import PieChart from "./pie-chart";
import GraphCard from "../../../../components/elements/GraphCard";
import React, {useEffect, useState} from "react";
import useCreationOverTime from "../../../../hooks/dashboard/useCreationOverTime";
import useModelBreakdown from "../../../../hooks/dashboard/useModelBreakdown";

const VerticalWiseModelBreakdown = () => {
    const [verticals, setVerticals] = useState([])
    const {data, status} = useModelBreakdown({});

    useEffect(()=>{
        if(data && data.data){
            let temp =[]
            console.log(data)
            for (const item of data.data) {

                        temp.push({
                            id: item.vertical,
                            name: item.vertical,
                            y: item.model_count,
                        },)

            }
            setVerticals(temp)
        }

    },[data])

    return (
        <GraphCard title={'Vertical wise Model count breakdown'}>
            <PieChart maxHeight={'600px'} series={verticals}/>
        </GraphCard>
    )
}

export default VerticalWiseModelBreakdown;