import React, {useEffect, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import TuneIcon from '@mui/icons-material/Tune';
import MuiPaper from '@mui/material/Paper';
import MuiInputBase from '@mui/material/InputBase';
import MuiSearchIcon from '@mui/icons-material/Search';
import styled from '@mui/system/styled';
import MuiButton from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import {useTranslation} from 'react-i18next';
import MuiDivider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Grid from '@mui/material/Grid';

import useDebounce from 'hooks/useDebounce';

const SearchIcon = styled(MuiSearchIcon)(({theme}) => ({
    marginLeft: '0.4em',
    marginRight: '0.4em',
    color: theme.palette.cml.main,
    opacity: '0.4',
}));

const Divider = styled(MuiDivider)(({theme}) => ({
    height: '1.8em',
    margin: '0 0.5em 0 .5em',
    borderColor: theme.palette.cml.main,
    opacity: '0.3',
}));

const Paper = styled(MuiPaper)(({theme}) => ({
    '&.MuiPaper-root': {
        display: 'flex',
        alignItems: 'center',
    },
}));

const InputBase = styled(MuiInputBase)(({theme}) => ({
    textTransform: 'none',
    width: '22em',

    '& .MuiInputBase-input': {
        padding:'0 !important'
    },
}));

const Button = styled(MuiButton)(({theme}) => ({
    '&.MuiButtonBase-root': {
        color: theme.palette.cml.main,
        textTransform: 'none',
    },
}));

const DebouncedSearchField = ({
                                  onSearch = () => {
                                  },
                                  placeholder = '',
                                  id = 'search-input',
                                  value = '',
                              }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(() => {
        onSearch(searchTerm);
    }, [debouncedSearchTerm]);

    useEffect(() => {
        setSearchTerm(value);
    }, [value]);

    const handleClear = () => {
        setSearchTerm('');
    };

    return (
        <InputBase
            id={id}
            variant='outlined'
            fullWidth={true}
            placeholder={placeholder}
            size='small'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            type='text'
            sx={{width: '100%'}}
            startAdornment={
                <InputAdornment position='start'>
                    <SearchIcon/>
                </InputAdornment>
            }
            endAdornment={
                searchTerm && <InputAdornment position='end'>
                    <IconButton
                        aria-label='clear search text'
                        edge='end'
                        disableRipple
                        onClick={handleClear}
                    >
                        <CancelIcon/>
                    </IconButton>
                </InputAdornment>
            }
        />
    );
};

const FilterButton = ({placeholder = '', handleSearch, handleFilterOpen, filterCount = 0}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <Paper elevation={0} sx={{
            border: "#bec0c2 1px solid",
            borderColor: "#bec0c2",
            borderRadius: 1,
            backgroundColor: '#fff',
            ':hover': {borderColor: 'rgba(0, 0, 0, 0.87)'},
            ':focus': {borderColor: '#0069da'}
        }}>
            <Grid container justifyContent={'space-between'} alignItems={'center'} flexWrap={'nowrap'}>
                <Grid item alignItems={'center'} container flexWrap={'nowrap'} sx={{paddingRight: '0.5em'}}>
                    <DebouncedSearchField placeholder={placeholder} onSearch={handleSearch}/>
                </Grid>
                <Grid item alignItems={'center'} container flexWrap={'nowrap'} justifyContent={'flex-end'}
                      sx={{width: '80px'}}>
                    <Grid item>
                        <Divider orientation='vertical'/>
                    </Grid>
                    <Grid item>
                        <Button
                            startIcon={filterCount > 0 ? <Badge badgeContent={filterCount} color='primary'><TuneIcon/></Badge> :
                                <TuneIcon/>}
                            disableRipple onClick={handleFilterOpen}>
                            {t('filter')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default FilterButton;
