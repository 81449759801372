import React from 'react';
import Grid from '@mui/material/Grid';

const InvalidItemCard = ({ message, backgroundColor, isRemoveShadow }) => {
  return (
    <Grid container justify="center" alignItems="center">

    </Grid>
  );
};

export default InvalidItemCard;
