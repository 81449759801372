import {useInfiniteQuery} from '@tanstack/react-query';

import api from 'services/api';

const fetchSearchPhrases = async ({pageParam = 1, filters, vertical}) => {
    const query = new URLSearchParams({
        page: pageParam
    });

    if (vertical) {
        query.append('vertical', vertical);
    }

    try {
        const response = await api.get(`api/v1/papers/search-phrases?${query.toString()}`);
        const {
            data: {payload, meta},
        } = await response.json();

        return {data: payload, meta};
    } catch (e) {
        return Promise.reject(e);
    }
};

const useSearchPhrases = ({filters, vertical, enabled = true,}) => {
    return useInfiniteQuery({
        queryKey: ['search-phrases', filters, vertical],
        queryFn: ({pageParam = 1}) => fetchSearchPhrases({pageParam, filters, vertical}),
        enabled,
        getNextPageParam: ({meta: {next_page}}, allPages) => {
            if (next_page === 0) return undefined;
            return next_page;
        },
    });
};

export default useSearchPhrases;
