import {useQuery} from '@tanstack/react-query';

import api from 'services/api';

const fetchFeedbacks = async ({evidenceId}) => {

    try {
        const response = await api.get(`api/v1/evidences/feedbacks/${evidenceId}`);
        const {
            data: {payload, meta},
        } = await response.json();

        return {data: payload, meta};
    } catch (e) {
        return Promise.reject(e);
    }
};

const useEvidenceFeedbacks = ({evidenceId, enabled = true,}) => {
    return useQuery({
        queryKey: ['evidence-feedbacks', evidenceId],
        queryFn: () => fetchFeedbacks({evidenceId}),
        enabled,
    });
};

export default useEvidenceFeedbacks;
