import React, {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import {format,parseISO} from 'date-fns';
import Paper from '@mui/material/Paper';
import {useSelector} from 'react-redux';
import {createSearchParams, useNavigate} from 'react-router-dom';

import InfiniteLoadTable from 'components/elements/InfiniteLoadTable';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import useSearchPhrases from 'hooks/papers/useSearchPhrases';
import {ROUTE_INBOX_EXPLORE} from 'pages/constants';
import AlertCard from 'components/elements/AlertCard';
import LabeledChip from 'components/elements/LabeledChip';
import CustomButton from 'components/elements/CustomButton';

const SearchPhrasesList = ({
                               height, refresh=0,filters = {
        search: '',
    }, sort = {
        name: '',
        order: '',
    }
                           }) => {
    const colors = ['#efefcb', '#c8edfd','#daf7ca']
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [flattenedResults, setFlattenedResults] = React.useState([]);

    const selectedVertical = useSelector((state) => state.app.vertical);

    const {
        data,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
        isError,
        status,
        refetch,
    } = useSearchPhrases({
        filters,
        sort,
        vertical: selectedVertical,
        enabled: selectedVertical !== ''
    });

    useEffect(() => {
        refetch()
    }, [refresh])

    useEffect(() => {
        if (data) {
            setFlattenedResults(data.pages.reduce((accum, curr) => {
                return [...accum, ...(curr.data ?? [])];
            }, []))
        }
    }, [data])

    const handleViewPapers = (value) => {
        navigate({
            pathname: ROUTE_INBOX_EXPLORE,
            search: `?${createSearchParams({'search_phrase': value})}`
        });
    }

    const getSource = (source) => {
        switch (source) {
            case 'SEMANTIC_SCHOLAR':
                return 'Semantic scholar';
            case 'GOOGLE_SCHOLAR':
                return 'Google scholar';
            case 'MANUAL':
                return 'Manual';
            default:
                return '';
        }
    }

    const getColor = (source) => {
        switch (source) {
            case 'SEMANTIC_SCHOLAR':
                return colors[0];
            case 'GOOGLE_SCHOLAR':
                return colors[1];
            case 'MANUAL':
                return colors[2];
            default:
                return '';
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: t('Search Phrase'),
                accessor: '',
                align: 'left',
                width: '35%',
                sortable: false,
                Cell: ({
                           cell: {
                               row: {values, original},
                           },
                       }) => {
                    return <>{original.search_phrase.search_phrase}</>;
                },
            },
            {
                Header: t('Source'),
                accessor: 'search_session_paper_provider',
                align: 'left',
                width: '10%',
                sortable: false,
                Cell: ({
                           cell: {
                               row: {values},
                           },
                       }) => {
                    return <Grid container spacing={1} alignItems={'center'}>
                        <Grid item>
                            <LabeledChip label={t(getSource(values.search_session_paper_provider))}
                                         backgroundColor={getColor(values.search_session_paper_provider)}/>
                        </Grid>
                    </Grid>;
                },
            },
            {
                Header: t('Total papers'),
                accessor: 'paper_count',
                align: 'right',
                width: '10%',
                sortable: false,
            },
            {
                Header: t('Latest download session'),
                accessor: 'latest_download_session_created_at',
                align: 'right',
                width: '15%',
                sortable: false,
                Cell: ({
                           cell: {
                               row: {values, original},
                           },
                       }) => {
                    return <>{values.latest_download_session_created_at?format(parseISO(values.latest_download_session_created_at), 'Pp'):''}</>;
                },
            },
            {
                Header: t('Total download sessions'),
                accessor: 'total_download_sessions',
                align: 'right',
                width: '10%',
                sortable: false,
            },
            // {
            //     Header: t('Created by'),
            //     accessor: 'classified_count',
            //     align: 'left',
            //     width: '10%',
            //     sortable: false,
            //     Cell: ({
            //                cell: {
            //                    row: {values, original},
            //                },
            //            }) => {
            //         return <>{'-'}</>;
            //     },
            // },

            {
                Header: t(''),
                accessor: 'vertical',
                align: 'right',
                width: '10%',
                sortable: false,
                Cell: ({
                           cell: {
                               row: {values, original},
                           },
                       }) => {
                    return <Grid container spacing={1} alignItems={'center'} justifyContent={'flex-end'}>
                        <Grid item>
                            <CustomButton onClick={() => handleViewPapers(original.search_phrase.search_phrase_id)}
                                         sx={{
                                             fontSize: '0.875rem',
                                             cursor: 'pointer'
                                         }}>{t('inbox.searchPhrases.viewPapers')}</CustomButton>
                        </Grid>
                    </Grid>;
                },
            }
        ],
        [t]
    );

    if (status === 'loading') {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height - 120}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    if (status === 'success' && flattenedResults && flattenedResults.length === 0) {
        return <Grid item xs={12} container spacing={1}>
            <AlertCard severity={'info'} message={'No data available'} height={'auto'}/>
        </Grid>
    }

    return <Paper sx={{width: '100%', padding: 2, height: height}}>
        <Grid container>
            <Grid item xs={12}>
                {flattenedResults && <InfiniteLoadTable
                    columns={columns}
                    height={height - 32}
                    data={flattenedResults}
                    isFetchingNextPage={isFetchingNextPage}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    isError={isError}
                />}
            </Grid>
        </Grid>
    </Paper>
};

export default SearchPhrasesList;
