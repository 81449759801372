import {useQuery} from '@tanstack/react-query';

import api from 'services/api';

const downloadPaper = async ({paperId}) => {
    try {
        const result = await api.get(`api/v1/papers/${paperId}/download`, {
            timeout: 300000,
        });
        let filename = ''
        const header = result.headers.get('Content-Disposition');
        if (header) {
            const parts = header.split(';');
            filename = parts[1].split('=')[1];
        }
        const data = await result.blob();
        return {data, filename};
    } catch (e) {
        return Promise.reject(e);
    }
};

const usePaperDownload = ({paperId, enabled = true,}) => {
    return useQuery({
        queryKey: ['paper-download', paperId],
        queryFn: () => downloadPaper({paperId}),
        enabled,
        refetchOnWindowFocus:false,
        retryOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 1000 * 60 * 60 * 24
    });
};

export default usePaperDownload;