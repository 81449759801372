import React from 'react';
import Paper from '@mui/material/Paper';
import {useTheme} from '@mui/material/styles';
import {Popover} from '@mui/material';

const FilterDropdown = ({
                            onClose,
                            open,
                            anchorEl,
                            children,
                        }) => {
    const theme = useTheme();

    return (
        <Popover id={'filter-dropdown'} open={open} anchorEl={anchorEl}
                 anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'center',
                 }}
                 transformOrigin={{
                     vertical: 'top',
                     horizontal: 'center',
                 }}
                 onClose={() => onClose()}>
            <Paper
                elevation={3}
                style={{
                    padding: theme.spacing(2),
                    maxWidth: '400px',
                    minWidth: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: theme.spacing(1),
                    maxHeight: `600px`,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                {children}
            </Paper>
        </Popover>
    );
};

export default FilterDropdown;
