import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';

import LoaderSpinner from 'components/elements/LoaderSpinner';
import InfiniteLoaderList from 'components/elements/InfiniteLoadList';
import useClassifierVerticals from 'hooks/classifiers/useClassifierVerticals';

import ModelCard from './model-card';

const ModelList = ({
                       height, filters = {
        search: '',
    }, sort = {
        name: '',
        order: '',
    }
                   }) => {
    const {t} = useTranslation();
    const [flattenedResults, setFlattenedResults] = useState([]);

    const {data,status,isError,isFetchingNextPage,hasNextPage,fetchNextPage} = useClassifierVerticals({filters})

    useEffect(() => {
        if (data) {
            setFlattenedResults(data.pages.reduce((accum, curr) => {
                return [...accum, ...(curr.data ?? [])];
            }, []))
        }
    }, [data])

    if (status === 'loading') {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height - 120}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    return <>
        {flattenedResults && <InfiniteLoaderList
            height={height}
            data={flattenedResults}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isError={isError}
            itemTemplate={ModelCard}
        />}
    </>
};

export default ModelList;
