import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import {
    TextField
} from '@mui/material';
import {Formik} from 'formik';
import {format} from 'date-fns';

const PaperImportPDFForm = ({
                                formRef,
                                onSubmit,
                                request,
                                edit = false,
                                paper,
                                selectedVertical
                            }) => {

    const {t} = useTranslation();
    const [selectedSources, setSelectedSources] = useState([]);

    const validationSchema = Yup.object().shape({
        // paper_name: Yup.string().required(t('Name is required')),
    });

    const sourceProviders = [
        {
            name: 'Semantic scholar',
            value: 'SEMANTIC_SCHOLAR'
        },
        {
            name: 'Google scholar',
            value: 'GOOGLE_SCHOLAR'
        },
    ]

    const handleSourcesChange = (sourceId, setFieldValue) => {
        let selected = [...selectedSources]
        if (selectedSources.includes(sourceId)) {
            selected = selected.filter(id => id !== sourceId)
        } else {
            if (selectedSources.length < 3) {
                selected.push(sourceId)
            }
        }
        setSelectedSources(selected)
        setFieldValue('source_providers', selected);
    }

    return (
        <Grid conainer item xs={12}>
            <Formik
                initialValues={{
                    vertical: edit ? paper['vertical'] : selectedVertical,
                    source_urls: edit ? paper['source_urls'] : '',

                }}
                innerRef={formRef}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue,
                  }) => (
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <TextField id="source_urls"
                                       label={t("Paper URLs")}
                                       required
                                       name="source_urls"
                                       value={values.source_urls}
                                       onChange={handleChange}
                                       error={Boolean(touched.source_urls && errors.source_urls)}
                                       helperText={(touched.source_urls && errors.source_urls) ? errors.source_urls : "PDF URLs of papers with each in a new line"}
                                       fullWidth
                                       multiline
                                       maxRows={6}
                                       minRows={4}
                                       variant="outlined" size="small"/>
                        </Grid>

                    </Grid>
                )}
            </Formik>
        </Grid>
    );
};

export default PaperImportPDFForm;