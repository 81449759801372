import {useMutation} from '@tanstack/react-query';
import api from 'services/api';

const useEvidenceExtract = () => {
    const extractEvidence = async (req,filters) => {
        try {
            const query = new URLSearchParams({});

            if (filters.search) {
                query.append('paper_name', filters.search);
            }

            if ([0, 1].includes(filters.is_extracted_evidences)) {
                query.append('is_extracted_evidences', filters.is_extracted_evidences);
            }

            if ([0, 1].includes(filters.is_good_paper_model_prediction)) {
                query.append('is_relevant_paper', filters.is_good_paper_model_prediction);
            }

            if (['NOT_EXTRACTED', 'SUCCESS', 'FAILED', 'PARTIAL'].includes(filters.paper_evidence_status)) {
                query.append('paper_evidence_status', filters.paper_evidence_status);
            }

            const evidences = await api.post(
                `api/v1/evidences/extractions?${query.toString()}`,
                JSON.stringify(req),
            );

            const data = await evidences.json();

            return data.data;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation({
        mutationFn: ({req,filters}) => extractEvidence(req,filters),
    });
};

export default useEvidenceExtract;