import {useQuery} from '@tanstack/react-query';

import api from 'services/api';

const fetchDashboardStats = async ({}) => {
    const query = new URLSearchParams({});
    try {
        const response = await api.get(`api/v1/dashboard/stats?${query.toString()}`);
        const {
            data: {payload, meta},
        } = await response.json();

        return {data: payload, meta};
    } catch (e) {
        return Promise.reject(e);
    }
};

const useDashboardStats = ({enabled = true}) => {
    return useQuery({
        queryKey: ['dashboard-stats'],
        queryFn: ({}) => fetchDashboardStats({}),
        enabled,
    });
};

export default useDashboardStats;
