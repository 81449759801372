import React, {useEffect, useState} from 'react';

import GraphCard from 'components/elements/GraphCard';
import useDashboardPaperDist from 'hooks/dashboard/useDashboardPaperDist';

import PieChart from './pie-chart';

const PaperDistribution = () => {
    const [results, setResults] = useState([]);

    const {data, status} = useDashboardPaperDist({});

    useEffect(() => {
        if (data && data.data) {
            setResults(data.data)
        }
    }, [data])

    return <GraphCard title={'Papers By Vertical'}>
        <PieChart data={results}/>
    </GraphCard>
}

export default PaperDistribution;