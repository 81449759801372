import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {Provider} from 'react-redux';

import ThemeDefault from 'themes/default';
import Routes from 'pages/routes';
import {ViewportProvider} from 'hooks/useViewport';
import {AuthContextProvider} from 'hooks/useAuth';
import keycloak from 'keycloak';
import {store} from 'state';

const queryClient = new QueryClient();

const App = () => (
    <Provider store={store}>
        <AuthContextProvider authClient={keycloak}>
                <React.StrictMode>
                    <ThemeProvider theme={ThemeDefault}>
                        <QueryClientProvider client={queryClient}>
                            <CssBaseline/>
                            <ViewportProvider>
                                <Routes/>
                            </ViewportProvider>
                        </QueryClientProvider>
                    </ThemeProvider>
                </React.StrictMode>
        </AuthContextProvider>
    </Provider>
);

export default App;
