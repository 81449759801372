import {useMutation} from '@tanstack/react-query';
import api from 'services/api';

const useEvidenceUpdate = () => {
    const updateEvidence = async (req, evidenceId) => {
        try {
            const evidences = await api.put(
                `api/v1/evidences/${evidenceId}`,
                JSON.stringify(req),
            );

            const data = await evidences.json();

            return data.data;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation({
        mutationFn: ({req, evidenceId}) => updateEvidence(req, evidenceId),
    });
};

export default useEvidenceUpdate;