import {useMutation} from '@tanstack/react-query';
import api from 'services/api';

const useTrainClassifierTrigger = () => {
    const triggerTrainClassifier = async (req) => {
        try {
            const request = await api.post(
                `api/v1/classifiers/trains`,
                JSON.stringify(req),
            );

            const data = await request.json();

            return data.data;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation({
        mutationFn: ({req}) => triggerTrainClassifier(req),
    });
};

export default useTrainClassifierTrigger;