import {useMutation} from '@tanstack/react-query';

import api from 'services/api';

const searchPaper = async ({searchPhrase, hasPdf,vertical}) => {
    const query = new URLSearchParams({
        download_papers_with_pdfs_only: hasPdf,
        source_provider: 'SEMANTIC_SCHOLAR',
        vertical
    });

    try {
        const response = await api.get(`api/v1/papers/search/${searchPhrase}?${query.toString()}`);
        const {
            data: {payload, meta},
        } = await response.json();

        return {data: payload, meta};
    } catch (e) {
        return Promise.reject(e);
    }
};

const usePapersSearch = () => {

    return useMutation({
        mutationFn: ({searchPhrase, hasPdf,vertical}) => searchPaper({searchPhrase, hasPdf,vertical}),
    });
};

export default usePapersSearch;