import React from 'react';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Logout from '@mui/icons-material/Logout';
import styled from '@mui/system/styled';
import MuiListItemIcon from '@mui/material/ListItemIcon';

import AvatarUser from 'components/elements/Avatar';

const ListItemIcon = styled(MuiListItemIcon)({
    minWidth: '36px',
});

const AccountMenu = ({anchorEl, open, handleClose, handleLogout, profile}) => {

    return (
        <React.Fragment>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        minWidth: 320,
                        maxWidth: 800,
                        right: '1.5em',
                        top: '2em',
                        mt: 1,
                        '& .MuiAvatar-root': {
                            ml: -0.5,
                            mr: 2,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 28,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <Box sx={{padding: '1em'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            Account
                        </Grid>
                        <Grid item>
                            <AvatarUser userName={profile ? (profile.name ?? profile.preferred_username) : ''}/>
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{color: '#ebedf0'}}/>
                <MenuItem onClick={handleLogout} sx={{mt: '0.5em'}}>
                    <ListItemIcon>
                        <Logout fontSize="small"/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}

export default AccountMenu;