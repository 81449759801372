import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import {Checkbox, FormControlLabel} from '@mui/material';
import {Formik} from 'formik';
import Typography from '@mui/material/Typography';

import useExtractionEligibility from 'hooks/evidences/useExtractionEligibility';

import {getLabel} from '../utils';


const ExtractEvidenceForm = ({
                                 formRef,
                                 onSubmit,
                                 customGpts = [],
                                 selectedAll = false,
                                 selected = [],
                                 count = 0,
                                 vertical
                             }) => {

    const {t} = useTranslation();
    const [selectedExtractionType, setSelectedExtractionType] = useState(['CHAT_GPT_RA_V2']);
    const {data} = useExtractionEligibility({
        vertical,
        selectedAll,
        papers: selected,
        extractionTypes: selectedExtractionType
    });
    const [paperCount, setPaperCount] = useState([]);

    useEffect(() => {

        if (data) {
            setPaperCount(data.data ?? [])
        }

    }, [data])

    const validationSchema = Yup.object().shape({});

    const handleTypeChange = (sourceId, setFieldValue) => {
        let selected = [...selectedExtractionType]
        if (selectedExtractionType.includes(sourceId)) {
            selected = selected.filter(id => id !== sourceId)
        } else {
            if (selectedExtractionType.length < 3) {
                selected.push(sourceId)
            }
        }
        setSelectedExtractionType(selected)
        setFieldValue('extraction_types', selected);
        setFieldValue('gpt_prompt', customGpts[0]?.prompt);
    }

    const getExtractedCount = (type) => {
        let i = paperCount.findIndex(s => s.extraction_type === type);
        if (i >= 0) {
            return paperCount[i].extracted
        } else {
            return 0
        }
    }

    return (
        <Grid conainer item xs={12}>
            <Formik
                initialValues={{
                    gpt_prompt: '',
                    extraction_types: ['CHAT_GPT_RA_V2'],
                }}
                innerRef={formRef}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sx={{height: 'fit-content'}} container spacing={2}>
                            {/*<Grid item xs={12}>*/}
                            {/*    <FormControl variant="outlined" fullWidth>*/}
                            {/*        <FormGroup>*/}
                            {/*            <Grid container alignItems={'center'} spacing={1}>*/}
                            {/*                <Grid item>*/}
                            {/*                    <Typography>Extract evidences from already extracted*/}
                            {/*                        papers?</Typography>*/}
                            {/*                </Grid>*/}
                            {/*                <Grid item>*/}
                            {/*                    <FormControlLabel*/}
                            {/*                        control={*/}
                            {/*                            <Checkbox checked={values.limit_extraction_to_new_papers}*/}
                            {/*                                      onChange={(e) => setFieldValue('limit_extraction_to_new_papers', e.target.checked)}*/}
                            {/*                                      name="gilad"/>*/}
                            {/*                        }*/}
                            {/*                        label={''}*/}
                            {/*                        labelPlacement={'start'}*/}
                            {/*                    />*/}
                            {/*                </Grid>*/}
                            {/*            </Grid>*/}
                            {/*        </FormGroup>*/}
                            {/*    </FormControl>*/}
                            {/*</Grid>*/}

                            <Grid item xs={12} container alignItems={'flex-start'} spacing={1}>

                                {/*<Grid item xs={4} container alignItems={'center'} spacing={1}>*/}
                                {/*    <Grid item xs={12}>*/}
                                {/*        <Typography>{t("Extraction limit")}</Typography>*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item>*/}
                                {/*        <TextField id="extraction_limit"*/}
                                {/*                   required*/}
                                {/*                   name="extraction_limit"*/}
                                {/*                   value={values.extraction_limit}*/}
                                {/*                   onChange={handleChange}*/}
                                {/*                   error={Boolean(touched.extraction_limit && errors.extraction_limit)}*/}
                                {/*                   helperText={touched.extraction_limit && errors.extraction_limit}*/}
                                {/*                   type={"number"}*/}
                                {/*                   variant="outlined" size="small"/>*/}
                                {/*    </Grid>*/}
                                {/*</Grid>*/}

                                <Grid item xs={12} container justifyContent={'space-between'} alignItems={'flex-start'}
                                      spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography>{t("Extract using")}</Typography>
                                    </Grid>
                                    <Grid item sx={{paddingRight: '0.25em'}} xs={12} container>
                                        {customGpts.map(gpt => (
                                            <Grid item xs={12} container alignItems={'center'}>
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedExtractionType?.includes(gpt.value)}
                                                                value={gpt.value}
                                                                disabled={!gpt.active}
                                                                onChange={() => handleTypeChange(gpt.value, setFieldValue, values.extraction_types)}
                                                                name={gpt.value}/>
                                                        }
                                                        sx={{width: '100%'}}
                                                        label={getLabel(gpt.name)}
                                                    />
                                                </Grid>
                                                {selectedExtractionType?.includes(gpt.value) && <Grid item>
                                                    <Typography sx={{color: (count - getExtractedCount(gpt.value))>0?'#4caf50':'#ff9800', fontSize: '0.875rem'}}>Evidence
                                                        has been extracted for {getExtractedCount(gpt.value)} out
                                                        of {count} papers. Evidence extraction
                                                        will be performed on the
                                                        remaining {count - getExtractedCount(gpt.value)} papers.</Typography>
                                                </Grid>
                                                }
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                )}
            </Formik>
        </Grid>
    );
};

export default ExtractEvidenceForm;