import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

const AvatarSmall = ({userName = ''}) => {
    return (
        <Grid item sx={{display: 'flex'}}>
            <Avatar sx={{
                display: 'inline flow-root list-item',
                lineHeight: '1.9em',
                textAlign: 'center',
                height: '1.9em',
                width: '1.9em',
                fontSize: '0.75rem',
            }}>
                <Typography
                    fontSize={'0.875rem'}>
                    {userName ? userName.match(/\b(\w)/g).join('').slice(0, 2) : ''}</Typography>
            </Avatar>
        </Grid>
    );
};

const ExecutedUser = ({user}) => {
    const executedUser = (user) => {
        if (user && (user.first_name || user.last_name)) {
            return `${user.first_name} ${user.last_name}`
        } else {
            return ''
        }
    }

    return <Grid container alignItems={"center"} spacing={1}
                 wrap={"nowrap"}>
        {executedUser(user) !== '' && <AvatarSmall userName={executedUser(user)}/>}
        <Grid item><Typography
            fontSize={'0.875rem'}>{executedUser(user) ? executedUser(user) : '-'}</Typography>
        </Grid>
    </Grid>

}
export default ExecutedUser