import {useMutation} from '@tanstack/react-query';
import api from 'services/api';

const useEvidenceFeedbackCreate = () => {
    const createFeedback = async (req, evidenceId) => {
        try {
            const response = await api.post(
                `api/v1/evidences/feedbacks/${evidenceId}`,
                JSON.stringify(req),
            );

            const {
                data: {payload, meta},
            } = await response.json();

            return {data: payload, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation({
        mutationFn: ({req, evidenceId}) => createFeedback(req, evidenceId),
    });
};

export default useEvidenceFeedbackCreate;