import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import useTrainClassifier from 'hooks/classifiers/useTrainClassifier';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import InfiniteLoaderList from 'components/elements/InfiniteLoadList';
import AlertCard from 'components/elements/AlertCard';

import TrainSessionCard from './train-session-card';

const TrainSessionList = ({
                              height, refresh = 0, filters = {
        search: '',
    }, sort = {
        name: '',
        order: '',
    }
                          }) => {
    const {t} = useTranslation();
    const [flattenedResults, setFlattenedResults] = useState([]);

    const {
        data,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
        isError,
        status,
        refetch,
        isFetched
    } = useTrainClassifier({
        filters,
        sort,
    });

    useEffect(() => {
        if (data) {
            setFlattenedResults(data.pages.reduce((accum, curr) => {
                return [...accum, ...(curr.data ?? [])];
            }, []))
        }
    }, [data])

    useEffect(() => {
        refetch()
    }, [refresh])

    if (status === 'loading') {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height - 120}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    if (status === 'success' && flattenedResults && flattenedResults.length === 0) {
        return <Grid item xs={12} container spacing={1}>
            <AlertCard severity={'info'} message={'No data available'} height={'auto'}/>
        </Grid>
    }

    return <Box sx={{
        width: '100%',
        overflowX: 'auto'
    }}>
        <Grid container sx={{minWidth: '720px'}}>
            <Grid item xs={12}>
                {flattenedResults && <InfiniteLoaderList
                    height={height}
                    data={flattenedResults}
                    isFetchingNextPage={isFetchingNextPage}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    isError={isError}
                    itemTemplate={({data, index}) => <TrainSessionCard data={data} index={index}
                                                                       handleRefetch={refetch}/>}
                />}
            </Grid>
        </Grid>
    </Box>
};

export default TrainSessionList;
