import {useInfiniteQuery} from '@tanstack/react-query';

import api from 'services/api';

const fetchPapers = async ({pageParam = 1, filters, sort, vertical}) => {
    const query = new URLSearchParams({
        page: pageParam
    });

    if (filters.search) {
        query.append('paper_name', filters.search);
    }

    if (filters.download_session) {
        query.append('download_session', filters.download_session);
    }

    if (filters.search_phrase) {
        query.append('search_phrase', filters.search_phrase);
    }

    if ([0, 1].includes(filters.is_relevant_paper)) {
        query.append('is_relevant_paper', filters.is_relevant_paper);
    }

    if ([0, 1].includes(filters.is_downloaded)) {
        query.append('is_downloaded', filters.is_downloaded);
    }

    if ([1].includes(filters.with_abstract)) {
        query.append('with_abstract', filters.with_abstract);
    }

    if (['RELEVANT', 'NOT_RELEVANT', 'NOT_EVALUATED'].includes(filters.manually_validated)) {
        query.append('manually_validated', filters.manually_validated);
    }

    if (['RELEVANT', 'NOT_RELEVANT', 'NOT_EVALUATED'].includes(filters.gpt)) {
        query.append('gpt', filters.gpt);
    }

    if (['RELEVANT', 'NOT_RELEVANT', 'NOT_EVALUATED'].includes(filters.cml)) {
        query.append('cml', filters.cml);
    }

    if (['SEMANTIC_SCHOLAR', 'GOOGLE_SCHOLAR'].includes(filters.source_provider)) {
        query.append('source_provider', filters.source_provider);
    }

    if (vertical) {
        query.append('vertical', vertical);
    }

    if (sort.citation_count && sort.current === 'citation_count') {
        query.append('sort_by', 'citation_count');
        query.append('order', sort.citation_count);
    }

    if (sort.published_year && sort.current === 'published_year') {
        query.append('sort_by', 'published_year');
        query.append('order', sort.published_year);
    }

    if (sort.created_at && sort.current === 'created_at') {
        query.append('sort_by', 'created_at');
        query.append('order', sort.created_at);
    }

    try {
        const response = await api.get(`api/v1/papers?${query.toString()}`);
        const {
            data: {payload, meta},
        } = await response.json();

        return {data: payload, meta};
    } catch (e) {
        return Promise.reject(e);
    }
};

const usePapers = ({filters, sort, vertical, enabled = true,}) => {
    return useInfiniteQuery({
        queryKey: ['papers', filters, vertical, sort],
        queryFn: ({pageParam = 1}) => fetchPapers({pageParam, filters, sort, vertical}),
        enabled,
        getNextPageParam: ({meta: {next_page}}, allPages) => {
            if (next_page === 0) return undefined;
            return next_page;
        },
    });
};

export default usePapers;
