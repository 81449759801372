const config = {
    baseUrl: `${process.env.REACT_APP_CML_INSIGHTS_API_GATEWAY_URL}${process.env.REACT_APP_CML_INSIGHTS_CONTEXT_PATH}`,
    mode: 'cors',
    cache: 'default',
    credentials: 'same-origin',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    params: {},
    timeout: `${process.env.REACT_APP_CML_INSIGHTS_REQUEST_TIMEOUT}`,
};

export default config;
