import React from 'react';
import Drawer from '@mui/material/Drawer';

const CustomDrawer = ({open, onclose, children}) => {

    return (
        <React.Fragment key={'bookmark-drawer'}>
            <Drawer
                anchor={'right'}
                open={open}
                onClose={onclose}
            >
                {children}
            </Drawer>
        </React.Fragment>
    );
}

export default CustomDrawer;
