import React, {useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {Button} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import usePaperUpdate from 'hooks/papers/usePaperUpdate';
import usePaperUpload from 'hooks/papers/usePaperUpload';

import PaperForm from '../createPaper/paper-form';

const EditPaperDialog = ({open, handleClose}) => {
    const {t} = useTranslation();
    const formRef = useRef();
    const inputRef = useRef();
    const [uploaded,setUploaded] = useState(false);

    const selectedVertical = useSelector((state) => state.app.vertical);

    const {mutateAsync, status} = usePaperUpdate();
    const {mutateAsync:uploadPaper,status:uploadStatus} = usePaperUpload();

    const handleSave = () => {
        formRef.current.handleSubmit();
    }

    const handleSubmit = (values) => {
        const req = {
            ...values,
            vertical: selectedVertical,
            paper_id: open.paper_id,
            marked_for_review: false
        }

        mutateAsync({req, paperId: open.paper_id}).then(() => {
            handleClose()
        });
    }

    const handleClickUpload = () => {
        inputRef.current.click();
    }

    const handleUpload = (event) => {
        const fileUploaded = event.target.files[0];
        const formData = new FormData();

        formData.append('file', fileUploaded);
        uploadPaper({ paperId:open.paper_id, req: formData }).then(() => {
            setUploaded(true)
        });
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={'xl'}
            >
                <DialogContent sx={{padding: '2rem', minHeight: '50vh'}}>
                    <Stack direction='row' spacing={1} justifyContent='flex-end'>
                        <IconButton
                            aria-label='close'
                            onClick={handleClose}
                            sx={{
                                backgroundColor: '#333333',
                                padding: '4px',
                                '&:hover': {
                                    backgroundColor: '#333333',
                                },
                            }}
                        >
                            <CloseIcon sx={{color: '#ffffff', fontSize: '1.2rem'}}/>
                        </IconButton>
                    </Stack>
                    <Grid container spacing={4}>
                        <Grid item xs={11} container spacing={2}>
                            <Grid item xs={12}
                                  sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Typography sx={{
                                    fontWeight: '700',
                                    fontSize: '1.2rem'
                                }}>{t('inbox.editPaper')}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{paddingTop: '4px !important'}}>
                                <Divider sx={{borderBottom: '2px solid black;', width: '100%'}}/>
                            </Grid>
                        </Grid>

                        <Grid item xs={11} container spacing={3}>
                            <PaperForm edit={true} paper={open} formRef={formRef}
                                       onSubmit={handleSubmit}/>
                        </Grid>

                        <Grid item xs={11} container spacing={3}>
                            <Grid item>
                                <Button
                                    sx={{width: '12em'}}
                                    onClick={handleClickUpload}
                                    variant='contained'
                                    disabled={uploadStatus==='pending'}
                                    startIcon={<FileUploadOutlinedIcon/>}
                                >
                                    {t('Upload Pdf')}
                                </Button>
                                <input ref={inputRef} accept='.pdf' onChange={handleUpload} type={'file'} hidden/>
                            </Grid>
                        </Grid>

                        <Grid item xs={11} container justifyContent={'flex-end'}>
                            <Grid item>
                                <Button variant="contained" disabled={status === 'loading'}
                                        onClick={handleSave}>Save</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default EditPaperDialog;