import React, {useEffect, useRef, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useResizeDetector} from 'react-resize-detector';
import Box from '@mui/material/Box';

import useDebounce from 'hooks/useDebounce';

const PieChart = ({title, data = [], maxHeight = '350px'}) => {
    const {width, ref} = useResizeDetector();
    const chartWidth = useDebounce(width, 200);

    const charOptions = () => {
        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: title,
                align: 'left'
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name}: <b>{point.y}</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: ''
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    // dataLabels: {
                    //     enabled: true,
                    //     format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    // }
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false
                },
                series: {
                    animation: false
                }
            },
            legend: {
                floating: true,
                layout: 'vertical',
                // x: -150,
                y: 10
            },
            credits: {
                enabled: false
            },
        }
    };

    const [options, setOptions] = useState(charOptions());
    const chartRef = useRef(null);

    useEffect(() => {
            const series = []
            for (const value of data) {
                series.push({
                    name: value.vertical,
                    y: value.total_count
                })
            }
            setOptions({
                ...charOptions(),
                series: [{
                    name: 'Verticals',
                    colorByPoint: true,
                    data: series
                }],
            });
        },
        [data]
    )

    useEffect(() => {
        if (chartRef) {
            chartRef.current.chart.reflow();
        }

    }, [chartWidth])

    return (<Box sx={{height: '100%', maxHeight, minHeight: maxHeight}} ref={ref}>
            <HighchartsReact containerProps={{style: {height: "100%"}}} ref={chartRef} highcharts={Highcharts}
                             options={options}/>
        </Box>
    );
};

export default PieChart;
