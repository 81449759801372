import React, {useEffect, useRef, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useResizeDetector} from 'react-resize-detector';
import Box from '@mui/material/Box';

import useDebounce from 'hooks/useDebounce';

const PrcChart = ({data,  maxHeight = '400px'}) => {
    const {width, ref} = useResizeDetector();
    const chartWidth = useDebounce(width, 200);

    const charOptions = () => {
        return {
            chart: {
                type: 'line',
                createDataCopy: false,
            },
            title: {
                text: null
            },
            xAxis: {
                title: {
                    text: 'Recall'
                },
                min: 0,
                max: 1
            },
            yAxis: {
                title: {
                    text: 'Precision'
                },
                min: 0,
                max: 1
            },
            tooltip: {
                style: {
                    fontSize: "0.875rem",
                },
                formatter: function () {
                    return `Recall: ${this.point.x.toFixed(2)}, Precision: ${this.point.y.toFixed(2)}`;
                }
            },
            credits: {
                enabled: false
            }
        };
    }

    const [options, setOptions] = useState(charOptions());
    const chartRef = useRef(null);

    useEffect(() => {
            if (data) {
                const series = []
                const prcDataTest = [];
                data['recall_test'].forEach((value, index) => {
                    prcDataTest.push([value, data['precision_test'][index]])
                })
                series.push({
                    name: 'PRC Test',
                    data: prcDataTest,
                    marker: {
                        enabled: false
                    },
                    lineWidth:3,
                })

                let prcDataTrain = [];
                data['recall_train'].forEach((value, index) => {
                    prcDataTrain.push([value, data['precision_train'][index]])
                })
                series.push({
                    name: 'PRC Train',
                    data: prcDataTrain,
                    marker: {
                        enabled: false
                    },
                    lineWidth:3,
                })
                if(data['random_plot_line_x'] && data['random_plot_line_y']){
                    let randomGuess = [];
                    data['random_plot_line_x'].forEach((value, index) => {
                        randomGuess.push([value, data['random_plot_line_y'][index]])
                    })
                    series.push( {
                        name: 'Random Guess',
                        data: randomGuess,
                        dashStyle: 'ShortDash',
                        enableMouseTracking: false,
                        marker: {
                            enabled: false
                        }
                    })
                }

                setOptions({
                    ...charOptions(),
                    series: series,
                });
            }

        }, [data]
    )

    useEffect(() => {
        if (chartRef) {
            chartRef.current.chart.reflow();
        }

    }, [chartWidth])

    return (<Box sx={{height: '100%', minHeight: maxHeight, maxHeight}} ref={ref}>
            <HighchartsReact containerProps={{style: {height: "100%"}}} ref={chartRef} highcharts={Highcharts}
                             options={options}/>
        </Box>
    );
};

export default PrcChart;
