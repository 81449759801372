import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    TextField
} from '@mui/material';
import {Formik} from 'formik';
import FormControl from '@mui/material/FormControl';
import {format} from 'date-fns';
import {useSelector} from 'react-redux';

import usePapersSearch from 'hooks/papers/usePapersSearch';
import useDebounce from 'hooks/useDebounce';


const SearchForm = ({
                        formRef,
                        onSubmit,
                        edit = false,
                        request
                    }) => {

    const {t} = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [sourceProvider, setSourceProvider] = useState('SEMANTIC_SCHOLAR');
    const selectedVertical = useSelector((state) => state.app.vertical);
    const [hasPdf, setHasPdf] = useState(true);
    const debouncedSearchTerm = useDebounce(searchTerm, 1000);
    const [totalResults, setTotalResults] = useState(0);
    const [downloaded, setDownloaded] = useState(0);

    const {mutateAsync, status} = usePapersSearch({})

    const validationSchema = Yup.object().shape({
        source_provider: Yup.string()
            .required(t('At least 1 research paper repository is required.')),
        search_phrase: Yup.string().required(t('Search phrase is required.')),
        requested_papers_count: Yup.number()
            .required(t('Paper count is required.'))
            .integer('Paper count must be an integer.')
            .min(1, "Paper count must be more than 0.")
            .max(256, "Max allowed paper count is 256."),
    });

    const sourceProviders = [
        {
            name: 'Google scholar',
            value: 'GOOGLE_SCHOLAR',
            active: false
        },
        {
            name: 'Semantic scholar',
            value: 'SEMANTIC_SCHOLAR',
            active: true
        }
    ]

    useEffect(() => {
        if (debouncedSearchTerm && sourceProvider === 'SEMANTIC_SCHOLAR') {
            mutateAsync({searchPhrase: searchTerm, hasPdf, vertical: selectedVertical}).then(res => {
                setTotalResults(res.data['total_results'])
                setDownloaded(res.data['downloaded'])
            })
        }
    }, [debouncedSearchTerm, hasPdf,sourceProvider]);

    const getHelperText = (touched, errors) => {
        if (touched.search_phrase && errors.search_phrase) {
            return errors.search_phrase
        } else if (sourceProvider === 'SEMANTIC_SCHOLAR' && debouncedSearchTerm && status === 'success') {
            return `${totalResults} results for “${debouncedSearchTerm}”, ${downloaded} have been downloaded.`
        } else if (sourceProvider === 'SEMANTIC_SCHOLAR' && debouncedSearchTerm && status === 'error') {
            return 'Oops, there seems to be an issue with the semantic scholar. '
        } else {
            return ''
        }

    }

    const getHelperTextColor = (touched, errors) => {
        if (touched.search_phrase && errors.search_phrase) {
            return '#d32f2f'
        } else if (debouncedSearchTerm && status === 'success') {
            if (totalResults > 0) {
                return '#4caf50'
            } else {
                return '#ff9800'
            }
        } else if (debouncedSearchTerm && status === 'error') {
            return '#d32f2f'
        } else {
            return '#9f9f9f'
        }
    }

    return (
        <Grid conainer item xs={12}>
            <Formik
                initialValues={{
                    search_phrase: request['search_phrase'] ? request['search_phrase'] : '',
                    source_provider: request['source_provider'] ? request['source_provider'] : 'GOOGLE_SCHOLAR',
                    requested_papers_count: request['requested_papers_count'] ? request['requested_papers_count'] : 50,
                    download_session_name: request['download_session_name'] ? request['download_session_name'] : `${request ? request['vertical'] : ''}-Search-${format(Date.now(), 'yyyy-MM-dd HH:mm:ss')}`,
                    download_papers_with_pdfs_only: request['download_papers_with_pdfs_only'] ? request['download_papers_with_pdfs_only'] : true
                }}
                innerRef={formRef}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <TextField id="search_phrase"
                                       label={t("inbox.create.searchPhrase")}
                                       required
                                       sx={{'.MuiFormHelperText-root': {marginLeft: 0}}}
                                       name="search_phrase"
                                       value={values.search_phrase}
                                       onChange={(e) => {
                                           setSearchTerm(e.target.value);
                                           setSourceProvider(values.source_provider);
                                           setFieldValue('search_phrase', e.target.value)
                                       }}
                                       FormHelperTextProps={{
                                           style: {fontSize: '0.875rem', color: getHelperTextColor(touched, errors)},
                                       }}
                                       error={Boolean(touched.search_phrase && errors.search_phrase)}
                                       helperText={getHelperText(touched, errors)}
                                       fullWidth
                                       variant="outlined" size="small"/>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl>
                                <FormLabel
                                    id="demo-row-radio-buttons-group-label">{t('inbox.search.researchPaperRepository')}</FormLabel>
                                <FormHelperText
                                    sx={{'&.MuiFormHelperText-root': {marginLeft: 0}}}
                                    error={Boolean(touched.source_provider && errors.source_provider)}>{touched.source_provider && errors.source_provider}</FormHelperText>
                                <RadioGroup
                                    row
                                    aria-labelledby="researchPaperRepository"
                                    name="source_provider"
                                    value={values.source_provider}
                                    onChange={(e) => {
                                        setSourceProvider(e.target.value);
                                        setFieldValue('source_provider', e.target.value)
                                    }}
                                >

                                    {sourceProviders.map(provider => (

                                        <FormControlLabel
                                            value={provider.value}
                                            control={<Radio/>}
                                            label={provider.name}/>
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                            <TextField id="requested_papers_count"
                                       label={t("Paper count")}
                                       required
                                       sx={{'.MuiFormHelperText-root': {marginLeft: 0}}}
                                       name="requested_papers_count"
                                       value={values.requested_papers_count}
                                       onChange={handleChange}
                                       error={Boolean(errors.requested_papers_count)}
                                       helperText={errors.requested_papers_count}
                                       fullWidth
                                       type={'number'}
                                       variant="outlined" size="small"/>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>
                                <FormGroup>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                sx={{marginLeft: 0}}
                                                control={
                                                    <Checkbox checked={values.download_papers_with_pdfs_only}
                                                              onChange={(e) => {
                                                                  setFieldValue('download_papers_with_pdfs_only', e.target.checked)
                                                                  setHasPdf(e.target.checked)
                                                              }}
                                                              name="gilad"/>
                                                }
                                                labelPlacement="start"
                                                label="Download papers with PDFs only?"
                                            />
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </FormControl>
                        </Grid>

                        {/*<Grid item xs={12}>*/}
                        {/*    <TextField id="download_session_name"*/}
                        {/*               label={t("Name")}*/}
                        {/*               name="download_session_name"*/}
                        {/*               value={values.download_session_name}*/}
                        {/*               onChange={handleChange}*/}
                        {/*               error={Boolean(touched.download_session_name && errors.download_session_name)}*/}
                        {/*               helperText={touched.download_session_name && errors.download_session_name}*/}
                        {/*               fullWidth*/}
                        {/*               variant="outlined" size="small"/>*/}
                        {/*</Grid>*/}

                    </Grid>
                )}
            </Formik>
        </Grid>
    );
};

export default SearchForm;