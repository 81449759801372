import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import VerticalSelector from 'components/elements/VerticalSelector';
import FilterButton from 'components/elements/FilterButton';
import FilterDropdown from 'components/elements/FilterDropdown';
import {notifyChangePapersFilters} from 'state/app';

import FilterForm from './filter-form';

const Filters = ({}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();

    const filters = useSelector((state) => state.app.papersFilters);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if(searchParams.get('download_session')){
            handleFilters({
                ...filters,
                download_session: searchParams.get('download_session'),
            })
        }

        if(searchParams.get('search_phrase')){
            handleFilters({
                ...filters,
                search_phrase: searchParams.get('search_phrase'),
            })
        }
    }, [searchParams])

    const handleFilters = (value) => {
        dispatch(notifyChangePapersFilters(value));
    }

    const handleFilterOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleFilterClose = (value) => {
        setAnchorEl(null);
    };

    const handleVerticalChange = (value) => {
        handleFilters({
            ...filters,
            vertical: value,
        })
    }

    const handleSubmit = (values) => {
        handleFilters({
            ...filters,
            is_downloaded: values.is_downloaded ? 1 : 0,
            with_abstract: values.with_abstract ? 1 : 0,
            is_relevant_paper: values.is_relevant_paper ? 1 : 0,
            manually_validated: values.manually_validated,
            gpt: values.gpt,
            cml: values.cml,
            source_provider: values.source_provider
        })
        handleFilterClose()
    }

    const getFilterCount = () => {
        let count = 0;
        if (filters.is_downloaded) {
            count++;
        }
        if (filters.with_abstract) {
            count++;
        }
        if (filters.is_relevant_paper) {
            count++;
        }
        if (filters.manually_validated !== '') {
            count++;
        }
        if (filters.gpt !== '') {
            count++;
        }
        if (filters.cml !== '') {
            count++;
        }
        if (filters.source_provider !== '') {
            count++;
        }
        return count
    };

    const handleSearch = (search) => {
        handleFilters({
            ...filters,
            search
        })
    }

    return (
        <Grid container flexWrap="nowrap" spacing={2} justifyContent={'space-between'}>
            <Grid item xs={4}>
                <VerticalSelector onChange={handleVerticalChange} value={filters?.vertical}/>
            </Grid>
            <Grid item xs={4} container justifyContent={'flex-end'} spacing={0.5}>
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            fontSize: '0.75rem',
                            color: '#989898'
                        }}>{'Filter By'}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <FilterButton handleSearch={handleSearch} placeholder={t('Filter downloaded papers')}
                                  filterCount={getFilterCount()}
                                  handleFilterOpen={handleFilterOpen}/>
                </Grid>
                <>{open && <FilterDropdown
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleFilterClose}
                >
                    <FilterForm onSubmit={handleSubmit} filters={filters}/>
                </FilterDropdown>}</>
            </Grid>
        </Grid>
    );
};

export default Filters;
