import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';

import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';
import GraphCard from 'components/elements/GraphCard';
import useCreationOverTime from 'hooks/dashboard/useCreationOverTime';

import TimeSeriesChart from './time-series-chart';
import alpha from "color-alpha";

const CreationOverTime = ({}) => {
    const [selected, setSelected] = useState('papers');
    const [results, setResults] = useState([]);
    const [range, setRange] = useState('28');

    const {data, status} = useCreationOverTime({type: selected, range});

    useEffect(() => {
        if (data && data.data) {
            const flattened = []
            let group = {
                data: [],
                type: 'area',
                color:alpha('#3401af', .5),
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, alpha('#3401af', .5)],
                        [1, alpha('#3401af', .2)]
                    ]
                },
            }

            for (const item of data.data) {
                group.data.push({
                    x: new Date(item.created_date),
                    y: item.count,
                })
            }

            flattened.push(group)


            // const flattened = data.data.reduce((group, item, currentIndex) => {
            //     const vertical = item.vertical;
            //     const index = group.findIndex(s => s.id === vertical);
            //     if (index >= 0) {
            //         group[index].data = [...group[index].data, {
            //             x: new Date(item.created_date),
            //             y: item.count,
            //         }];
            //     } else {
            //         group.push({
            //             data: [{
            //                 x: new Date(item.created_date),
            //                 y: item.count,
            //             }],
            //             name: item.vertical,
            //             id: item.vertical,
            //             type: 'area',
            //             // visible: currentIndex === 0,
            //             // fillColor: {
            //             //     linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
            //             //     stops: [
            //             //         [0],
            //             //         [1]
            //             //     ]
            //             // },
            //         });
            //     }
            //     return group;
            // }, []);
            setResults(flattened)
        }
    }, [data])

    const items = [
        {id: 'papers', name: 'Papers', value: 'papers'},
        {id: 'evidences', name: 'Evidences', value: 'evidences'},
        // {id: 'plays', name: 'Plays', value: 'plays'}];
    ]

    const ranges = [
        {id: '7DAYS', name: 'Last 7 days', value: '7'},
        {id: '28DAYS', name: 'Last 28 days', value: '28'},
        {id: '90DAYS', name: 'Last 90 days', value: '90'}
    ]
    const handleChange = (value) => {
        setSelected(value)
    }

    const getTitle = () => {
        return items[items.findIndex(i => i.value === selected)].name
    }

    return <GraphCard title={`${getTitle()} created over time`}>
        <Grid container justifyContent={'flex-end'} spacing={2}>
            <Grid item xs={3}>
                <LabeledCustomSelect
                    sx={{
                        backgroundColor: '#fff', '.MuiOutlinedInput-notchedOutline': {
                            top: 0,
                            'legend': {display: 'none'},
                        },
                        '.MuiInputBase-input': {
                            fontSize: '0.875rem !important'
                        }
                    }}
                    id="select-ranges"
                    name="range"
                    size={'small'}
                    selectItems={ranges}
                    emptyPlaceholder
                    value={range}
                    onChange={(e) => {
                        setRange(e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <LabeledCustomSelect
                    sx={{
                        backgroundColor: '#fff', '.MuiOutlinedInput-notchedOutline': {
                            top: 0,
                            'legend': {display: 'none'},
                        },
                        '.MuiInputBase-input': {
                            fontSize: '0.875rem !important'
                        }
                    }}
                    id="select-runStatuses"
                    name="runStatuses"
                    size={'small'}
                    selectItems={items}
                    emptyPlaceholder
                    value={selected}
                    onChange={(e) => {
                        handleChange(e.target.value);
                    }}
                />
            </Grid>
        </Grid>
        <TimeSeriesChart series={results}/>
    </GraphCard>
}

export default CreationOverTime