import React from 'react';
import MuiChip from '@mui/material/Chip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import styled from '@mui/system/styled';

const Chip = styled(MuiChip)(() => ({
    color: 'black',
    height: '1.8em',
    fontFamily: 'Open Sans',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '0.875rem',
}));

const LabeledChip = ({
                         label,
                         backgroundColor = '#00d2d3',
                         iconColor='#616161',
                         icon,
                         title,
                         disableHoverListenerAction = false,
                         cursor = 'default'
                     }) => {

    function wc_hex_is_light(color) {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        return brightness < 155;
    }

    return title ? (
        <Tooltip
            title={<h1 style={{fontSize: '1rem'}}>{title}</h1>}
            placement="top"
            disableHoverListener={disableHoverListenerAction}
            arrow
        >
            <Chip
                label={label}
                style={{
                    backgroundColor,
                    cursor
                }}
                icon={icon}
                sx={{
                    '& .MuiChip-icon': {
                        color: iconColor,  // Set the icon color here
                    },
                }}
            />
        </Tooltip>
    ) : (
        <Chip
            label={label}
            style={{
                backgroundColor,
                color: wc_hex_is_light(backgroundColor) ? '#ffffff' : "#000000",
                cursor,
            }}
            sx={{
                '& .MuiChip-icon': {
                    color: iconColor,  // Set the icon color here
                },
            }}
            icon={icon}
        />
    );
};

export default LabeledChip;
