import React, { useRef} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {useTranslation} from 'react-i18next';
import {Button} from "@mui/material";

import useEvidenceCreate from "hooks/evidences/useEvidenceCreate";
import EvidenceForm from "../evidence-form";

const CreateEvidenceDialog = ({open, data, handleClose}) => {
    const {t} = useTranslation();
    const formRef = useRef();

    const {mutateAsync, status} = useEvidenceCreate();

    const handleSave = () => {
        formRef.current.handleSubmit();
    }

    const handleSubmit = (values) => {
        const req = {
            paper_id: data.paper_id,
            extracted_evidences: values,
            extraction_type: 'MANUAL',
        }
        mutateAsync({req}).then(() => {
            handleClose()
        });
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={'md'}
            >
                <DialogContent sx={{padding: '2rem', minHeight: '50vh'}}>
                    <Stack direction='row' spacing={1} justifyContent='flex-end'>
                        <IconButton
                            aria-label='close'
                            onClick={handleClose}
                            sx={{
                                backgroundColor: '#333333',
                                padding: '4px',
                                '&:hover': {
                                    backgroundColor: '#333333',
                                },
                            }}
                        >
                            <CloseIcon sx={{color: '#ffffff', fontSize: '1.2rem'}}/>
                        </IconButton>
                    </Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={11} container spacing={2} sx={{paddingBottom: '2em !important'}}>
                            <Grid item xs={12}
                                  sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Typography sx={{
                                    fontWeight: '700',
                                    fontSize: '1.2rem'
                                }}>{t('Add new evidence')}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{paddingTop: '4px !important'}}>
                                <Divider sx={{borderBottom: '2px solid black;', width: '100%'}}/>
                            </Grid>
                        </Grid>

                        <Grid item xs={11} container spacing={3} sx={{overflow:'auto',height:'calc(100vh - 400px)', paddingTop: '0 !important'}}>
                            <EvidenceForm formRef={formRef} onSubmit={handleSubmit}/>
                        </Grid>
                        <Grid item xs={11} container justifyContent={'flex-end'}>
                            <Grid item>
                                <Button variant="contained" disabled={status === 'loading'}
                                        onClick={handleSave}>Save</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default CreateEvidenceDialog;