import { useMutation } from '@tanstack/react-query';
import api from 'services/api';

const usePaperCSVDownload = () => {
    const fetchPaperCSV = async ({ filters, sort, vertical }) => {
        const query = new URLSearchParams();
    
        if (filters.search) {
            query.append('paper_name', filters.search);
        }
    
        if ([0, 1].includes(filters.is_relevant_paper)) {
            query.append('is_relevant_paper', filters.is_relevant_paper);
        }
    
        if ([0, 1].includes(filters.is_downloaded)) {
            query.append('is_downloaded', filters.is_downloaded);
        }
    
        if ([1].includes(filters.with_abstract)) {
            query.append('with_abstract', filters.with_abstract);
        }
    
        if (['RELEVANT', 'NOT_RELEVANT', 'NOT_EVALUATED'].includes(filters.manually_validated)) {
            query.append('manually_validated', filters.manually_validated);
        }
    
        if (['RELEVANT', 'NOT_RELEVANT', 'NOT_EVALUATED'].includes(filters.gpt)) {
            query.append('gpt', filters.gpt);
        }
    
        if (['RELEVANT', 'NOT_RELEVANT', 'NOT_EVALUATED'].includes(filters.cml)) {
            query.append('cml', filters.cml);
        }
    
        if (vertical) {
            query.append('vertical', vertical);
        }
    
        if (sort.citation_count && sort.current === 'citation_count') {
            query.append('sort_by', 'citation_count');
            query.append('order', sort.citation_count);
        }
    
        if (sort.published_year && sort.current === 'published_year') {
            query.append('sort_by', 'published_year');
            query.append('order', sort.published_year);
        }
    
        if (sort.created_at && sort.current === 'created_at') {
            query.append('sort_by', 'created_at');
            query.append('order', sort.created_at);
        }
    
        try {
            const result = await api.get(`api/v1/papers/download?${query.toString()}`, {
                responseType: 'blob', // Ensure you get the blob for file download
            });
            let filename = ''
            const header = result.headers.get('Content-Disposition');
            if(header) {
                const parts = header.split(';');
                filename = parts[1].split('=')[1];
            }
            const data = await result.blob();
            return {data, filename};
        } catch (e) {
            return Promise.reject(e);
        }
    };
    return useMutation({
        mutationFn: ({filters, sort, vertical}) => fetchPaperCSV({ filters, sort, vertical }),
    });
};

export default usePaperCSVDownload;
