import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';

import useClassifierConfigs from 'hooks/classifiers/useClassifierConfigs';
import LoaderSpinner from 'components/elements/LoaderSpinner';

import AdvancedForm from './advanced-form';

const AdvancedConfigs = ({formRef, onSubmit, request}) => {
    const {t} = useTranslation();
    const [configs, setConfigs] = useState(null)

    const {mutateAsync, status} = useClassifierConfigs();

    useEffect(() => {
        mutateAsync({verticals: request.verticals}).then((data)=>{
            setConfigs(data.data)
        })

    }, [request])

    if(status==='error'){

    }

    if (!configs){
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: 'calc(80vh - 232px)'}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={'#6abed5'} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    return <Grid container item xs={12}>
        <Grid item xs={12}>
            {configs && <AdvancedForm formRef={formRef} onSubmit={onSubmit} configs={configs}/>}
        </Grid>
    </Grid>

}

export default AdvancedConfigs;