import {useQuery} from '@tanstack/react-query';

import api from 'services/api';

const fetchCreationOverTime = async ({type,range}) => {
    const query = new URLSearchParams({});
    try {
        if (range) {
            query.append('range', range);
        }

        const response = await api.get(`api/v1/dashboard/${type}?${query.toString()}`);
        const {
            data: {payload, meta},
        } = await response.json();

        return {data: payload, meta};
    } catch (e) {
        return Promise.reject(e);
    }
};

const useCreationOverTime = ({enabled = true, type,range}) => {
    return useQuery({
        queryKey: ['dashboard-creation-over-time',type,range],
        queryFn: ({}) => fetchCreationOverTime({type,range}),
        enabled,
    });
};

export default useCreationOverTime;
