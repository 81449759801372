import Grid from '@mui/material/Grid';
import {Card} from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

const GraphCard = ({title, children}) => {
    return <Grid item xs={12} sx={{height: '100%'}}>
        <Card sx={{padding: 2, height: '100%'}}>
            <Grid container spacing={1} sx={{height: '100%'}}>
                <Grid item xs={12}>
                    <Typography sx={{
                        fontSize: '1.25rem',
                        fontWeight: '700',
                        textTransform:'capitalize'
                    }}>{title}</Typography>
                </Grid>
                <Grid item xs={12} sx={{height: '100%'}}>
                    {children}
                </Grid>
            </Grid>
        </Card>
    </Grid>
}

export default GraphCard;