import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useNavigate} from 'react-router-dom';

import SummaryCard from 'components/elements/SummaryCard';
import useDashboardStats from 'hooks/dashboard/useDashboardStats';
import {ROUTE_EVIDENCE_EXPLORE, ROUTE_INBOX_EXPLORE, ROUTE_PLAYS_EXPLORE} from 'pages/constants';

const Summary = () => {
    const navigate = useNavigate();
    const [results, setResults] = useState(null);

    const {data, status} = useDashboardStats({});

    useEffect(() => {
        if (data && data.data) {
            setResults(data.data)
        }
    }, [data])

    const handleViewPapers = () => {
        navigate(ROUTE_INBOX_EXPLORE);
    };

    const handleViewEvidences = () => {
        navigate(ROUTE_EVIDENCE_EXPLORE);
    };

    const handleViewPlays = () => {
        navigate(ROUTE_PLAYS_EXPLORE);
    };


    return <>{results && <Grid xs={12} item container spacing={2} flexWrap={'nowrap'}>
        <SummaryCard title={'Total verticals'} count={results['vertical_count']}/>
        <SummaryCard title={'Total search sessions'} count={results['search_session_count']}/>
        <SummaryCard title={'Total papers'} count={results['paper_count']} onClick={handleViewPapers}/>
        <SummaryCard title={'Total evidences'} count={results['evidence_count']} onClick={handleViewEvidences}/>
        <SummaryCard title={'Total plays'} count={"-"} onClick={handleViewPlays}/>
    </Grid>}</>
}

export default Summary;