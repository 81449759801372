import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';

import VerticalSelector from 'components/elements/VerticalSelector';
import FilterButton from 'components/elements/FilterButton';
import FilterDropdown from 'components/elements/FilterDropdown';

const Filters = ({
                     handleSearch = () => {
                     },
                     handleFilters = () => {
                     },
                     filters
                 }) => {
    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleFilterOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleFilterClose = (value) => {
        setAnchorEl(null);
    };

    const handleVerticalChange = (value) => {
        handleFilters({
            ...filters,
            vertical: value,
        })
    }

    const handleSubmit = (values) => {
        handleFilters({
            ...filters,
            is_downloaded: values.is_downloaded ? 1 : 0
        })
        handleFilterClose()
    }

    const getFilterCount = () => {
        return filters.is_downloaded;
    };

    return (
        <Grid container flexWrap="nowrap" spacing={1} justifyContent={'space-between'}>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={4} container justifyContent={'flex-end'} spacing={0.5}>
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            fontSize: '0.75rem',
                            color: '#989898'
                        }}>{'Filter By'}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <FilterButton handleSearch={handleSearch} placeholder={t('Search verticals')}
                                  filterCount={getFilterCount()}
                                  handleFilterOpen={handleFilterOpen}/>
                </Grid>
                <>{open && <FilterDropdown
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleFilterClose}
                >

                </FilterDropdown>}</>
            </Grid>
        </Grid>
    );
};

export default Filters;
