import React, {useEffect, useRef, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useResizeDetector} from 'react-resize-detector';
import Box from '@mui/material/Box';

import useDebounce from 'hooks/useDebounce';
import alpha from "color-alpha";

const CurationProgressChart = ({title, data = [], maxHeight = '600px'}) => {
    const {width, ref} = useResizeDetector();
    const chartWidth = useDebounce(width, 200);

    const charOptions = () => {
        return {
            chart: {
                type: 'bar',
            },
            title: {
                text: title,
                align: 'left'
            },
            xAxis: {
                title: {
                    text: null
                },
                gridLineWidth: 1,
                lineWidth: 0,
            },
            yAxis: {
                min: 0,
                labels: {
                    overflow: 'justify'
                },
                title: {
                    text: null
                },
                gridLineWidth: 0
            },
            plotOptions: {
                bar: {
                    borderRadius: '50%',
                    dataLabels: {
                        enabled: true,
                    },
                    groupPadding: 0.1
                },
                series: {
                    animation: false
                }
            },
            tooltip: {
                style: {
                    fontSize: "0.875rem",
                },
                useHTML: true,
                formatter: function () {
                    return `<span>${this.key}<br/> ${this.point.series.name}: ${this.point.y.toFixed(2)}</span>`;
                },
            },
            credits: {
                enabled: false
            },
        };
    }

    const [options, setOptions] = useState(charOptions());
    const chartRef = useRef(null);

    useEffect(() => {

            const categories = []
            const withPlays = []
            const reviewed = []
            const total = []
            for (const value of data) {
                categories.push(value.vertical)
                withPlays.push(value.play_created_count)
                reviewed.push(value.reviewed_count)
                total.push(value.count)
            }

            let series = [
                {
                    name: 'Papers with at least one play created',
                    data: withPlays,
                    color: alpha('#3401af', .5)
                },
                {
                    name: 'Papers with reviewed evidences',
                    data: reviewed,
                    color: alpha('#df812e', .5)
                }, {
                    name: 'Papers in vertical',
                    data: total,
                    color: alpha('#49ac43', .5)
                }]
            setOptions({
                ...charOptions(),
                series: series,
                xAxis: {
                    categories
                }
            });
        }, [data]
    )

    useEffect(() => {
        if (chartRef) {
            chartRef.current.chart.reflow();
        }

    }, [chartWidth])

    return (<Box sx={{height: '100%', minHeight: maxHeight, maxHeight,overflow:'auto'}} ref={ref}>
            <HighchartsReact containerProps={{style: {height: '100%'}}} ref={chartRef} highcharts={Highcharts}
                             options={options}/>
        </Box>
    );
};

export default CurationProgressChart;
