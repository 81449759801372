import React from 'react';
import Grid from '@mui/material/Grid';
import {Chip, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';
import {Formik, getIn} from 'formik';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import TermWeightForm from './term-weight-form';
import SearchPhraseForm from './search-phrase-form';
import styled from '@mui/system/styled';
import MuiHelpOutlineIcon from '@mui/icons-material/HelpOutline';

import CustomTooltip from 'components/elements/CustomTooltip';

const HelpOutlineIcon = styled(MuiHelpOutlineIcon)(({theme}) => ({
    fontSize: 'small',
}));

const ConfigHeader = ({title})=>{
    return <Typography>{title} <span><CustomTooltip title={title}> <span
        style={{verticalAlign: '-0.1em'}}><HelpOutlineIcon fontSize="1.6rem"
                                                           sx={{'&.MuiSvgIcon-root': {color: '#666666'}}}/></span></CustomTooltip></span></Typography>
}

const AdvancedForm = ({
                          formRef,
                          onSubmit,
                          configs
                      }) => {
    const {t} = useTranslation();

    const validationSchema = Yup.object().shape({});

    const handleDeleteTableParams = (values) => {
        let searchPhrases = formRef.current.values.table_search_params;
        delete searchPhrases[values];
        formRef.current.setFieldValue('table_search_params', searchPhrases)
    }

    const handleAddTableParams = (values, {resetForm}) => {
        let param = {[values.term]: values.weight}
        formRef.current.setFieldValue('table_search_params', {...formRef.current.values.table_search_params, ...param})
        resetForm()
    }

    const handleDeleteKeywordParams = (values) => {
        let searchPhrases = formRef.current.values.keyword_search_params;
        delete searchPhrases[values];
        formRef.current.setFieldValue('keyword_search_params', searchPhrases)
    }

    const handleAddKeywordParams = (values, {resetForm}) => {
        let param = {[values.term]: values.weight}
        formRef.current.setFieldValue('keyword_search_params', {...formRef.current.values.keyword_search_params, ...param})
        resetForm()
    }

    const handleDeleteSearchPhrase = (values) => {
        let searchPhrases = formRef.current.values.search_phrases;
        formRef.current.setFieldValue('search_phrases', searchPhrases.filter(val => val !== values))
    }

    const handleAddSearchPhrase = (values, {resetForm}) => {
        formRef.current.setFieldValue('search_phrases', [...formRef.current.values.search_phrases, values.search_phrase])
        resetForm()
    }

    return <Grid conainer item xs={12}>
        <Formik
            initialValues={{
                model_training_params: configs ? configs['model_training_params'] : {train_test_split:0},
                keyword_search_params: configs ? configs['keyword_search_params'] : null,
                table_search_params: configs ? configs['table_search_params'] : null,
                search_phrases: configs ? configs['search_phrases'] : [''],
                step:'advanced',
                trigger:false
            }}
            innerRef={formRef}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleChange,
                  values,
                  touched,
                  errors,
                  setFieldValue
              }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                            <ConfigHeader title={'Train parameters'}/>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="train_test_split"
                                   label={t("Train test split")}
                                   name="model_training_params.train_test_split"
                                   value={values.model_training_params.train_test_split}
                                   onChange={handleChange}
                                   error={Boolean(getIn(touched, `model_training_params.train_test_split`) && getIn(errors, `model_training_params.train_test_split`))}
                                   helperText={getIn(touched, `model_training_params.train_test_split`) && getIn(errors, `model_training_params.train_test_split`)}
                                   fullWidth
                                   variant="outlined" size="small"/>
                    </Grid>

                    <Grid container spacing={1} item xs={12}>
                        <Grid item xs={12} container justifyContent={'space-between'}>
                            <Grid item>
                                <ConfigHeader title={'Table search terms'}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>

                            <Grid item xs={12} container spacing={1}>
                                {values['table_search_params'] && Object.entries(values['table_search_params']).map(([key, value], index) => {
                                    return <Grid item><Chip
                                        label={`${key} : ${value}`}
                                        key={`table_search_params-${index}`}
                                        onDelete={() => handleDeleteTableParams(key)}
                                    />
                                    </Grid>
                                })}
                            </Grid>
                            <Grid item xs={12} container spacing={1}>
                                <TermWeightForm onSubmit={handleAddTableParams}/>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid container spacing={1} item xs={12}>
                        <Grid item xs={12} container justifyContent={'space-between'}>
                            <Grid item>
                                <ConfigHeader title={'Keyword search terms'}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>

                            <Grid item xs={12} container spacing={1}>
                                {values['keyword_search_params'] && Object.entries(values['keyword_search_params']).map(([key, value], index) => {
                                    return <Grid item><Chip
                                        label={`${key} : ${value}`}
                                        key={`keyword_search_params-${index}`}
                                        onDelete={() => handleDeleteKeywordParams(key)}
                                    />
                                    </Grid>
                                })}
                            </Grid>
                            <Grid item xs={12} container spacing={1}>
                                <TermWeightForm onSubmit={handleAddKeywordParams}/>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid container spacing={1} item xs={12}>
                        <Grid item xs={12} container justifyContent={'space-between'}>
                            <Grid item>
                                <ConfigHeader title={'Search phrases'}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>

                            <Grid item xs={12} container spacing={1}>
                                {values['search_phrases'].map((field, index) => {
                                    return <Grid item><Chip
                                        label={field}
                                        key={`search-phrase-${index}`}
                                        onDelete={() => handleDeleteSearchPhrase(field)}
                                    />
                                    </Grid>
                                })}
                            </Grid>
                            <Grid item xs={12} container spacing={1}>
                                <SearchPhraseForm onSubmit={handleAddSearchPhrase}/>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            )}
        </Formik>
    </Grid>
}

export default AdvancedForm;