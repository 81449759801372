import React from 'react';
import Grid from '@mui/material/Grid';
import {TextField} from '@mui/material';
import {FieldArray, Formik, getIn} from 'formik';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const SearchPhraseForm = ({onSubmit}) => {
    const {t} = useTranslation();

    const validationSchema = Yup.object().shape({
        search_phrase: Yup.string().required(t('Search phrase is required')),
    });

    return <Grid conainer item xs={10}>
        <Formik
            initialValues={{
                search_phrase: '',
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleChange,
                  values,
                  touched,
                  errors,
                  handleSubmit,
                  handleReset
              }) => (
                <Grid container spacing={2}>
                    <Grid item container spacing={1} xs={10.5}
                          alignItems={'center'}>
                        <Grid item xs={12}>
                            <TextField
                                id={`search_phrase`}
                                name={`search_phrase`}
                                error={Boolean(getIn(touched, `search_phrase`) && getIn(errors, `search_phrase`))}
                                helperText={getIn(touched, `search_phrase`) && getIn(errors, `search_phrase`)}
                                label={t("Search phrase")}
                                variant="outlined"
                                fullWidth
                                value={values.search_phrase}
                                onChange={handleChange}
                                size="small"/>
                        </Grid>

                    </Grid>
                    <Grid container alignItems={'center'} item xs={1.5}
                          justifyContent={"flex-end"}>
                        <Grid item>
                            <IconButton size={"small"}
                                        onClick={handleSubmit}><AddBoxIcon/></IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton size={"small"}
                                        onClick={handleReset}><CloseIcon/></IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Formik>
    </Grid>
}

export default SearchPhraseForm;