import styled from '@mui/system/styled';
import {Button} from '@mui/material';


const CustomButton = styled(Button)(() => ({
    '&.MuiButton-root': {
        '&:hover': {fontWeight: '700'},
        backgroundColor: 'transparent',
        textTransform: 'none',
        paddingRight: '0',
        paddingLeft: '0',
    }
}))

export default CustomButton;