import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import {TextField} from '@mui/material';
import {Formik} from 'formik';

import CustomMultiSelect from 'components/elements/CustomMultiSelect';
import {format} from "date-fns";
import Typography from "@mui/material/Typography";


const TrainForm = ({
                       formRef,
                       onSubmit,
                       request,
                       verticals
                   }) => {

    const {t} = useTranslation();

    const validationSchema = Yup.object().shape({
        model_name: Yup.string().required(t('Model name is required')),
        verticals: Yup.array()
            .min(1, t('Select at least 1 vertical'))
            .required(t('At least 1 vertical is required')),
    });

    return (
        <Grid conainer item xs={12}>
            <Formik
                initialValues={{
                    model_name: request ? request['model_name'] : `Train-Classifier-${format(Date.now(), 'yyyy-MM-dd HH:mm:ss')}`,
                    verticals: request ? request['verticals'] : [],
                    step: 'train',
                    trigger: false
                }}
                innerRef={formRef}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue,
                  }) => (
                    <Grid container spacing={3} item xs={12}>

                        <Grid item xs={12} container spacing={0.5}>
                            <Grid item xs={12}>
                                <Typography sx={{fontSize: '0.875rem'}}>Verticals</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{paddingLeft: '0.25em !important'}}>
                                <CustomMultiSelect
                                    value={values.verticals}
                                    onChange={setFieldValue}
                                    label={t("Verticals")}
                                    name="verticals"
                                    height={280}
                                    inputProps={{
                                        id: 'verticals',
                                    }}
                                    searchable
                                    error={Boolean(touched.verticals && errors.verticals)}
                                    helperText={touched.verticals && errors.verticals}
                                    options={verticals}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField id="name"
                                       label={t("Model name")}
                                       required
                                       sx={{'.MuiFormHelperText-root':{marginLeft:0}}}
                                       name="model_name"
                                       value={values.model_name}
                                       onChange={handleChange}
                                       error={Boolean(touched.model_name && errors.model_name)}
                                       helperText={touched.model_name && errors.model_name}
                                       fullWidth
                                       variant="outlined" size="small"/>
                        </Grid>

                    </Grid>
                )}
            </Formik>
        </Grid>
    );
};

export default TrainForm;