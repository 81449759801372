import {useQuery} from '@tanstack/react-query';

import api from 'services/api';

const fetchEligibility = async ({vertical, extractionTypes = [], papers = [], selectedAll = false}) => {

    const query = new URLSearchParams({});

    if (vertical) {
        query.append('vertical', vertical);
    }

    if (selectedAll) {
        query.append('select_all_papers', selectedAll.toString());
    }

    if (papers) {
        query.append('papers', `'${papers.join("','")}'`);
    }

    if (extractionTypes) {
        query.append('extraction_types', `'${extractionTypes.join("','")}'`);
    }

    try {
        const response = await api.get(`api/v1/evidences/extractions/check?${query.toString()}`);
        const {
            data: {payload, meta},
        } = await response.json();

        return {data: payload, meta};
    } catch (e) {
        return Promise.reject(e);
    }
};

const useExtractionEligibility = ({vertical, extractionTypes, papers, selectedAll, enabled = true,}) => {
    return useQuery({
        queryKey: ['extraction-eligibility', vertical, papers, selectedAll,extractionTypes],
        queryFn: () => fetchEligibility({vertical, extractionTypes, papers, selectedAll}),
        enabled,
    });
};

export default useExtractionEligibility;
