import {useMutation} from '@tanstack/react-query';
import api from 'services/api';

const useEvidenceCreate = () => {
    const createEvidence = async (req) => {
        try {
            const response = await api.post(
                `api/v1/evidences`,
                JSON.stringify(req),
            );

            const {
                data: {payload, meta},
            } = await response.json();

            return {data: payload, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation({
        mutationFn: ({req}) => createEvidence(req),
    });
};

export default useEvidenceCreate;