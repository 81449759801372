import React, {useEffect, useState} from 'react';

import GraphCard from 'components/elements/GraphCard';
import useDashboard from "hooks/dashboard/useDashboard";

import PieChart from './pie-chart';
import Grid from "@mui/material/Grid";
import PaperDistribution from "./paper-distributuion";
import CreationOverTime from "./creation-over-time";
import Filters from "./filters";
import ColumnChart from "./column-chart";
import CurationProgressChart from "./curation-progress-chart";
import BarChart from "./bar-chart";
import StackedBarChart from "./stacked-bar-chart";

const VerticalWiseProgress = () => {
    const [results, setResults] = useState([]);
    const [filters, setFilters] = useState({sortBy: 'RECENT'});

    const {data, status} = useDashboard({});

    useEffect(() => {
        if (data && data.data) {
            setResults(data.data)
        }
    }, [data])

    return <Grid container item xs={12}>

        <Grid xs={12} item container spacing={2} justifyContent={'flex-end'}>
            <Grid xs={4} md={3} item>
                <Filters filters={filters}/>
            </Grid>
        </Grid>

        <>{results && <Grid xs={12} item container spacing={2}>

            <Grid xs={6} item>
                <GraphCard title={'Evidence Extracted By Vertical'}>
                    <ColumnChart data={results['evidence_distribution']??[]}/>
                </GraphCard>
            </Grid>
            <Grid xs={6} item>
                <GraphCard title={'Curation progress by vertical'}>
                    <CurationProgressChart maxHeight={'450px'} data={results['curation_progress']??[]}/>
                </GraphCard>
            </Grid>


            <Grid xs={6} item>
                <GraphCard title={'Classification model test score distribution'}>
                    <BarChart maxHeight={'400px'} data={results['model_score_distribution']??[]}/>
                </GraphCard>
            </Grid>
            <Grid xs={6} item>
                <GraphCard title={'Manual validation overlap with classification model'}>
                    <StackedBarChart data={results['validation_overlap']??[]} maxHeight={'400px'}/>
                </GraphCard>
            </Grid>
        </Grid>}</>
    </Grid>
}

export default VerticalWiseProgress;