import {useMutation} from '@tanstack/react-query';
import api from 'services/api';

const useVerticalDelete = () => {
    const deleteVertical = async (vertical) => {
        try {
            const response = await api.delete(
                `api/v1/verticals/${vertical}`,
            );

            const {
                data: {payload, meta},
            } = await response.json();

            return {data: payload, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation({
        mutationFn: ({vertical}) => deleteVertical(vertical),
    });
};

export default useVerticalDelete;