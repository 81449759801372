import React from 'react';
import Grid from '@mui/material/Grid';
import {TextField} from '@mui/material';
import {Formik, getIn} from 'formik';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const TermWeightForm = ({onSubmit}) => {
    const {t} = useTranslation();

    const validationSchema = Yup.object().shape({
        term: Yup.string().required(t('Term is required')),
        weight: Yup.string().required(t('Weight is required')),
    });

    const initialValues={
        term: '',
        weight: 0,
    }

    return <Grid conainer item xs={12}>
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleChange,
                  values,
                  touched,
                  errors,
                  setFieldValue,
                  handleSubmit,
                  handleReset
              }) => (
                <Grid container spacing={2}>

                    <Grid item container spacing={1} xs={12}>

                        <Grid item container spacing={1} xs={6}
                              alignItems={'center'}>
                            <Grid item xs={8}>
                                <TextField
                                    id={`term`}
                                    name={`term`}
                                    error={Boolean(getIn(touched, `term`) && getIn(errors, `term`))}
                                    helperText={getIn(touched, `term`) && getIn(errors, `term`)}
                                    label={t("Term")}
                                    variant="outlined"
                                    fullWidth
                                    value={values.term}
                                    onChange={handleChange}
                                    size="small"/>
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    id={`weight`}
                                    label={t("Weight")}
                                    name={`weight`}
                                    error={Boolean(getIn(touched, `weight`) && getIn(errors, `weight`))}
                                    helperText={getIn(touched, `weight`) && getIn(errors, `weight`)}
                                    value={values.weight}
                                    onChange={handleChange}
                                    fullWidth
                                    type={'number'}
                                    variant="outlined" size="small"/>
                            </Grid>

                        </Grid>
                        <Grid container alignItems={'center'} item xs={1}
                              justifyContent={"flex-end"}>
                            <Grid item>
                                <IconButton size={"small"}
                                            onClick={handleSubmit}><AddBoxIcon/></IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton size={"small"}
                                            onClick={handleReset}><CloseIcon/></IconButton>
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            )}
        </Formik>
    </Grid>
}

export default TermWeightForm;