import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import styled from '@mui/system/styled';
import Paper from '@mui/material/Paper';
import MuiTypography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplyIcon from '@mui/icons-material/Reply';

import PageContainer from 'components/elements/PageContainer';
import FilterButton from 'components/elements/FilterButton';
import PlaysTable from './playbook-items';

const ActionArea = () => {
    const { t } = useTranslation();

    return (
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>{t('plays.explore.actionTitle')}</Grid>
            <Grid item>
                <FilterButton placeholder={t('plays.explore.searchPlaceholder')} />
            </Grid>
        </Grid>
    );
};

const PlaybookItemActions = ({ handleDescriptionClick, handleBookmarkClick, handleReplyClick }) => {
    return (
        <Grid container spacing={2}>
            <Grid item>
                <DescriptionIcon sx={{ fontSize: '1rem', color: '#b1b1b1' }} onClick={handleDescriptionClick} />
            </Grid>
            <Grid item>
                <BookmarkIcon sx={{ fontSize: '1rem', color: '#b1b1b1' }} onClick={handleBookmarkClick} />
            </Grid>
            <Grid item>
                <ReplyIcon
                    sx={{ fontSize: '1rem', color: '#b1b1b1', transform: 'scaleX(-1)' }}
                    onClick={handleReplyClick}
                />
            </Grid>
        </Grid>
    );
};

function createData(name, calories, fat, carbs, difficulty, protein) {
    return { name, calories, fat, carbs, difficulty, protein };
}

const rows = [createData(3, 159, 6.0, 24, 'simple', 4.0), createData(4.5, 237, 9.0, 37, 'moderate', 4.3)];

const PlaybookItem = () => {
    const { t } = useTranslation();

    return (
        <Paper sx={{ width: '100%' }}>
            <Grid container sx={{ padding: '1em' }}>
                <Grid item container flexDirection="column" justifyContent="space-between" flexWrap="nowrap" xs={2}>
                    <Grid item>
                        <MuiTypography fontSize="0.7rem" color="#666666" paddingBottom="0.2em">
                            Segment
                        </MuiTypography>
                        <MuiTypography fontSize="0.7rem" fontWeight="700">
                            All customers getting onboarded
                        </MuiTypography>
                    </Grid>
                    <Grid item>
                        <PlaybookItemActions />
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <PlaysTable plays={rows} />
                </Grid>
            </Grid>
        </Paper>
    );
};

const Playbooks = ({ containerheight }) => {
    const { t } = useTranslation();

    return (
        <PageContainer pageTitle={t('plays.explore.pageTitle')} pageSubTitle={t('plays.explore.pageSubTitle')}>
            <Grid container spacing={2}>
                <Grid item width="100%">
                    <ActionArea />
                </Grid>
                <Grid item container width="100%">
                    <PlaybookItem />
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default Playbooks;
