import * as React from 'react';
const SVGComponent = (props) => (
    <svg
        width={77.202599}
        height={54.137085}
        viewBox="0 0 20.426521 14.323771"
        id="svg5"
        inkscapeVersion="1.1.2 (0a00cf5339, 2022-02-04)"
        sodipodiDocname="cml_logo_new.svg"
        xmlnsInkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlnsSodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsSvg="http://www.w3.org/2000/svg"
        {...props}
    >
        <sodipodiNamedview
            id="namedview7"
            pagecolor="#ffffff"
            bordercolor="#999999"
            borderopacity={1}
            inkscapePageshadow={0}
            inkscapePageopacity={0}
            inkscapePagecheckerboard={0}
            inkscapeDocument-units="mm"
            showgrid="false"
            showborder="false"
            inkscapeObject-paths="true"
            inkscapeSnap-intersection-paths="true"
            inkscapeSnap-smooth-nodes="true"
            inkscapeZoom={5.12}
            inkscapeCx={64.84375}
            inkscapeCy={24.21875}
            inkscapeWindow-width={1920}
            inkscapeWindow-height={1011}
            inkscapeWindow-x={1920}
            inkscapeWindow-y={0}
            inkscapeWindow-maximized={1}
            inkscapeCurrent-layer="layer1"
            inkscapeSnap-midpoints="true"
            fit-margin-top={0}
            fit-margin-left={0}
            fit-margin-right={0}
            fit-margin-bottom={0}
            units="px"
        />
        <defs id="defs2">
            <inkscapePath-effect
                effect="fillet_chamfer"
                id="path-effect824"
                is_visible="true"
                lpeversion={1}
                satellites_param="F,0,0,1,0,0,0,100 @ F,0,0,1,0,0,0,100 @ F,0,0,1,0,0,0,100 @ F,0,0,1,0,0,0,100 @ F,0,0,1,0,0,0,100 @ F,0,0,1,0,0,0,100 @ F,0,0,1,0,0,0,100 @ F,0,0,1,0,0.91202186,0,100 @ F,0,0,1,0,0,0,100 @ F,0,0,1,0,0,0,100 @ F,0,0,1,0,0,0,100 @ F,0,0,1,0,0,0,100 @ F,0,0,1,0,0,0,100 @ F,0,0,1,0,0,0,100 @ F,0,0,1,0,0,0,100 @ F,0,0,1,0,0,0,100"
                unit="px"
                method="arc"
                mode="C"
                radius={0}
                chamfer_steps={101}
                flexible="false"
                use_knot_distance="true"
                apply_no_radius="true"
                apply_with_radius="true"
                only_selected="false"
                hide_knots="false"
            />
        </defs>
        <g inkscapeLabel="Layer 1" inkscapeGroupmode="layer" id="layer1" transform="translate(1146.1071,2182.1887)">
            <g
                id="g18904"
                transform="matrix(0.02334936,0,0,0.02334936,-1119.3462,-2131.1218)"
                inkscapeExport-filename="/home/asiri/Desktop/cml_logo.png"
                inkscapeExport-xdpi={96}
                inkscapeExport-ydpi={96}
            >
                <path
                    style={{
                        color: '#000000',
                        fill: '#3b91e4',
                        strokeLinecap: 'round',
                        strokeLinejoin: 'round',
                        InkscapeStroke: 'none',
                        paintOrder: 'fill markers stroke',
                    }}
                    d="m -502.07122,-2187.0687 c -18.8279,0.1671 -37.9254,2.6864 -56.918,7.7754 -74.4181,19.9456 -133.1325,75.9876 -157.3398,147.9317 l -202.0177,0.6387 a 32.50325,32.50325 0 0 0 -0.707,0.164 c -19.4264,0.088 -38.7738,2.6177 -57.5703,7.5996 a 32.50325,32.50325 0 0 0 -0.086,0.022 c -121.55238,32.5697 -194.17908,158.3656 -161.60938,279.918 32.5697,121.5523 158.36358,194.1791 279.91608,161.6094 74.4178,-19.9455 133.1324,-75.9878 157.3398,-147.9317 l 202.0195,-0.6387 a 32.50325,32.50325 0 0 0 1.4864,-0.1914 c 19.1634,-0.152 38.2449,-2.6573 56.789,-7.5722 a 32.50325,32.50325 0 0 0 0.086,-0.023 c 121.5524,-32.5698 194.1811,-158.3637 161.6113,-279.9161 -27.4807,-102.5597 -121.3294,-170.289 -222.9999,-169.3867 z m 8.916,65.0078 c 69.7529,2.6605 132.2231,50.0095 151.2988,121.2012 23.4738,87.6054 -28.0106,176.7982 -115.6035,200.2949 -13.6375,3.6109 -27.6918,5.4409 -41.8164,5.4492 a 32.50325,32.50325 0 0 0 -0.6035,0.1387 l -188.3223,0.5957 25.0977,-174.8652 a 32.50325,32.50325 0 0 0 0,-0.039 l 3.3731,-23.4961 c 12.8697,-60.0901 58.1727,-107.8082 117.5625,-123.7266 16.4288,-4.402 32.9129,-6.1666 49.0097,-5.5527 z m -236.0371,155.7402 -25.0957,174.8672 -3.3789,23.5391 c -12.8709,60.0892 -58.1732,107.8032 -117.5625,123.7207 -87.6203,23.4766 -176.82898,-28.0284 -200.30668,-115.6484 -23.4742,-87.6074 28.0132,-176.8005 115.60938,-200.295 13.6352,-3.6096 27.6885,-5.4408 41.8106,-5.4492 a 32.50325,32.50325 0 0 0 1.1074,-0.1406 z"
                    id="path8061-7-7-6-7-6"
                />
                <path
                    id="path13543"
                    style={{
                        fill: '#47dbd6',
                        fillOpacity: 1,
                        strokeWidth: 53.4006,
                        strokeMiterlimit: 1.6,
                        paintOrder: 'fill markers stroke',
                        stopColor: '#000000',
                    }}
                    d="m -283.24988,-2031.3612 h -70.09081 -292.9046 -70.08564 l -202.01765,0.6387 c -0.23625,0.052 -0.47189,0.1066 -0.70692,0.1638 -19.42639,0.088 -38.77363,2.6177 -57.57013,7.5996 -0.0288,0.01 -0.0575,0.015 -0.0863,0.022 -121.55237,32.5697 -194.17897,158.3654 -161.60927,279.9178 1.2433,4.6402 2.6674,9.1832 4.177,13.6777 h 70.0872 292.91135 70.08254 l 202.01919,-0.6387 c 0.4968,-0.052 0.99234,-0.1161 1.48621,-0.1912 19.1634,-0.152 38.24518,-2.6573 56.78929,-7.5722 0.0286,-0.01 0.0572,-0.015 0.0858,-0.023 121.55241,-32.5699 194.18112,-158.3639 161.61132,-279.9163 -1.24345,-4.6406 -2.66876,-9.1833 -4.17856,-13.6782 z m -470.61778,236.9804 h -327.09684 c -3.823,-75.4187 44.9785,-145.3556 121.0381,-165.7558 13.6352,-3.6096 27.68828,-5.4393 41.81038,-5.4493 0.36992,-0.041 0.73912,-0.087 1.10742,-0.1406 l 187.81643,-0.5937 h 65.66834 327.0958 c 3.82389,75.4177 -44.97483,145.3551 -121.03187,165.7574 -13.63761,3.6109 -27.69145,5.4393 -41.81607,5.4493 -0.20162,0.044 -0.40283,0.09 -0.60357,0.1384 l -188.32235,0.5959"
                    sodipodiNodetypes="ccccccscsccccccscscccccccccccccc"
                />
            </g>
        </g>
    </svg>
);
export default SVGComponent;
