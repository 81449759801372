import React, {useState} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import {Checkbox} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

import useHover from 'hooks/useHover';
import usePaperUpdateValidated from 'hooks/papers/usePaperUpdateValidated';
import CustomButton from 'components/elements/CustomButton';

import CreateEvidenceDialog from './createEvidence/create-evidence-dialog';
import ReviewEvidenceDialog from './reviewEvidence/review-evidence-dialog';
import ActionMenu from './ActionMenu';
import {getEvidences} from './utils';

const CardRoot = styled(Box)(() => ({
    padding: '2px 0px 6px 0px',
}));

const Accordion = styled(MuiAccordion)(({theme, active = false}) => ({
    border: '0.1em solid #E9E9E9',
    boxShadow: 'none',
    ...(active && {
        background: 'aliceblue',
    }),
}));

const EvidenceGridCard = ({
                              style,
                              data,
                              index,
                              handleClick,
                              selected = false,
                              handleRefetch,
                              handleSelect,
                              selectedEvidences = [],
                              selectAll = false
                          }) => {
        const {t} = useTranslation();
        const [hoverRef] = useHover();
        let [searchParams] = useSearchParams();
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const [expand, setExpand] = useState(false);

        const [paperAdding, setPaperAdding] = useState(false);
        const [reviewing, setReviewing] = useState(false);

        const {mutateAsync, status} = usePaperUpdateValidated();

        const handleSubmit = (value) => {
            const req = {
                "relevant_paper_manually_validated": value
            }
            mutateAsync({req, paperId: data.paper_id}).then(() => {
                handleMoreClose()
                handleRefetch()
            });
        }

        const handleAdd = (paper) => {
            setPaperAdding(paper)
        }

        const handleAddPaperClose = () => {
            setPaperAdding(null)
        }

        const handleReview = (e) => {
            e.stopPropagation()
            setReviewing(true)
        }

        const handleReviewClose = () => {
            setReviewing(false)
            handleRefetch()
        }

        const isActive = () => {
            return data['run_id'] === searchParams.get('pipeline_id')
        }

        const handleMore = (event) => {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
        };

        const handleMoreClose = () => {
            setAnchorEl(null);
        };

        return (
            <CardRoot style={style} ref={hoverRef}>
                <Accordion expanded={expand} TransitionProps={{unmountOnExit: true}} active={isActive()}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            '&.MuiAccordionSummary-root': {boxShadow: '1px 2px 13px -10px #888'},
                            '&.MuiAccordionSummary-root.Mui-expanded': {minHeight: 'unset'},
                            div: {
                                '&.MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                            },
                            '&:hover': {
                                cursor: 'pointer'
                            },
                            ...(selected && {
                                background: 'aliceblue',
                            }),
                        }}

                    >
                        <Grid container>

                            <Grid item xs={12} container justifyContent="space-between"  spacing={1}
                                  onClick={() => handleClick(data)}>
                                {/*<Grid item xs={12} container spacing={1}>*/}
                                {/*    <Grid item container xs={7.2} alignItems='center' justifyContent='center'>*/}
                                {/*        <Grid item container alignItems='center' justifyContent='center'>*/}
                                {/*            <CustomTooltip title={statusTooltip(data['paper_evidence_status'])}>*/}
                                {/*                {statusIcon(data['paper_evidence_status'])}*/}
                                {/*            </CustomTooltip>*/}
                                {/*            <Grid item>*/}
                                {/*                <Checkbox size="small"*/}
                                {/*                          checked={selectedEvidences.includes(data['paper_id']) || selectAll}*/}
                                {/*                          disabled={selectAll}*/}
                                {/*                          onClick={e => e.stopPropagation()}*/}
                                {/*                          onChange={() => handleSelect(data['paper_id'])}*/}
                                {/*                />*/}
                                {/*            </Grid>*/}
                                {/*        </Grid>*/}
                                {/*    </Grid>*/}
                                {/*    /!*<Grid item container xs={4.8} alignItems='center'>*!/*/}
                                {/*    /!*    <Grid item xs={12} sx={{height: '80%'}}>*!/*/}
                                {/*    /!*        <Divider sx={{*!/*/}
                                {/*    /!*            '&.MuiDivider-root': {*!/*/}
                                {/*    /!*                borderWidth: '1px',*!/*/}
                                {/*    /!*                borderColor: '#a7a7a7',*!/*/}
                                {/*    /!*                width: 'fit-content'*!/*/}
                                {/*    /!*            }*!/*/}
                                {/*    /!*        }}*!/*/}
                                {/*    /!*                 orientation='vertical'/>*!/*/}
                                {/*    /!*    </Grid>*!/*/}
                                {/*    /!*</Grid>*!/*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} container alignItems="center" spacing={1}>


                                    <Grid item xs={12}>
                                        <Typography fontSize={'0.875rem'}>{data['paper_name']}</Typography>
                                    </Grid>
                                    <Grid item xs={12} container spacing={0.5}>
                                        {getEvidences(data['extracted_evidences'])}
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item container spacing={0.5} justifyContent={"space-between"} alignItems="center" xs={12} flexWrap={'nowrap'}>
                                <Grid item sx={{minWidth:'32px',paddingLeft:'0 !important'}}>
                                    <Checkbox size="small"
                                              sx={{padding:'4px'}}
                                              checked={selectedEvidences.includes(data['paper_id']) || selectAll}
                                              disabled={selectAll}
                                              onClick={e => e.stopPropagation()}
                                              onChange={() => handleSelect(data['paper_id'])}
                                    />
                                </Grid>
                                <Grid item container alignItems="center" spacing={1} justifyContent={"flex-end"}>
                                    <Grid item>
                                        {/*<IconButton onClick={handleReview}>*/}
                                        {/*<RateReviewIcon*/}
                                        {/*fontSize={'small'}/></IconButton>*/}

                                        <CustomButton sx={{color: '#0794d3'}} startIcon={<AddBoxIcon fontSize={'small'}/>}
                                                      onClick={handleReview}>{t('Curate')}</CustomButton>


                                    </Grid>
                                    {/*<Grid item><IconButton onClick={(e) => {*/}
                                    {/*    e.stopPropagation()*/}
                                    {/*    handleAdd(data)*/}
                                    {/*}}><AddIcon*/}
                                    {/*    fontSize={'small'}/></IconButton></Grid>*/}
                                    <Grid item>
                                        <IconButton onClick={handleMore}><MoreVertIcon/></IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                </Accordion>
                {paperAdding &&
                    <CreateEvidenceDialog open={paperAdding} handleClose={handleAddPaperClose} height={300}/>}
                {reviewing &&
                    <ReviewEvidenceDialog open={reviewing} data={data} handleClose={handleReviewClose} height={300}/>}
                {
                    open &&
                    <ActionMenu open={open} anchorEl={anchorEl} handleClose={handleMoreClose} handleSubmit={handleSubmit}/>
                }
            </CardRoot>
        );
    }
;

export default EvidenceGridCard;