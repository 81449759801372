import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from '@mui/system/styled';
import {Button} from '@mui/material';
import Grid from '@mui/material/Grid';
import RefreshIcon from '@mui/icons-material/Refresh';

import PageContainer from 'components/elements/PageContainer';

import Filters from './filters';
import SessionsList from './sessions-list';

const DownloadSessions = () => {
    const {t} = useTranslation();

    return (
        <PageContainer pageTitle={t('inbox.downloads.pageTitle')} pageSubTitle={t('inbox.downloads.pageSubTitle')}>
            <DownloadSessionsPage/>
        </PageContainer>
    );
};

const MuiButton = styled(Button)(() => ({
    '&.MuiButton-root': {
        '&:hover': {fontWeight: '700'},
        backgroundColor: 'transparent',
        textTransform: 'none',
        paddingRight: '0',
        paddingLeft: '0',
    }
}))

const DownloadSessionsPage = ({containerHeight}) => {
    const {t} = useTranslation();

    const [filters, setFilters] = React.useState({
        search: '',
        vertical: ''
    });
    const [sort, setSort] = useState({
        name: '',
        order: ''
    });

    const [refresh, setRefresh] = useState(0);
    const handleSearch = (search) => {
        setFilters({
            ...filters,
            search
        })
    }

    useEffect(() => {
        setRefresh(0)
    }, [])

    const handleRefresh = () => {
        setRefresh(Math.random())
    }

    return (
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={1} sx={{padding: '0 1em !important'}}>

            <Grid item container xs={12} alignItems={'center'}>
                <Filters handleSearch={handleSearch} filters={filters} handleFilters={setFilters}/>
            </Grid>

            <Grid item container xs={12} justifyContent={'flex-end'} spacing={3}>
                <Grid item>
                    <MuiButton sx={{color: '#656565'}} startIcon={<RefreshIcon sx={{color:'#0794d3'}}/>}
                               onClick={handleRefresh}>{t('Refresh')}</MuiButton>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <SessionsList height={containerHeight - 144}
                              refresh={refresh}
                              sort={sort} filters={filters}/>
            </Grid>

        </Grid>
    );
};

export default DownloadSessions;
